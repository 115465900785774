import { AthleteCard, Card } from '@cstactics/types';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { useGameAssetsStore } from '../game-assets.store';
import { NotHiredStatus } from '@cstactics/types/src';

// Getter

export const getCard = (id: string): Card | undefined => {
  return getAthleteCard(id);
};

// Athlete Card

export const getAthleteCard = (id: string): AthleteCard | undefined => {
  const cards = useGameAssetsStore.getState().athleteCards;
  return cards?.find((card) => card.uuid === id);
};

export const isAthleteCardBlocked = (athleteCard: AthleteCard): boolean => {
  const latestFiredAt = !athleteCard.employmentStatus.isHired
    ? (athleteCard.employmentStatus as NotHiredStatus).firedAt
    : undefined;
  if (!latestFiredAt) return false;

  return latestFiredAt + 1.728e8 > new Date().getTime();
};

export const getAthleteBlockedDistance = (athleteCard: AthleteCard): string | undefined => {
  const latestFiredAt = !athleteCard.employmentStatus.isHired
    ? (athleteCard.employmentStatus as NotHiredStatus).firedAt
    : undefined;
  if (!latestFiredAt) return;

  return latestFiredAt + 1.728e8 > new Date().getTime()
    ? formatDistanceToNowStrict(latestFiredAt + 1.728e8)
    : undefined;
};
