import { AthleteStatModifierEffect } from './athlete-effects';

export interface MatchAthleteEffect {
  uuid: string;
  type: string;
  description: string;
  effect: AthleteStatModifierEffect;
  trigger: MatchAthleteEffectTrigger;
  triggerAmount: number;
  duration: number;
  isRemovedAtRoundEnd?: boolean;
}
export function isMatchAthleteEffect(obj: any): obj is MatchAthleteEffect {
  return !!obj.trigger && !!obj.effect;
}

export enum MatchAthleteEffectTriggerType {
  ALLY_AMOUNT = 'allyAmount', // How many allies are alive
  ENEMY_AMOUNT = 'enemyAmount', // how many enemies are alive
  HEALTH = 'health', // health percentage
  KILLS = 'kills', // kills in this round
  GAME_TIME = 'gameTime', // how much time has passed in the game
  ROUND_END = 'roundEnd', // round end
  BOMB_PLANTED = 'bombPlanted', // bomb planted (by my athlete)
  BOMB_DEFUSED = 'bombDefused', // bomb defused (by my athlete)
}

export enum MatchAthleteEffectTriggerCheck {
  LESS_THAN = 'lessThan',
  EQUAL = 'equal',
  MORE_THAN = 'greaterThan',
}

export interface MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType;
}

export interface MatchAthleteEffectTriggerAllyAmount extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.ALLY_AMOUNT;
  amount: number;
  check: MatchAthleteEffectTriggerCheck;
}
export function isMatchAthleteEffectTriggerAllyAmount(obj: any): obj is MatchAthleteEffectTriggerAllyAmount {
  return obj.type === MatchAthleteEffectTriggerType.ALLY_AMOUNT;
}

export interface MatchAthleteEffectTriggerEnemyAmount extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.ENEMY_AMOUNT;
  amount: number;
  check: MatchAthleteEffectTriggerCheck;
}
export function isMatchAthleteEffectTriggerEnemyAmount(obj: any): obj is MatchAthleteEffectTriggerEnemyAmount {
  return obj.type === MatchAthleteEffectTriggerType.ENEMY_AMOUNT;
}

export interface MatchAthleteEffectTriggerHealth extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.HEALTH;
  percent: number;
  check: MatchAthleteEffectTriggerCheck;
}
export function isMatchAthleteEffectTriggerHealth(obj: any): obj is MatchAthleteEffectTriggerHealth {
  return obj.type === MatchAthleteEffectTriggerType.HEALTH;
}

export interface MatchAthleteEffectTriggerGameTime extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.GAME_TIME;
  time: number;
  check: MatchAthleteEffectTriggerCheck;
}
export function isMatchAthleteEffectTriggerGameTime(obj: any): obj is MatchAthleteEffectTriggerGameTime {
  return obj.type === MatchAthleteEffectTriggerType.GAME_TIME;
}

export interface MatchAthleteEffectTriggerKills extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.KILLS;
  amount: number;
  check: MatchAthleteEffectTriggerCheck;
}
export function isMatchAthleteEffectTriggerKills(obj: any): obj is MatchAthleteEffectTriggerKills {
  return obj.type === MatchAthleteEffectTriggerType.KILLS;
}

export interface MatchAthleteEffectTriggerRoundEnd extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.ROUND_END;
  result: 'won' | 'lost';
}
export function isMatchAthleteEffectTriggerRoundEnd(obj: any): obj is MatchAthleteEffectTriggerRoundEnd {
  return obj.type === MatchAthleteEffectTriggerType.ROUND_END;
}

export interface MatchAthleteEffectTriggerBombPlanted extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.BOMB_PLANTED;
  self: boolean;
}
export function isMatchAthleteEffectTriggerBombPlanted(obj: any): obj is MatchAthleteEffectTriggerBombPlanted {
  return obj.type === MatchAthleteEffectTriggerType.BOMB_PLANTED;
}

export interface MatchAthleteEffectTriggerBombDefused extends MatchAthleteEffectTrigger {
  type: MatchAthleteEffectTriggerType.BOMB_DEFUSED;
  self: boolean;
}
export function isMatchAthleteEffectTriggerBombDefused(obj: any): obj is MatchAthleteEffectTriggerBombDefused {
  return obj.type === MatchAthleteEffectTriggerType.BOMB_DEFUSED;
}
