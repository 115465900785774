import { openUserCardPack, queryUserCards } from '@/lib/aws/user/user.api';
import { _sortUserCards } from '../cards/cards.utils';
import { useGameAssetsStore } from '../game-assets.store';

/**
 * Trigger card pack opening and return cards of card pack
 * @param id
 */
export const openCardPack = async (id: string) => {
  const { pack } = await openUserCardPack(id);

  // query new cards
  const cards = await queryUserCards();

  // set new cards in store
  useGameAssetsStore.setState({ ..._sortUserCards(cards) });

  // return cards of opened pack
  return pack.cards;
};
