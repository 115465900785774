import { Reward } from '../rewards';

export const SHOP_VOUCHER_STRAPI_MODEL_NAME = 'voucher';

export interface VoucherCode {
  /** A name for the voucher code, mainly used to refer to it and know what it is for. */
  name: string;
  /** What the code that has to be entered is */
  code: string;
  /** How is this code valid. */
  validity: VoucherCodeValidity;
  /** When was this code created */
  createdAt: number;
  /** When was this code last updated */
  updatedAt: number;
  /** When does this code expire */
  expiresAt: number;
  /** List of rewards granted by this voucher code */
  rewards: Reward[];
  /** List of user ids which claimed this voucher */
  claimedBy: string[];
}

export enum VoucherCodeValidity {
  ONCE_PER_USER = 'OncePerUSER',
  ONCE = 'Once',
}
