import { MinMax, CsMap } from '../../../../../athlete';
import { MoveType, RoundRole } from '../../../../simulation';
import { TEAMA_OR_TEAMB } from '../filter';

export interface MovementFilter extends MovementFilterDef {
  /** Which locations is being measured now?  TODO: not precise formulation (movement from or to or both? ) */
  location?: string;
  /** check if movement in standard movement slot or quick move after encounter */
  movementWindow?: MoveType;
  /** TeamA or TeamB */
  team?: TEAMA_OR_TEAMB;
  /** terrorist or counterTerrorist */
  roundRole?: RoundRole;
  /** did the target athlete change location during this event */
  moved?: boolean;
  /** How many Athletes are in one location */
  amountAthletes?: MinMax;
  /** Is the bomb carrier present TODO: not precise formulation, present where */
  bombCarrier?: boolean;
  /** Which map is this match taking place in? */
  map?: CsMap;
}

export function isMovementFilter(obj: any): obj is MovementFilter {
  return Object.keys(obj).every(
    (key) => isMovementFilterWildCard(key) || isMovementFilterParent(key) || isMovementFilterChild(key)
  );
}
export enum MovementFilterParent {
  LOCATION = 'location',
  MOVEMENT_WINDOW = 'movementWindow',
  TEAM = 'team',
  ROUND_ROLE = 'roundRole',
  MOVED = 'moved',
}
export const movementFilterParentValues = Object.values(MovementFilterParent);
export function isMovementFilterParent(obj: any): obj is MovementFilterParent {
  return movementFilterParentValues.includes(obj);
}

export enum MovementFilterChild {
  AMOUNT_ATHLETES = 'amountAthletes',
  BOMB_CARRIER = 'bombCarrier',
}
export const movementFilterChildValues = Object.values(MovementFilterChild);
export function isMovementFilterChild(obj: any): obj is MovementFilterChild {
  return movementFilterChildValues.includes(obj);
}

export enum MovementFilterWildCard {
  MAP = 'map',
}
export const movementFilterWildCardValues = Object.values(MovementFilterWildCard);
export function isMovementFilterWildCard(obj: any): obj is MovementFilterWildCard {
  return movementFilterWildCardValues.includes(obj);
}

export type MovementFilterDef = Partial<
  Record<MovementFilterWildCard | MovementFilterParent | MovementFilterChild, any>
>;
