import { RewardSource } from '../../rewards';
import { CurrencyType } from '../card';

export interface UserTransaction {
  date: number;
  type: UserTransactionType;
  state: UserTransactionState;
  uuid: string;
  userId: string;
}

export enum UserTransactionType {
  PURCHASE_REAL_MONEY_OFFER_VIA_STRIPE = 'purchaseRealMoneyOfferViaStripe',
  GET_REWARD_PACK = 'getRewardPack',
  GET_REWARD_PREMIUM_CURRENCY = 'getRewardPremiumCurrency',
  OPEN_CARD_PACK = 'openCardPack',
}

export enum UserTransactionState {
  IN_PROGRESS = 'inProgress',
  PROCESSED = 'processed',
  FAILED = 'failed',
}
export interface GetRewardPackTransaction extends UserTransaction {
  type: UserTransactionType.GET_REWARD_PACK;
  cardPackLootCycleId: string;
  rewardSource: RewardSource;
  amount: number;
  cardPackIds?: Array<string>;
}

export function isGetRewardPackTransaction(transaction: UserTransaction): transaction is GetRewardPackTransaction {
  return transaction.type === UserTransactionType.GET_REWARD_PACK;
}

export interface GetRewardPremiumCurrencyTransaction extends UserTransaction {
  type: UserTransactionType.GET_REWARD_PREMIUM_CURRENCY;
  rewardSource: RewardSource;
  amount: number;
}

export function isGetRewardPremiumCurrencyTransaction(
  transaction: UserTransaction
): transaction is GetRewardPremiumCurrencyTransaction {
  return transaction.type === UserTransactionType.GET_REWARD_PREMIUM_CURRENCY;
}

export interface PurchaseRealMoneyOfferStripeUserTransaction extends UserTransaction {
  type: UserTransactionType.PURCHASE_REAL_MONEY_OFFER_VIA_STRIPE;
  offerId: string;
  stripe: {
    stripeSessionId: string;
    stripeCustomerId: string;
  };
}

export function isPurchaseRealMoneyOfferStripeUserTransaction(
  transaction: UserTransaction
): transaction is PurchaseRealMoneyOfferStripeUserTransaction {
  return transaction.type === UserTransactionType.PURCHASE_REAL_MONEY_OFFER_VIA_STRIPE;
}

export interface OpenCardPackUserTransaction extends UserTransaction {
  type: UserTransactionType.OPEN_CARD_PACK;
  cardPackId: string;
  cardPackTransactionId: string;
  cardIds?: string[];
  currency?: Record<CurrencyType, number>;
}

export function isOpenCardPackUserTransaction(
  transaction: UserTransaction
): transaction is OpenCardPackUserTransaction {
  return transaction.type === UserTransactionType.OPEN_CARD_PACK;
}

export interface UserTransactionPackTransferElement {
  cardPackOfferId: string;
  cardPackDefinitionId: string;
}

export function isUserTransactionPackTransferElement(object: Object): object is UserTransactionPackTransferElement {
  return object.hasOwnProperty('cardPackOfferId') && object.hasOwnProperty('cardPackDefinitionId');
}
