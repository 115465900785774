import { StaffCardShort } from '@cstactics/types';
import { useAuthStore } from '../../auth/auth.store';
import { AuthState } from '../../auth/auth.utils';
import { useGameAssetsStore } from '../game-assets.store';
import { queryUserStaffs } from '@/lib/aws/user/user-facility.api';

/**
 * Subscribe on auth store's auth state
 * • query cards on authorized
 * • empty store on unauthorized
 */
export const _handleAuthStateChangeForCards = () => {
  useAuthStore.subscribe(({ authState }) => {
    if (authState === AuthState.AUTHORIZED) {
      _onAuthorized();
    }
    if (authState === AuthState.UNAUTHORIZED) {
      _onUnauthorized();
    }
  });
};

/**
 * Handle Auth State Change to AUTHORIZED
 * Query and Sort User Cards
 * @param appUser
 */
const _onAuthorized = async () => {
  try {
    // query cards
    const cards = await queryUserStaffs();
    console.debug('[cards.facilityUtils] onAuthorized found cards::', cards.length);

    // sort cards
    const sortedCards = _sortUserCards(cards);
    // set cards in store
    useGameAssetsStore.setState({ ...sortedCards });
  } catch (error) {
    console.error(`[cards.utils] _onAuthorized::error`, error);
  }
};

interface SortedCards {
  staffCards: StaffCardShort[];
}

/**
 * Sort User Cards by CardType
 * @param cards
 */
export const _sortUserCards = (cards: StaffCardShort[]): SortedCards => {
  return cards.reduce<SortedCards>(
    (_cards, _card) => {
      _cards.staffCards.push(_card as StaffCardShort);
      return _cards;
    },
    { staffCards: [] }
  );
};

/**
 * Handle Auth State Change to UNAUTHORIZED
 * Empty User Cards
 */
const _onUnauthorized = async () => {
  useGameAssetsStore.setState({ staffCards: undefined, staffDetail: undefined });
};
