import { StaffCard } from '@cstactics/types';
import { useGameAssetsStore } from '../game-assets.store';
import { queryUserStaffDetails } from '@/lib/aws/user/user-facility.api';

// Getter
export async function getStaffCard(id: string): Promise<StaffCard> {
  const staffDetail = await queryUserStaffDetails(id);
  useGameAssetsStore.setState({ staffDetail: staffDetail });
  return staffDetail;
}
