import { isRarity } from '../../rack/pack-edition';
import { Card, CardType } from '../card';

// export interface CurrencyCard extends Card {
//   type: CardType.CURRENCY;
//   content: CurrencyCardContent;
// }

export interface PremiumCurrencyCard extends Card {
  type: CardType.PREMIUM_CURRENCY;
  content: CurrencyCardContent;
}

export function isPremiumCurrencyCard(card: Card): card is PremiumCurrencyCard {
  return card.type === CardType.PREMIUM_CURRENCY && isCurrencyCardContent(card.content);
}

export interface SoftCurrencyCard extends Card {
  type: CardType.SOFT_CURRENCY;
  content: CurrencyCardContent;
}

export function isSoftCurrencyCard(card: Card): card is SoftCurrencyCard {
  return card.type === CardType.SOFT_CURRENCY && isCurrencyCardContent(card.content);
}

export interface CurrencyCardContent {
  name: string;
  amount: number;
}

export function isCurrencyCardContent(currencyCardContent: any): currencyCardContent is CurrencyCardContent {
  const isCurrencyCard: boolean = !!currencyCardContent && typeof currencyCardContent.amount === 'number';
  return isCurrencyCard;
}

export enum CurrencyType {
  SOFT_CURRENCY = 'SoftCurrency',
  PREMIUM_CURRENCY = 'PremiumCurrency',
}

export const currencyTypeValues = Object.values(CurrencyType);
