import { Components } from '@mui/material/styles';
import { palette } from '../palette';

const MuiTextFieldOverrides: Components = {
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
    },
  },

  // Form Control
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%',
        marginBottom: ['24px'],
      },
    },
  },

  // Input Base
  MuiInputBase: {
    defaultProps: {},
    styleOverrides: {
      root: {
        height: '100%',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid',
      },
      input: {
        'height': '100%',
        'width': '100%',
        'color': palette.contentPrimary.tertiary,
        '&.MuiInputBase-input': {
          'padding': '14px 0px',
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
        },
        '&.Mui-focused': {
          color: palette.contentPrimary.primary,
        },
        '&.Mui-error': {
          color: palette.contentPrimary.primary,
        },
        // Placeholder styling
        '&::placeholder, &:-moz-placeholder, &::-moz-placeholder, &::-webkit-input-placeholder': {
          opacity: 1,
          color: palette.contentPrimary.tertiary,
        },
      },
    },
  },

  // Outlined Input Field
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
    styleOverrides: {
      root: {
        'height': 52,
        'width': '100%',
        'borderColor': palette.bgPrimary.secondary,
        'backgroundColor': 'transparent',
        'color': palette.contentPrimary.tertiary,
        'padding': '14px 16px',
        '&.Mui-focused': {
          borderColor: palette.bgPrimary.tertiary,
          color: palette.contentPrimary.primary,
        },
        '&.Mui-error': {
          borderColor: palette.error.main,
          color: palette.contentPrimary.primary,
        },
        '&.Mui-disabled': {
          color: palette.contentPrimary.disabled,
        },
      },
      input: {
        'height': '100%',
        'width': '100%',
        'padding': 0,
        '&.MuiOutlinedInput-input': {
          color: palette.contentPrimary.primary,
        },
        '&.Mui-disabled': {
          WebkitTextFillColor: palette.contentPrimary.disabled,
        },
      },
      notchedOutline: {
        display: 'none',
      },
    },
  },

  // Filled Input Field
  MuiFilledInput: {
    defaultProps: {
      fullWidth: true,
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        'height': 52,
        'width': '100%',
        'borderColor': palette.bgPrimary.secondary,
        'backgroundColor': palette.bgPrimary.secondary,
        'color': palette.contentPrimary.tertiary,
        'padding': '14px 16px',

        '&:hover': {
          backgroundColor: palette.bgPrimary.secondary,
        },
        '&.Mui-focused': {
          boxSizing: 'border-box',
          border: '1px solid',
          borderColor: palette.bgPrimary.tertiary,
          backgroundColor: palette.bgPrimary.secondary,
          color: palette.contentPrimary.primary,
        },
        '&.Mui-error': {
          border: '1px solid',
          borderColor: palette.error.main,
          color: palette.contentPrimary.primary,
        },
        '&.Mui-disabled': {
          backgroundColor: palette.bgPrimary.secondary,
          color: palette.contentPrimary.disabled,
        },
        'input': {
          'height': '100%',
          'width': '100%',
          '&.MuiFilledInput-input': {
            padding: 0,
            color: palette.contentPrimary.primary,
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: palette.contentPrimary.disabled,
          },
        },
      },
    },
  },

  // Helper Text
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        'color': palette.contentPrimary.tertiary,
        'margin': '8px 0px 0px 0px',

        'fontWeight': '400',
        'fontSize': '12px',
        'lineHeight': '20px',
        '&.Mui-error': {
          color: palette.error.main,
        },
      },
    },
  },

  // InputAdornment Icon
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        height: 24,
        width: 24,

        color: 'inherit',
        marginTop: '0px !important',
        marginRight: '12px',
      },
    },
  },
};

export default MuiTextFieldOverrides;
