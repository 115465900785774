import { AiTeam, TeamBaseInfo } from '../csgo-manager';
import { LeagueBaseInfo } from './leagues';

export interface Bracket {
  /** UUID for this bracket */
  uuid: string;

  /** league reference */
  league: LeagueBaseInfo;

  /** map of team.uuid and teams in this bracket */
  teams: Record<string, LeagueTeam | AiLeagueTeam>;

  /** max amount of teams in this bracket */
  maxSize: number;

  /** Percentage of teams to fill in brackets at League start */
  fillPercentage: number;
}

export interface LeagueTeam extends TeamBaseInfo {
  /** current amount of league points */
  lp: number;

  /** current amount of wins this season */
  wins: number;

  /** current amount of losses this season */
  losses: number;

  /** current amount of ties this season */
  ties: number;

  /** UUID of teams this team has already played against in the current season */
  facedOpponents: string[];

  /** ladder position */
  ladderPosition: number;

  /** amount matches played / total */
  amountMatches: {
    played: number;
    total: number;
  };

  /** win rate  */
  winRate: number[];

  /** bracketId */
  bracketId?: string;

  /** Information about pervious season ranking */
  previous?: {
    /** League Points achieved in previous season*/
    previousLp: number;

    /** League Points achieved in previous season*/
    previousSeasonResult: SeasonClimbing;
  };
}

export type AiLeagueTeam = LeagueTeam & { aiTeam: AiTeam };

export interface RankedLeagueTeam extends LeagueTeam {
  /** current mmr */
  mmr: number;

  /** current rank */
  rank: number;
}

export enum SeasonClimbing {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
  STAYING = 'staying',
}
