import { Box, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { IconContainerStyles } from './IconContainer.styles';

interface IconContainerProps {
  /** icon (documentation: {@link https://github.com/tailwindlabs/heroicons})*/
  children: ReactNode;
  /** style */
  sx?: SxProps<Theme>;
  /** css class name */
  className?: string;
}

const IconContainer: FC<IconContainerProps> = ({ children, sx, className }) => {
  const styles = useStyles(IconContainerStyles, {}, sx);
  return (
    <Box sx={styles.root} className={className}>
      {children}
    </Box>
  );
};

export default IconContainer;
