import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    height: 18,
    width: 18,

    svg: {
      height: 18,
      width: 18,
    },
  },
};

export { styles as IconContainerStyles };
