import { AthleteEffect } from '../effects/athlete-effects';

export type Buff = AthleteBuff; // | TeamBuff | StaffBuff | FacilityBuff // not in scope for launch
export type AppliedBuff<T = Buff> = T & { appliedAt: number };

export enum BuffType {
  ATHLETE_BUFF = 'athleteBuff',
  // not in scope for launch
  // TEAM_BUFF = 'teamBuff',
  // STAFF_BUFF = 'staffBuff',
  // FACILITY_BUFF = 'facilityBuff',
}

// ATHLETE BUFF

export interface AthleteBuff extends BuffBase {
  type: BuffType.ATHLETE_BUFF;
  effects: AthleteEffect[];
}

export function isAthleteBuff(obj: any): obj is AthleteBuff {
  return obj.type === BuffType.ATHLETE_BUFF;
}

// BUFF BASE

export interface BuffBase {
  uuid: string;
  type: BuffType;
  origin: BuffOrigin;
  behavior?: BuffBehavior;
  duration: {
    type: BuffDurationType;
    amount: number;
  };
  tags: string[];
  description: string;
}

export function isBuffBase(obj: any): obj is BuffBase {
  const isBehavior = obj.behavior ? isBuffBehavior(obj.behavior) : true;
  const isTags: () => boolean = () => {
    if (Array.isArray(obj.tags)) {
      const result = obj.tags.every((tag: any) => typeof tag === 'string');
      return result;
    }
    return false;
  };
  return (
    obj.id !== '' &&
    isBuffOriginValue(obj.BuffOrigin) &&
    isBehavior &&
    isBuffDurationType(obj.duration.type) &&
    !isNaN(obj.duration.amount) &&
    isTags()
  );
}

// BUFF BEHAVIOR

export interface BuffBehavior {
  type: BuffBehaviorType;
  category: BuffBehaviorCategory;
  stackingNumber: number;
}
export function isBuffBehavior(obj: any): obj is BuffBase {
  return (
    !!obj &&
    isBuffBehaviorTypeValue(obj.type) &&
    isBuffBehaviorCategoryValue(obj.category) &&
    !isNaN(obj.stackingNumber)
  );
}

export enum BuffBehaviorType {
  CANCEL = 'cancel',
  EXTEND = 'extend',
  OVERRIDE = 'override',
}
export const buffBehaviorTypeValues = Object.values(BuffBehaviorType);
export function isBuffBehaviorTypeValue(value: any): value is BuffBehaviorType {
  return buffBehaviorTypeValues.includes(value);
}

export enum BuffBehaviorCategory {
  TAG = 'tags',
  ID = 'id',
}
export const buffBehaviorCategoryValues = Object.values(BuffBehaviorCategory);
export function isBuffBehaviorCategoryValue(value: any): value is BuffBehaviorCategory {
  return buffBehaviorCategoryValues.includes(value);
}

// BUFF ORIGIN

export enum BuffOrigin {
  ENDURANCE = 'endurance',
  // not in scope for launch
  // CONSUMABLES = 'consumables',
  // MATCH = 'match',
  // SYSTEM = 'system',
  // FACILITY = 'facility',
  // STAFF = 'staff',
  // TEAM = 'team',
  // UPGRADE = 'upgrade',
}

export const buffOriginValues = Object.values(BuffOrigin);
export function isBuffOriginValue(value: any): value is BuffOrigin {
  return buffOriginValues.includes(value);
}

// BUFF DURATION

export enum BuffDurationType {
  TIME = 'time',
  MATCHES = 'matches',
  ROUNDS = 'rounds',
  PERMANENT = 'permanent',
}
export const buffDurationTypeValues = Object.values(BuffDurationType);
export function isBuffDurationType(type: any): type is BuffDurationType {
  return buffDurationTypeValues.includes(type);
}
