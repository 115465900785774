import {
  MatchPreparation,
  MatchPreparationExtended,
  MatchResult,
  MatchResultApiReturn,
  RankInResult,
  UpdateMatchPreparationInput,
} from '@cstactics/types';
import { StateCreator } from 'zustand';
import { GameAssetsState } from '../game-assets.store';
import {
  getUserMatchPreparation,
  getUserMatchResult,
  playPlacement,
  updateMatchId,
  updateUserMatchPreparation,
} from './matches.actions';
import { _handleAuthStateChangeForMatches } from './matches.utils';

export interface MatchesSlice {
  // states

  /** user matches */
  upcomingMatches?: MatchPreparation[];

  /** user match results */

  /** TODO: fix types */
  matchResults?: MatchResult[];

  /** current user match */
  activeMatchPreparation?: MatchPreparationExtended;

  /** current match Id */
  activeMatchId?: string;

  // actions

  /**
   * Get Match Preparation of given matchId
   * @param matchId
   */
  getUserMatchPreparation: (matchId: string) => Promise<MatchPreparationExtended>;

  /**
   * Update Match Preparation
   * @param matchId
   * @param updates
   */
  updateUserMatchPreparation: (matchId: string, update: UpdateMatchPreparationInput) => Promise<void>; //TODO:

  /**
   * Get Match Result of given matchId
   * @param matchId
   */
  getUserMatchResult: (matchId: string) => Promise<MatchResultApiReturn>;

  /**
   * Play Placement Match
   * @param teamId
   */
  playPlacement: (teamId: string) => Promise<{ matchId?: string; rankInResult?: RankInResult }>;

  /**
   * Update current matchId
   * @param matchId
   */
  updateMatchId: (matchId: string) => Promise<void>;

  /**
   * Get Opponent Team
   * @param updates
   */
  // getOpponentTeam: (matchId: string) => AthleteCardShort[] | undefined;

  // init

  /**
   * !! Don't use this in code: It's called by InitCardsSlice.init !!
   * Initialize matches
   * • listen to auth state
   * • query user matches if user is authorized
   * • empty store on logout
   */
  _initMatches: () => void;
}

export const createMatchesSlice: StateCreator<GameAssetsState, [], [], MatchesSlice> = () => ({
  updateUserMatchPreparation,
  getUserMatchPreparation,
  getUserMatchResult,
  playPlacement,
  updateMatchId,
  _initMatches: () => {
    _handleAuthStateChangeForMatches();
  },
});
