import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import {
  ComponentStyles,
  ComponentStylesWithFns,
  GetStyleFn,
  isGetStyleFn,
  StyleKeys,
  StyleStates,
} from './styles.types';

/**
 * Hook to get sx Props out of component styles with functions
 * @param stylesWithFns {@link ComponentStylesWithFns}
 * @param states {@link StyleStates}
 * @param merge
 * @param extendStyleDeclarations
 */
export function useStyles<P extends StyleStates = {}, K extends StyleKeys = StyleKeys>(
  stylesWithFns: ComponentStylesWithFns<P, K>,
  states = {},
  merge?: SxProps<Theme>,
  extendStyleDeclarations = false
) {
  const styles: Partial<ComponentStyles<K | string>> = {};
  for (const key of Object.keys(stylesWithFns)) {
    let style = stylesWithFns[key as K] as SxProps<Theme> | GetStyleFn<P>;
    styles[key as K] = isGetStyleFn<P>(style) ? style(states as P) : style;
  }

  if (merge) {
    if (extendStyleDeclarations) {
      for (const key of Object.keys(merge)) {
        // @ts-ignore
        const style = merge[key as K];
        if (typeof style === 'object' && key in styles) {
          //TODO dsantic 24.04.2023. Fix this
          // @ts-ignore
          styles[key as K] = { ...styles[key as K], ...style };
        }
      }
    } else {
      //TODO dsantic 24.04.2023. Fix this
      // @ts-ignore
      styles['root' as K] = styles.root ? { ...styles.root, ...merge } : { ...merge };
    }
  }

  return styles as ComponentStyles<K | string>;
}

export function sxPropsToStyle(sx?: SxProps<Theme>): CSSProperties {
  if (!sx) return {};

  if (Array.isArray(sx)) {
    return sx.reduce((styles, current) => {
      if (!current) return styles;
      return { ...styles, ...(current as CSSProperties) };
    }, {} as CSSProperties);
  }

  return sx as CSSProperties;
}
