export enum CoachType {
  GENERALIST = 'generalist', // boosts all skills a little bit
  AMBITIOUS = 'ambitious', // boosts skills very little, boosts fame and renown
  FORMER_CAPTAIN = 'formerCaptain', // boosts ingame skills (gameSense, proficiency on maps or role)
  MOTIVATOR = 'motivator', // boosts skills a little , increases motivation gain and boost on MatchStart
}
export const coachTypeValues = Object.values(CoachType);
export function isCoachType(obj: any): obj is CoachType {
  return coachTypeValues.includes(obj);
}
