import { CsMap, CsRole, MinMax } from '../../../../../athlete';
import { RoundRole } from '../../../../simulation';
import { TEAMA_OR_TEAMB } from '../filter';

export interface EncounterFilter extends EncounterFilterDef {
  /** Does the approach have these Tags  __PARENT__*/
  approachTags?: string[];
  /** number of kills in an approach */
  kills?: number;
  /** number of deaths in an approach  TODO: duplicate to kills? */
  deaths?: number;
  /** teamA or teamB */
  team?: TEAMA_OR_TEAMB;
  /** Total number of Athletes in the encounter, from min to max */
  totalAthletes?: MinMax;
  /** target athlete is the one that initiated this approach */
  activeInApproach?: boolean;
  /** target athlete died in this approach __PARENT__*/
  dead?: boolean;
  /** target athlete killed someone in the approach __PARENT__*/
  killer?: boolean;
  /** target athlete carries bomb __PARENT__*/
  bombCarrier?: boolean;
  /** terrorist or counterTerrorist __PARENT__*/
  roundRole?: RoundRole;
  /** the target athletes role on team */
  athleteRole?: CsRole;
  /** does the target athlete have a trait with this name? */
  athleteTraitName?: string;
  /** does the target athlete have a trait with all these tags */
  athleteTraitTag?: string[];
  /** which map is this match taking place __WILDCARD__*/
  map?: CsMap;
  /** at which location does the encounter take place __PARENT__*/
  location?: string;
}

export function isEncounterFilter(obj: any): obj is EncounterFilter {
  return Object.keys(obj).every(
    (key) => isEncounterFilterWildCard(key) || isEncounterFilterParent(key) || isEncounterFilterChild(key)
  );
}

export enum EncounterFilterParent {
  APPROACH_TAGS = 'approachTags',
  TEAM = 'team',
  DEAD = 'dead',
  KILLER = 'killer',
  BOMB_CARRIER = 'bombCarrier',
  ROUND_ROLE = 'roundRole',
  LOCATION = 'location',
}
export const encounterFilterParentValues = Object.values(EncounterFilterParent);
export function isEncounterFilterParent(obj: any): obj is EncounterFilterParent {
  return encounterFilterParentValues.includes(obj);
}

export enum EncounterFilterWildCard {
  MAP = 'map',
}
export const encounterFilterWildCardValues = Object.values(EncounterFilterWildCard);
export function isEncounterFilterWildCard(obj: any): obj is EncounterFilterWildCard {
  return encounterFilterWildCardValues.includes(obj);
}

export enum EncounterFilterChild {
  TOTAL_ATHLETES = 'totalAthletes',
  ACTIVE_IN_APPROACH = 'activeInApproach',
  ATHLETE_ROLE = 'athleteRole',
  ATHLETE_TRAIT_NAME = 'athleteTraitName',
  ATHLETE_TRAIT_TAGS = 'athleteTraitTags',
  KILLS = 'kills',
  DEATHS = 'deaths',
}
export const encounterFilterChildValues = Object.values(EncounterFilterChild);
export function isEncounterFilterChild(obj: any): obj is EncounterFilterChild {
  return encounterFilterChildValues.includes(obj);
}

export type EncounterFilterDef = Partial<
  Record<EncounterFilterParent | EncounterFilterWildCard | EncounterFilterChild, any>
>;
