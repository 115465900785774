export interface UserNotification {
  uuid: string;
  title: string;
  description: string;
  type: UserNotificationType;
  date: number;
  action?: UserNotificationRouteToAction;
  isRead?: boolean;
}

export enum UserNotificationType {
  DEFAULT = 'default',
  HIGHLIGHT = 'highlight',
  WARNING = 'warning',
}

export interface UserNotificationActionBase {
  type: UserNotificationActionType;
  label: string;
}

export enum UserNotificationActionType {
  ROUTE_TO = 'routeTo',
}

export interface UserNotificationRouteToAction extends UserNotificationActionBase {
  type: UserNotificationActionType.ROUTE_TO;
  route: string;
}
