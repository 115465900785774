import { SxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export type GetStyleFn<P extends StyleStates = {}> = (props: P) => Array<boolean | SystemStyleObject<Theme>>;

export type StyleKeys = 'root' | string;

export type StyleStates = Record<any, any>;

export type ComponentStylesWithFns<P extends StyleStates = {}, K extends StyleKeys = StyleKeys> = Record<
  K,
  SystemStyleObject<Theme> | GetStyleFn<P>
>;
export type ComponentStyles<K extends StyleKeys = StyleKeys> = Record<K, SxProps<Theme>>;

export function isGetStyleFn<P extends StyleStates>(style: SxProps<Theme> | GetStyleFn<P>): style is GetStyleFn<P> {
  return typeof style === 'function';
}
