import { StateCreator } from 'zustand';
import { GameAssetsState } from '../game-assets.store';
import { _handleAuthStateChangeForRooms } from '@/lib/store/gameAssets/rooms/rooms.utils';
import { BuildingRoom, UserRoom } from '@cstactics/types';
import { downgradeRoom, fastRoomUpgrade, upgradeRoom } from '@/lib/store/gameAssets/rooms/rooms.action';

export interface RoomsSlice {
  // states

  rooms?: UserRoom[] | any;
  buildingRoom?: BuildingRoom | any;

  //actions

  /**
   * Hire athlete for current user
   * @param athleteCardId
   * @returns
   */
  upgradeRoom: (roomId: string, level: number) => Promise<void>;
  fastRoomUpgrade: () => Promise<void>;
  downgradeRoom: (roomId: string) => Promise<void>;
  // init
  /**
   * !! Don't use this in code: It's called by InitCardsSlice.init !!
   * Initialize cards store
   * • listen to auth state
   * • query user cards if user is authorized
   * • empty store in logout
   */
  _initRooms: () => void;
}
export const createRoomsSlice: StateCreator<GameAssetsState, [], [], RoomsSlice> = () => ({
  upgradeRoom,
  fastRoomUpgrade,
  downgradeRoom,
  _initRooms: () => {
    _handleAuthStateChangeForRooms();
  },
});
