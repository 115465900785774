export interface StaffEffect {
  uuid: string;
  description: string;
  statName: StaffStatModifierName;
  isRelative: boolean;
  value: number;
}

export enum StaffStatModifierName {
  SKILL_RATING = 'skillRating',
  TALENT_RATING = 'talentRating',
  XP_GAIN_MULTIPLIER = 'xpGainMultiplier',
  SALARY_MODIFIER = 'salaryModifier',
}

export const staffStatModifierNameValues = Object.values(StaffStatModifierName);
