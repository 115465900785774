export interface MinMax {
  min: number;
  max: number;
}

export function isMinMax(minMax: any): minMax is MinMax {
  return !isNaN(minMax.min) && !isNaN(minMax.max);
}

export interface Comparable {
  values: number[];
  selector: StatSelector.ALL | StatSelector.ANY;
}
export function isComparable(comparable: any): comparable is Comparable {
  return (
    comparable.values.every((value: any) => !isNaN(value)) &&
    (comparable.selector === StatSelector.ANY || comparable.selector === StatSelector.ALL)
  );
}

export enum StatSelector {
  ALL = 'all',
  ANY = 'any',
  ALL_SKILLS = 'allSkills',
  ALL_TALENTS = 'allTalents',
  ALL_ROLE_PROFICIENCIES = 'allRoleProficiencies',
  ALL_MAP_PROFICIENCIES = 'allMapProficiencies',
  ALL_SKILL_TALENTS = 'allSkillTalents',
  ALL_ROLE_PROFICIENCIES_TALENTS = 'allRoleProficienciesTalents',
  ALL_MAP_PROFICIENCIES_TALENTS = 'allMapProficienciesTalents',
  ANY_SKILLS = 'anySkills',
  ANY_TALENTS = 'anyTalents',
  ANY_ROLE_PROFICIENCIES = 'anyRoleProficiencies',
  ANY_MAP_PROFICIENCIES = 'anyMapProficiencies',
  ANY_SKILL_TALENTS = 'anySkillTalents',
  ANY_ROLE_PROFICIENCIES_TALENTS = 'anyRoleProficienciesTalents',
  ANY_MAP_PROFICIENCIES_TALENTS = 'anyMapProficienciesTalents',
}

export const statSelector = Object.values(StatSelector);
export function isStatSelector(selector: any): selector is StatSelector {
  return Object.values(StatSelector).includes(selector);
}

export type ConditionCheck = IsConditionCheck | HasConditionCheck | MathConditionCheck;
export function isConditionCheck(check: any): check is ConditionCheck {
  return isHasConditionCheck(check) || isMathConditionCheck(check) || isIsConditionCheck(check);
}

export enum IsConditionCheck {
  IS = 'is',
  IS_NOT = 'isNot',
}
export function isIsConditionCheck(check: ConditionCheck): check is IsConditionCheck {
  return Object.values(IsConditionCheck as any).includes(check);
}

export enum HasConditionCheck {
  HAS = 'has',
  HAS_NOT = 'hasNot',
}
export const hasConditionCheck = Object.values(HasConditionCheck);
export function isHasConditionCheck(check: ConditionCheck): check is HasConditionCheck {
  return Object.values(HasConditionCheck as any).includes(check);
}

export enum MathConditionCheck {
  LESS = 'less',
  LESS_OR_EQUALS = 'lessOrEquals',
  EQUALS = 'equals',
  MORE_OR_EQUALS = 'moreOrEquals',
  MORE = 'more',
}
export const mathConditionCheck = Object.values(MathConditionCheck);
export function isMathConditionCheck(check: ConditionCheck): check is MathConditionCheck {
  return Object.values(MathConditionCheck as any).includes(check);
}

export type PowerDistributionTable = Record<string, MinMax>;
// TODO: create typeguard

export type PowerDistribution = Record<string, number>;
// TODO: create typeguard
