import MixpanelAPI from '@/lib/mixpanel/mixpanel.api';
import { queryUserNotifications, queryUserProfile } from '../../aws/user/user.api';
import { useMixpanelStore } from '../mixpanel/mixpanel.store';
import { setNotifications } from '../notifications/notifications.actions';
import { useAuthStore } from './auth.store';

export enum AuthState {
  /** Unknown state while bootstrapping */
  UNKNOWN,
  /** User is not authorized*/
  UNAUTHORIZED,
  /** User has signed up and has to verify email account*/
  WAITING_FOR_VERIFICATION,
  /** User is authorized*/
  AUTHORIZED,
}

/** App user data */
export interface AppUser {
  email: string;
  username: string;
  country: string;
  city: string;
  zipCode: string;
  streetAndNr: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  sub: string;
}

export interface UserProfile {
  avatarImageKey?: string;
}

export const handleAuthSuccess = async (user: any) => {
  const appUser = _formatCognitoUserToAppUser(user);
  const userProfile = await _queryUserProfile();
  const notifications = await queryUserNotifications();

  setNotifications(notifications);

  // Track login event only once after the actual login
  if (useMixpanelStore.getState().initialLogin) {
    await MixpanelAPI.signIn(appUser);
  }

  useAuthStore.setState({ authState: AuthState.AUTHORIZED, appUser, userProfile });
};

export const _formatCognitoUserToAppUser = (user: any): AppUser => {
  const { name: username, address, sub, ...rest } = user;

  let streetAndNr, zipCode, city, country;
  if (address) {
    [streetAndNr, zipCode, city, country] = address.split(';');
  }

  return { ...rest, sub, streetAndNr, zipCode, city, country, username };
};

export const _queryUserProfile = async () => {
  try {
    const userProfile: UserProfile = await queryUserProfile();
    return userProfile;
  } catch (error) {
    return undefined;
  }
};
