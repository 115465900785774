import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Components, PaginationItem, PaginationRenderItemParams } from '@mui/material';
import { palette } from '../palette';

const CustomPaginationItem = (item: PaginationRenderItemParams) => {
  return <PaginationItem slots={{ previous: ChevronLeftIcon, next: ChevronRightIcon }} {...item} />;
};

const MuiPaginationOverrides: Components = {
  MuiPagination: {
    defaultProps: {
      renderItem: CustomPaginationItem,
    },
    styleOverrides: {
      root: {
        height: 52,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        'height': 52,
        'width': 52,
        'borderRadius': 4,
        '&:not(.MuiPaginationItem-previousNext)': {
          display: 'none',
        },
      },
      text: {
        'color': palette.contentPrimary.secondary,
        '&:hover': {
          backgroundColor: palette.bgPrimary.secondary,
        },
      },
      previousNext: {
        svg: {
          width: 22,
        },
      },
    },
  },
};

export default MuiPaginationOverrides;
