import { Card, CardType } from '..';
import { SponsorshipContractUnsigned } from '../../../facility';

export interface SponsorshipContractCard extends Card {
  type: CardType.SPONSORSHIP_CONTRACT;
  content: SponsorshipContractUnsigned;
}
export function isSponsorshipContractCard(card: Card): card is SponsorshipContractCard {
  return card.type === CardType.SPONSORSHIP_CONTRACT;
}
