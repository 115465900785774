import { BombPlacementFilter, isBombPlacementFilter } from './bombPlacement/bombPlacementFilter';
import { EncounterFilter, isEncounterFilter } from './encounter/encounterFilter';
import { GameEndFilter, isGameEndFilter } from './gameEnd/gameEndFilter';
import { MapBanFilter, isMapBanFilter } from './mapBan/mapBanFilter';
import { MovementFilter, isMovementFilter } from './movement/movementFilter';
import { RoundEndFilter, isRoundEndFilter } from './roundEnd/roundEndFilter';
import { TeamStatusFilter, isTeamStatusFilter } from './teamStatus/teamStatusFilter';

export function isFilter(obj: any): obj is Filter {
  return (
    isBombPlacementFilter(obj) ||
    isEncounterFilter(obj) ||
    isGameEndFilter(obj) ||
    isMapBanFilter(obj) ||
    isMovementFilter(obj) ||
    isRoundEndFilter(obj) ||
    isTeamStatusFilter(obj)
  );
}

export enum FilterInheritance {
  WILDCARD = 'wildCard',
  PARENT = 'parent',
  CHILD = 'child',
}
export const filterInheritanceValues = Object.values(FilterInheritance);
export function isFilterInheritance(inheritance: any): inheritance is FilterInheritance {
  return filterInheritanceValues.includes(inheritance);
}

export enum TEAMA_OR_TEAMB {
  TEAM_A = 'teamA',
  TEAM_B = 'teamB',
}

export type Filter =
  | BombPlacementFilter
  | EncounterFilter
  | GameEndFilter
  | MapBanFilter
  | MovementFilter
  | RoundEndFilter
  | TeamStatusFilter;
