import { Athlete, AthleteShort, isAthlete } from '../../athlete';
import { Card, CardShort, CardType } from '../card';
import { Employable } from '../employable';
import { Retirable } from '../retireable';

export interface AthleteCard extends Card, Employable, Retirable {
  type: CardType.ATHLETE;
  content: Athlete;
}
export function isAthleteCard(card: Card): card is AthleteCard {
  return card.type === CardType.ATHLETE && isAthlete(card.content);
}

export interface AthleteCardShort extends CardShort, Employable, Retirable {
  type: CardType.ATHLETE;
  content: AthleteShort;
}
