export enum RoundPhase {
  PRE_BOMB = 'preBomb',
  POST_BOMB = 'postBomb',
}
export const roundPhaseValues = Object.values(RoundPhase);
export function isRoundPhase(phase: any): phase is RoundPhase {
  return roundPhaseValues.includes(phase);
}

export enum T_WinReason {
  BOMB_EXPLODES = 'bomb-explodes',
  COUNTER_TERRORISTS_DEAD = 'counter-terrorists-dead',
}
export const T_WinReason_Values = Object.values(T_WinReason);
export const is_T_WinReason = (value: WinReason) => {
  return T_WinReason_Values.includes(value as T_WinReason);
};

export enum CT_WinReason {
  NINJA_DEFUSE = 'ninja-defuse',
  BOMB_DEFUSE = 'bomb-defuse',
  PRE_BOMB_TIME_OVER = 'pre-bomb-time-over',
  TERRORISTS_DEAD = 'terrorists-dead',
}
export const CT_WinReason_Values = Object.values(CT_WinReason);
export const is_CT_WinReason = (value: WinReason) => {
  return CT_WinReason_Values.includes(value as CT_WinReason);
};

export enum PRE_BOMB_PHASE_END {
  BOMB_PLACED = 'bomb-placed',
}

export type WinReason = T_WinReason | CT_WinReason;
export const winReason_Values = [...T_WinReason_Values, ...CT_WinReason_Values];
export function isWinReason(winReason: any): winReason is WinReason {
  return is_CT_WinReason(winReason) || is_T_WinReason(winReason);
}
