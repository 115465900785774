import { MinMax } from '../../../../../athlete';
import { CT_WinReason, T_WinReason } from '../../../../simulation';
import { TEAMA_OR_TEAMB } from '../filter';

export interface RoundEndFilter extends RoundEndFilterDef {
  /** reason to end the round */
  winReason?: CT_WinReason | T_WinReason;
  /** teamA or tteamB */
  team?: TEAMA_OR_TEAMB;
  /** how many people died */
  deaths?: number;
  /** How deep is this round in the match?
   *  ex: “20% to 40%” depth in a match that ends “16 v 11” is:
   * total rounds = 27
   * 20% of 27 = 5,4 = 5
   * 40% of 27 = 10,8 = 11
   *  So any of (and only) the rounds between 5 and 11 fit this condition
   * values between 0 and 1
   * */
  depth?: MinMax;
}

export function isRoundEndFilter(obj: any): obj is RoundEndFilter {
  return Object.keys(obj).every((key) => isRoundEndFilterParent(key) || isRoundEndFilterChild(key));
}

export enum RoundEndFilterParent {
  TEAM = 'team',
  DEPTH = 'depth',
}

export const roundEndFilterParentValues = Object.values(RoundEndFilterParent);
export function isRoundEndFilterParent(obj: any): obj is RoundEndFilterParent {
  return roundEndFilterParentValues.includes(obj);
}

export enum RoundEndFilterChild {
  WIN_REASON = 'winReason',
  DEATHS = 'deaths',
}
export const roundEndFilterChildValues = Object.values(RoundEndFilterChild);
export function isRoundEndFilterChild(obj: any): obj is RoundEndFilterChild {
  return roundEndFilterChildValues.includes(obj);
}

export type RoundEndFilterDef = Partial<Record<RoundEndFilterParent | RoundEndFilterChild, any>>;
