import { DataBasePksItem, DbMetaAttributes } from '../../utils';

export enum PackState {
  IDLE = 'IDLE',
  PREPARED = 'PREPARED',
  CLOSED = 'CLOSED',
  ISSUE = 'ISSUE',
  RESERVED = 'RESERVED',
}

export type Pack = {
  packId: string;
  shelfId: string;
  name: string;
  state: PackState;
  slots: string[];
};

export type PackDbItem = DataBasePksItem & DbMetaAttributes & Pack & {};
