import mixpanel from 'mixpanel-browser';
import { AppUser } from '../store/auth/auth.utils';
import { useMixpanelStore } from '../store/mixpanel/mixpanel.store';

namespace MixpanelAPI {
  /**
   * register mixpanel
   */
  export async function register(email: string, username: string, sub: string): Promise<any> {
    if (!(mixpanel as any).__loaded) return;

    try {
      if (!sub) {
        console.warn('[mixpanel.api] register::sub is undefined');
        return;
      }

      mixpanel.identify(sub);

      mixpanel.people.set({
        user_id: sub,
        $email: email,
        $name: username,
      });

      mixpanel.track('Register');
    } catch (error) {
      console.log(`[mixpanel.api] register::error`, error);
    }
  }

  /**
   * signIn mixpanel
   */
  export async function signIn(appUser: AppUser): Promise<any> {
    if (!(mixpanel as any).__loaded) return;

    try {
      if (!appUser) {
        console.warn('[mixpanel.api] signIn::currentUser is undefined');
        return;
      }

      useMixpanelStore.setState({ initialLogin: false });
      mixpanel.identify(appUser.sub);
      mixpanel.track('Login');
    } catch (error) {
      console.log(`[mixpanel.api] signIn::error`, error);
    }
  }

  /**
   * pageView mixpanel
   */
  export async function pageView(): Promise<any> {
    if (!(mixpanel as any).__loaded) return;

    try {
      mixpanel.track_pageview();
    } catch (error) {
      console.log(`[mixpanel.api] pageView::error`, error);
    }
  }

  /**
   * logout mixpanel
   */
  export async function logout(): Promise<any> {
    if (!(mixpanel as any).__loaded) return;

    try {
      mixpanel.reset();
    } catch (error) {
      console.log(`[mixpanel.api] logout::error`, error);
    }
  }

  /**
   * send mixpanel event
   */
  export async function event(eventName: string, props?: any): Promise<any> {
    if (!(mixpanel as any).__loaded) return;

    try {
      mixpanel.track(eventName, props);
    } catch (error) {
      console.log(`[mixpanel.api] mixpanelEvent::error`, error);
    }
  }
}

export default MixpanelAPI;
