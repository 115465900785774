import { Components } from '@mui/material/styles';
import { palette } from '../palette';

const MuiInputLabelOverrides: Components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        'color': palette.contentPrimary.primary,
        // height: '100%',
        'width': '100%',
        'marginBottom': ['8px'],
        'fontSize': 14,
        'lineHeight': '20px',
        'fontWeight': 600,

        '&.Mui-disabled': {
          color: palette.contentPrimary.disabled,
        },
      },
    },
  },
};

export default MuiInputLabelOverrides;
