import { BreakpointsOptions } from '@mui/material';

export const breakpoints: BreakpointsOptions = {
  values: {
    small: 0,
    medium: 601,
    large: 985,
    extraLarge: 1471,
  },
};
