import { SystemStatModifierName } from '../../csgo-manager';
import { AthleteEffect, AthleteEffectType, AthleteStatName } from './athlete-effects';
import { FacilityEffect, FacilityStatModifierName } from './facility-effects';
import { StaffEffect, StaffStatModifierName } from './staff-effects';

export enum EffectCalcType {
  ADDITIVE = 'additive',
  RELATIVE = 'relative',
}

export type ActiveEffects = {
  athleteEffects: Record<EffectCalcType, AthleteEffect[]>;
  staffEffects: Record<EffectCalcType, StaffEffect[]>;
  facilityEffects: Record<EffectCalcType, FacilityEffect[]>;
};

export type ActiveEffectValuesItem = Record<EffectCalcType, number>;

// ACTIVE EFFECT VALUES
export interface ActiveEffectValues {
  athleteEffects: ActiveAthleteEffectValues;
  staffEffects: ActiveStaffEffectValues;
  facilityEffects: ActiveFacilityEffectValues;
}

// ACTIVE ATHLETE EFFECT VALUES

export interface ActiveAthleteEffectValues {
  [AthleteEffectType.STAT_MODIFIER_EFFECT]: ActiveAthleteStatModifierEffectValues;
  [AthleteEffectType.TALENT_MODIFIER_EFFECT]: ActiveAthleteTalentModifierEffectValues;
}

export type ActiveAthleteStatModifierEffectValues = Partial<
  Record<AthleteStatName | SystemStatModifierName, ActiveEffectValuesItem>
>;
export type ActiveAthleteTalentModifierEffectValues = Partial<Record<AthleteStatName, ActiveEffectValuesItem>>;

// ACTIVE FACILITY EFFECT VALUES

export type ActiveFacilityEffectValues = Partial<Record<FacilityStatModifierName, ActiveEffectValuesItem>>;

// ACTIVE STAFF EFFECT VALUES

export type ActiveStaffEffectValues = Partial<Record<StaffStatModifierName, ActiveEffectValuesItem>>;
