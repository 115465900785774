import { useAuthStore } from '@/lib/store/auth/auth.store';
import { AppUser, handleAuthSuccess } from '@/lib/store/auth/auth.utils';
import { getRequest } from '@/lib/utils/request';
import { useSession } from 'next-auth/react';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { useGameAssetsStore } from '../store/gameAssets/game-assets.store';

interface StoreContext {
  isInitialized: boolean;
}

export const storeContext = createContext<StoreContext>({ isInitialized: false });

export const StoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { status, data } = useSession();

  useEffect(() => {
    console.debug('[store.provider] init called::');

    useAuthStore.getState().init();
    useGameAssetsStore.getState().init();
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    const handleAuth = async () => {
      if (status === 'authenticated') {
        const data = await getRequest<{ user: { name: string; email: string }; userData: AppUser }>(
          '/api/auth/profile'
        );

        const { user, userData } = data || {};

        await handleAuthSuccess({ ...user, ...userData });
      }
    };
    if (status === 'authenticated') handleAuth();
  }, [status, data]);

  return <storeContext.Provider value={{ isInitialized }}>{children}</storeContext.Provider>;
};
