import { LockInput } from '@/components/others/LockInput/LockInput';
import { StoreProvider } from '@/lib/providers/store.provider';
import MixpanelAPI from '@/lib/mixpanel/mixpanel.api';
import '@/lib/styles/globals.css';
import { theme } from '@/theme/theme';
import { ThemeProvider } from '@mui/material';

import mixpanel from 'mixpanel-browser';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { SWRConfig } from 'swr';
import { withPasswordProtect } from 'next-password-protect';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();

  /**
   * Initialize Tag Manager and Mixpanel
   */
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM || '',
    });
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_ID || '', {
      debug: true,
      ignore_dnt: true,
      track_pageview: false, // disabled and done in useEffect due to SPA
    });
  }, []);

  /**
   *  detect route change
   * */
  useEffect(() => {
    MixpanelAPI.pageView();
    console.log(`%c [App] route change:: ${router.pathname} `, 'background: #222; color: #bada55');
  }, [router]);

  return (
    <SessionProvider session={session} refetchInterval={5 * 60}>
      <ThemeProvider theme={theme}>
        <StoreProvider>
          <Head>
            <title>CounterStrike Tactics</title>
            <meta name="description" content="Manager Game for CounterStrike" />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          <NextNProgress options={{ showSpinner: false }} />
          <SWRConfig
            value={{
              refreshInterval: 0,
              revalidateOnFocus: false,
              revalidateOnReconnect: false,

              fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
            }}
          >
            <Component {...pageProps} />
          </SWRConfig>
        </StoreProvider>
      </ThemeProvider>
    </SessionProvider>
  );
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginApiUrl: '/api/unlock',
      checkApiUrl: '/api/unlock-password-check',
      loginComponent: LockInput,
      loginComponentProps: {
        buttonBackgroundColor: '#FFF',
        buttonColor: '#000',
        logo: '/logo.png',
      },
    })
  : MyApp;
