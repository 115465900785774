import '@fontsource/inter';
import { Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: ['Inter', 'Helvetica'].join(','),
  h4: { fontSize: 18, lineHeight: '24px', fontWeight: 600, fontFamily: ['Inter', 'Helvetica'].join(',') },
  h5: { fontSize: 16, lineHeight: '24px', fontWeight: 600, fontFamily: ['Inter', 'Helvetica'].join(',') },
  h6: { fontSize: 14, lineHeight: '20px', fontWeight: 600, fontFamily: ['Inter', 'Helvetica'].join(',') },
  body: { fontSize: 16, lineHeight: '24px', fontWeight: 400, fontFamily: ['Inter', 'Helvetica'].join(',') },
  caption: { fontSize: 14, lineHeight: '20px', fontWeight: 400, fontFamily: ['Inter', 'Helvetica'].join(',') },
  footnote: { fontSize: 12, lineHeight: '20px', fontWeight: 400, fontFamily: ['Inter', 'Helvetica'].join(',') },
};

export function setTypographyVariants(theme: Theme): void {
  theme.typography = {
    ...theme.typography,
    h1: {
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.up('small')]: { fontSize: 36, lineHeight: '40px', fontWeight: 600 },
      [theme.breakpoints.up('medium')]: { fontSize: 44, lineHeight: '48px', fontWeight: 600 },
      [theme.breakpoints.up('large')]: { fontSize: 42, lineHeight: '46px', fontWeight: 600 },
      [theme.breakpoints.up('extraLarge')]: { fontSize: 42, lineHeight: '46px', fontWeight: 600 },
    },
    h2: {
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.up('small')]: { fontSize: 28, lineHeight: '36px', fontWeight: 600 },
      [theme.breakpoints.up('medium')]: { fontSize: 32, lineHeight: '40px', fontWeight: 600 },
      [theme.breakpoints.up('large')]: { fontSize: 36, lineHeight: '40px', fontWeight: 600 },
      [theme.breakpoints.up('extraLarge')]: { fontSize: 36, lineHeight: '40px', fontWeight: 600 },
    },
    h3: {
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.up('small')]: { fontSize: 22, lineHeight: '32px', fontWeight: 600 },
      [theme.breakpoints.up('medium')]: { fontSize: 24, lineHeight: '36px', fontWeight: 600 },
      [theme.breakpoints.up('large')]: { fontSize: 24, lineHeight: '36px', fontWeight: 600 },
      [theme.breakpoints.up('extraLarge')]: { fontSize: 24, lineHeight: '36px', fontWeight: 600 },
    },
  };
}
