import { MinMax } from '../../../../../athlete';

export interface GameEndFilter extends GameEndFilterDef {
  /** how many rounds did the winner win */
  winnerRoundsWon?: number;
  /** how many rounds did the looser win */
  looserRoundsWon?: MinMax;
}
export function isGameEndFilter(obj: any): obj is GameEndFilter {
  return Object.keys(obj).every((key) => isGameEndFilterWildCard(key));
}

export enum GameEndFilterWildCard {
  ROUNDS_WON = 'roundsWon',
  ROUNDS_LOST = 'roundsLost',
}

export const gameEndFilterWildCardValues = Object.values(GameEndFilterWildCard);
export function isGameEndFilterWildCard(obj: any): obj is GameEndFilterWildCard {
  return gameEndFilterWildCardValues.includes(obj);
}

export type GameEndFilterDef = Partial<Record<GameEndFilterWildCard, any>>;
