import { devtools } from 'zustand/middleware';
import { StateCreator } from 'zustand/esm';
import { DevtoolsOptions } from 'zustand/middleware/devtools';

export const withDevtools = <T extends StateCreator<any>>(storeFn: T, settings?: DevtoolsOptions): T =>
  process.env.NODE_ENV === 'development'
    ? // @ts-ignore
      (devtools(storeFn, {
        ...settings,
      }) as T)
    : storeFn;
