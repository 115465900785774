import { ActionBase } from './action';

export interface AthleteActionBase extends ActionBase {
  teamId: string;
  athleteId: string;
  actionType: AthleteActionType;
}

export interface AthleteActionBomb extends AthleteActionBase {
  actionType: AthleteActionType.BOMB;
  category: BombActionCategory;
}
export function isAthleteActionBomb(action: any): action is AthleteActionBomb {
  return action.actionType === AthleteActionType.BOMB && isBombActionCategory(action.category);
}

export interface AthleteActionKill extends AthleteActionBase {
  actionType: AthleteActionType.KILL;
  opponentId: string;
}
export function isAthleteActionKill(action: any): action is AthleteActionKill {
  return action.actionType === isAthleteActionKill;
}

export type AthleteAction = AthleteActionBomb | AthleteActionKill;

export enum AthleteActionType {
  BOMB = 'bomb',
  KILL = 'kill',
}
export const athleteActionTypeValues = Object.values(AthleteActionType);
export function isAthleteActionType(type: any): type is AthleteActionType {
  return athleteActionTypeValues.includes(type);
}

export enum BombActionCategory {
  BOMB_PLACED = 'bombPlaced',
  BOMB_DEFUSED = 'bombDefused',
  BOMB_NINJA_DEFUSED = 'bombNinjaDefused',
  BOMB_EXPLODES = 'bombExplodes',
}
export const bombActionCategoryValues = Object.values(BombActionCategory);
export function isBombActionCategory(category: any): category is BombActionCategory {
  return bombActionCategoryValues.includes(category);
}
