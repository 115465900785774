import { queryUserCards } from '@/lib/aws/user/user.api';
import { AthleteCard, Card, CardType } from '@cstactics/types';
import { useAuthStore } from '../../auth/auth.store';
import { AuthState } from '../../auth/auth.utils';
import { useGameAssetsStore } from '../game-assets.store';

/**
 * Subscribe on auth store's auth state
 * • query cards on authorized
 * • empty store on unauthorized
 */
export const _handleAuthStateChangeForCards = () => {
  useAuthStore.subscribe(({ authState }) => {
    if (authState === AuthState.AUTHORIZED) {
      _onAuthorized();
    }
    if (authState === AuthState.UNAUTHORIZED) {
      _onUnauthorized();
    }
  });
};

/**
 * Handle Auth State Change to AUTHORIZED
 * Query and Sort User Cards
 * @param appUser
 */
const _onAuthorized = async () => {
  try {
    // query cards
    const cards = await queryUserCards();
    console.debug('[cards.utils] onAuthorized found cards::', cards.length);

    // sort cards
    const sortedCards = _sortUserCards(cards);
    // set cards in store
    useGameAssetsStore.setState({ ...sortedCards });
  } catch (error) {
    console.error(`[cards.utils] _onAuthorized::error`, error);
  }
};

interface SortedCards {
  athleteCards: AthleteCard[];
}

/**
 * Sort User Cards by CardType
 * @param cards
 */
export const _sortUserCards = (cards: Card[]): SortedCards => {
  return cards.reduce<SortedCards>(
    (_cards, _card) => {
      if (_card.type === CardType.ATHLETE) _cards.athleteCards.push(_card as AthleteCard);

      return _cards;
    },
    { athleteCards: [] }
  );
};

/**
 * Handle Auth State Change to UNAUTHORIZED
 * Empty User Cards
 */
const _onUnauthorized = async () => {
  useGameAssetsStore.setState({ athleteCards: undefined });
};
