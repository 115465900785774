import { create } from 'zustand';
import { withDevtools } from '../utils';
import { NotificationsSlice, createNotificationsSlice } from './notifications.slice';

export type NotificationsState = NotificationsSlice;

export const useNotificationsStore = create<NotificationsState>()(
  withDevtools((set, get, ...a) => ({
    ...createNotificationsSlice(set, get, ...a),
  }))
);
