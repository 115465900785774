import {
  Athlete,
  CsRole,
  isAthleteChemistryStats,
  isAthleteSkills,
  isAthleteSystemStatModifiers,
  isCsRole,
  isTalents,
  isTrait,
} from '../../athlete';
import {
  ActiveAthleteEffectValues,
  AppliedBuff,
  AthleteEffect,
  AthleteStatModifierEffect,
  isStatModifierEffect,
} from '../../../buff-system';
import { TeamBaseInfo, TeamRosterExtended, TeamMapPriorities, TeamFame, isTeamMapPriorities } from '../../team';
import { TacticsTagsValue, Path, LocationDefinition, isPath } from './map/map';
import { RoundRole, TeamMapTactics, isRoundRole, isTeamMapTactics } from './tactic/tactic';
import { Card, isAthleteCard } from '../../card';
import { isMatchAthleteEffect } from '../../../buff-system/effects/match-athlete-effects';

export interface MatchLineup extends TeamBaseInfo {
  team: TeamRosterExtended;
  mapPriorities: TeamMapPriorities; // number: 1 .... CsMaps.length with 1 being least desired;
  mapTactics: TeamMapTactics;
  buffs: AppliedBuff[];
  activeEffectValues: ActiveAthleteEffectValues;
}
export function isMatchLineup(lineup: any): lineup is MatchLineup {
  return (
    Object.values(lineup.team).every((athlCard) => isAthleteCard(athlCard as Card)) &&
    isTeamMapPriorities(lineup.mapPriorities) &&
    isTeamMapTactics(lineup.mapTactics) &&
    Array.isArray(lineup.buffs)
  );
}

export type MatchAthleteUuid = MatchAthlete['uuid'];
export type MatchAthlete = Omit<
  Athlete,
  'powerDistribution' | 'visualGroup' | 'namingGroup' | 'appearance' | 'country' | 'firstName' | 'lastName' | 'nickName'
> & {
  role: CsRole;
  uuid: string;
  teamId: string;
  matchEffects: AthleteStatModifierEffect[];
};
export function isMatchAthlete(athl: any): athl is MatchAthlete {
  return (
    // from the Athlete
    isAthleteSkills(athl.skills) &&
    Array.isArray(athl.traits) &&
    athl.traits.every((trait: any) => isTrait(trait)) &&
    isTalents(athl.talents) &&
    isAthleteSystemStatModifiers(athl.systemStatModifiers) &&
    isAthleteChemistryStats(athl.chemistry) &&
    typeof athl.renown === 'number' &&
    // added
    isCsRole(athl.role) &&
    typeof athl.uuid === 'string' &&
    typeof athl.teamId === 'string' &&
    Array.isArray(athl.matchEffects) &&
    athl.matchEffects.every((effect: AthleteEffect) => isStatModifierEffect(effect))
  );
}

export interface MatchRoundAthlete extends MatchAthlete {
  health: number;
  tags: TacticsTagsValue[];
  pathIndex: number;
  stepList: Path;
  location: LocationDefinition['name'];
}
export function isMatchRoundAthlete(athl: any): athl is MatchRoundAthlete {
  return (
    typeof athl.health === 'number' &&
    typeof athl.pathIndex === 'number' &&
    isPath(athl.stepList) &&
    typeof athl.location === 'string' &&
    isMatchAthleteEffect(athl)
  );
}

export interface MatchTeam extends MatchTeamBase {
  athletes: MatchRoundAthlete[];
  stats?: any;
}
export function isMatchTeam(team: MatchTeamBase): team is MatchTeam {
  return Array.isArray(team.athletes) && team.athletes.every((athl) => isMatchRoundAthlete(athl));
}

export interface MatchTeamBase {
  tacticChoice: TeamMapTactics;
  mapPriorities: TeamMapPriorities;
  athletes: MatchAthlete[];
  uuid: string;
  userId: string;
  fame: TeamFame;
  buffs: AppliedBuff[];
}
export function isMatchTeamBase(teamBase: any): teamBase is MatchTeamBase {
  return (
    isTeamMapTactics(teamBase.tacticChoice) &&
    isTeamMapPriorities(teamBase.mapPriorities) &&
    Array.isArray(teamBase.athletes) &&
    teamBase.athletes.every((athl: any) => isMatchAthleteEffect(athl)) &&
    typeof teamBase.uuid === 'string' &&
    typeof teamBase.userId === 'string' &&
    Array.isArray(teamBase.buffs)
  );
}

export type WhoIs = Record<MatchTeamBase['uuid'], RoundRole>;
export function isWhoIs(whoIs: any): whoIs is WhoIs {
  return (
    Object.values(whoIs).length === 2 &&
    Object.entries(whoIs).every(([teamId, roundRole]) => typeof teamId == 'string' && isRoundRole(roundRole))
  );
}
