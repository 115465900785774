import { UserProfile } from '@/lib/store/auth/auth.utils';
import { getRequest, postRequest } from '@/lib/utils/request';
import {
  Card,
  MatchPreparation,
  MatchPreparationExtended,
  MatchResult,
  PurchaseRealMoneyOfferStripeUserTransaction,
  RankInState,
  ShopOfferDisplayType,
  ShopOfferRealMoney,
  Team,
  TeamLogo,
  UpdateMatchPreparationInput,
  UserCardPack,
  UserNotification,
  UserTransaction,
} from '@cstactics/types';

// USER CARDS

export async function queryUserCards() {
  return getRequest<Card[]>('/api/user/cards');
}

// USER CARD PACKS

export async function queryUserCardPacks() {
  return (await getRequest('/api/user/packs')) as UserCardPack[];
}

export async function openUserCardPack(packId: string) {
  return postRequest('/api/user/packs', { action: 'open', packId });
}

// USER TRANSACTION HISTORY

export async function queryUserTransactionHistory() {
  return getRequest<UserTransaction[]>('/api/user/transactions');
}

export async function queryUserTransaction(transactionId: string) {
  return getRequest<UserTransaction>(`/api/user/transactions/${transactionId}`);
}

// USER PROFILE

export async function changePersonalDetails(
  name?: string,
  lastName?: string,
  country?: string,
  city?: string,
  zipCode?: string,
  streetAndNr?: string
): Promise<UserProfile> {
  return postRequest(`/api/auth/updatedetails`, {
    name,
    lastName,
    address: `${streetAndNr};${zipCode};${city};${country}`,
  });
}

export async function queryUserProfile(): Promise<UserProfile> {
  return getRequest(`/api/user/profile`);
}

export async function uploadProfileImage(base64String: string, category: string) {
  return postRequest('/api/user/profile/image', { base64String, category });
}

export async function queryUserNotifications(): Promise<{ ok: boolean; notifications: UserNotification[] }> {
  return getRequest(`/api/user/notifications`);
}

export async function clearUserNotifications(): Promise<{ ok: boolean; notifications: UserNotification[] }> {
  return postRequest(`/api/user/notifications/mark-as-read`);
}

// USER TEAMS

export async function queryUserTeam(): Promise<Team | undefined> {
  return getRequest(`/api/user/teams`);
}

export async function createUserTeam(creationData: { name: string; tag: string; logo: TeamLogo }) {
  return postRequest('/api/user/teams', { ...creationData });
}

export async function updateUserTeam(teamId: string, team: Partial<Team>) {
  return postRequest(`/api/user/teams/${teamId}`, { team });
}

export async function hireUserTeamAthlete(teamId: string, athleteId: string) {
  return postRequest(`/api/user/teams/hire`, { teamId, athleteId });
}

export async function fireUserTeamAthlete(teamId: string, athleteId: string) {
  return postRequest(`/api/user/teams/fire`, { teamId, athleteId });
}

export async function updateUserTeamMapPriorities(teamId: string, mapPriorities: string[]) {
  return postRequest(`/api/user/teams/updatemappriorities`, { teamId, mapPriorities });
}

export async function updateUserTeamMapTactic(teamId: string, tactic: any) {
  return postRequest(`/api/user/teams/updatemaptactics`, { teamId, tactic });
}

// MATCHES

// TODO: use correct response type for types library here
export async function playPlacementMatches(teamId: string): Promise<any> {
  return postRequest(`/api/user/matches/rank-in`, { teamId });
}

export async function getRankInState(): Promise<RankInState> {
  return getRequest(`/api/user/matches/rank-in`) as Promise<RankInState>;
}

export async function getUpcomingMatches(): Promise<MatchPreparation[]> {
  return getRequest(`/api/user/matches/upcoming`);
}

export async function getMatchResults(): Promise<MatchResult[]> {
  return getRequest(`/api/user/matches/results`);
}

export async function getMatchResult(matchId: string): Promise<MatchResult> {
  return getRequest(`/api/user/matches/results/${matchId}`);
}

export async function getMatchPreparation(matchId: string): Promise<MatchPreparationExtended> {
  return getRequest(`/api/user/matches/preparations/${matchId}`);
}

export async function updateMatchPreparation(
  matchId: string,
  update: UpdateMatchPreparationInput
): Promise<MatchPreparation> {
  return postRequest(`/api/user/matches/preparations/${matchId}`, { update });
}

// SHOP

export async function purchaseInGameCurrencyOffer(
  offerId: string,
  displayType: ShopOfferDisplayType
): Promise<{ success: boolean; message?: string }> {
  return postRequest(`/api/user/shop/in-game-currency`, { offerId, displayType });
}

export async function submitVoucherCode(code: string): Promise<{ success: boolean; message?: string }> {
  return postRequest(`/api/user/shop/voucher`, { code });
}

export async function getStripeCheckoutUrl(email: string, stripePriceId: string): Promise<{ sessionUrl: string }> {
  const successUrl = `${window.location.origin}/shop/success`;
  const cancelUrl = `${window.location.origin}/shop`;

  return postRequest(`/api/user/shop/stripe-checkout`, { email, stripePriceId, successUrl, cancelUrl });
}

export async function resetShopDailyDrop(): Promise<{ success: boolean; message?: string }> {
  return postRequest(`/api/user/shop/reset-daily-drop`, {});
}

export async function getCheckoutResult(
  checkoutSessionId: string
): Promise<{ userTransaction?: PurchaseRealMoneyOfferStripeUserTransaction; offer?: ShopOfferRealMoney }> {
  return postRequest(`/api/user/shop/post-checkout`, { checkoutSessionId });
}
