import { CsMap, isCsMap } from '../../../athlete';
import { Team } from '../../../team';
import { MatchAthleteUuid, TeamStats, WinReason, isWinReason } from '../../simulation';
import { ActionBase } from './action';

export interface TeamActionBase extends ActionBase {
  teamId: string;
  actionType: TeamActionType;
}

export interface TeamMapBanAction extends TeamActionBase {
  mapName: CsMap;
  banned: boolean;
  actionType: TeamActionType.MAP_BAN;
}
export function isTeamMapBanAction(action: any): action is TeamMapBanAction {
  return action.actionType === TeamActionType.MAP_BAN && isCsMap(action.mapName) && typeof action.banned === 'boolean';
}

export interface TeamRoundResultAction extends TeamActionBase {
  winReason: WinReason;
  actionType: TeamActionType.ROUND_WIN;
}
export function isTeamRoundResultAction(action: any): action is TeamRoundResultAction {
  return action.actionType === TeamActionType.ROUND_WIN && isWinReason(action.winReason);
}

export interface TeamStatsAction extends TeamActionBase {
  actionType: TeamActionType.TEAM_STATS;
  stats: Record<string, TeamStats>;
}

export interface TeamEncounterAction extends Omit<TeamActionBase, 'teamId'> {
  actionType: TeamActionType.ENCOUNTER;
  participants: Record<Team['uuid'], MatchAthleteUuid[]>;
}

export enum TeamActionType {
  MAP_BAN = 'mapBan',
  ROUND_WIN = 'roundWin',
  TEAM_STATS = 'teamStats',
  ENCOUNTER = 'encounter',
}
export const teamActionTypeValues = Object.values(TeamActionType);
export function isTeamActionType(type: any): type is TeamActionType {
  return teamActionTypeValues.includes(type);
}

export type TeamAction = TeamMapBanAction | TeamRoundResultAction;
