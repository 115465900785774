import { StateCreator } from 'zustand';
import { createCardPacksSlice } from '../cardPacks/cardPacks.slice';
import { createCardsSlice } from '../cards/cards.slice';
import { GameAssetsState } from '../game-assets.store';
import { createMatchesSlice } from '../matches/matches.slice';
import { createTeamsSlice } from '../teams/teams.slice';
import { createRoomsSlice } from '@/lib/store/gameAssets/rooms/rooms.slice';
import { createFacilitySlice } from '@/lib/store/gameAssets/facility/facility.slice';
import { createSponsorsSlice } from '@/lib/store/gameAssets/sponsors/sponsors.slice';

export interface GameAssetsInitSlice {
  /** is initialized */
  isInitialized: boolean;

  /**
   * Initialize game assets store
   * • listen to auth state
   * • query user cards if user is authorized
   * • empty store in logout
   */
  init: () => void;
}

export const createGameAssetsInitSlice: StateCreator<GameAssetsState, [], [], GameAssetsInitSlice> = (
  set,
  get,
  ...a
) => ({
  isInitialized: false,
  init: () => {
    if (get().isInitialized) {
      console.warn('[cards-store] init called while already initialized');
      return;
    }
    set({ isInitialized: true });

    createCardsSlice(set, get, ...a)._initCards();
    createCardPacksSlice(set, get, ...a)._initCardPacks();
    createTeamsSlice(set, get, ...a)._initTeams();
    createMatchesSlice(set, get, ...a)._initMatches();
    createFacilitySlice(set, get, ...a)._initFacilities();
    createSponsorsSlice(set, get, ...a)._initSponsors();
    createRoomsSlice(set, get, ...a)._initRooms();
  },
});
