import { HistoryData } from './economy';

export interface PremiumCurrencyWallet {
  balance: number;
  historyData: HistoryData[];
}

export enum PremiumCurrencyExpenseType {
  SHOP_PURCHASE = 'shopPurchase',
  SHOP_REROLL = 'shopReroll',
  SPONSORSHIP_TIME_SKIP = 'sponsorshipTimeSkip',
  ROOM_BUILD_TIME_SKIP = 'roomBuildTimeSkip',
}

export enum PremiumCurrencyIncomeType {
  SHOP_OFFER = 'shopOffer',
  REWARD = 'reward',
  CARD_PACK = 'cardPack',
}
