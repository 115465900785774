import { useAuthStore } from '../../auth/auth.store';
import { AuthState } from '../../auth/auth.utils';
import { useGameAssetsStore } from '../game-assets.store';
import { queryUserCardPacks } from '@/lib/aws/user/user.api';

/**
 * Subscribe on auth store's auth state
 * • query card packs on authorized
 * • empty store on unauthorized
 */
export const _handleAuthStateChangeForCardPacks = () => {
  useAuthStore.subscribe(({ authState }) => {
    if (authState === AuthState.AUTHORIZED) {
      _onAuthorized();
    }
    if (authState === AuthState.UNAUTHORIZED) {
      _onUnauthorized();
    }
  });
};

/**
 * Handle Auth State Change to AUTHORIZED
 * Query User Card Packs
 * @param appUser
 */
const _onAuthorized = async () => {
  try {
    // query card packs
    const cardPacks = await queryUserCardPacks();
    console.debug('[cardPacks.utils] onAuthorized found packs::', cardPacks.length);
    // set cards in store
    useGameAssetsStore.setState({ cardPacks });
  } catch (error) {
    console.log('[cardPacks.utils] _onAuthorized error::', error);
  }
};

/**
 * Handle Auth State Change to UNAUTHORIZED
 * Empty User Card Packs
 */
const _onUnauthorized = async () => {
  useGameAssetsStore.setState({ cardPacks: undefined });
};
