import { AthleteEffect, isAthleteEffect } from '../../buff-system/effects/athlete-effects';
import { CsMap, CsRole, isCsMap, isCsRole } from './proficiencies';
import { isSkillName, SkillName } from './skills';
import {
  StatSelector,
  MathConditionCheck,
  IsConditionCheck,
  HasConditionCheck,
  isStatSelector,
  isMathConditionCheck,
  isHasConditionCheck,
  isConditionCheck,
} from './utils';

export type GenePair<T = any> = [
  firstGene: T,
  secondGene: T,
  /** dominate / recessive indicator Points to Index of dominate gene */
  dominateIndex: 0 | 1
];

export interface Trait {
  name: string;
  icon: string;
  description: string;
  tags: string[];
  powerValue: number;
  effects: AthleteEffect[];
}
export function isTrait(trait: any): trait is Trait {
  return (
    trait.name !== undefined &&
    trait.icon !== undefined &&
    trait.description !== undefined &&
    trait.effects.every((effect: any) => isAthleteEffect(effect))
  );
}

export interface TraitDefinition {
  trait: Trait;
  generationInfo: TraitGenerationInfo;
}
export function isTraitDefinition(td: any): td is TraitDefinition {
  return isTrait(td.trait) && isTraitGenerationInfo(td.generationInfo);
}

// GENERATION INFO

export interface TraitGenerationInfo {
  probability: number;
  probabilityModifiers: TraitProbabilityModifier[];
}
export function isTraitGenerationInfo(tgi: any): tgi is TraitGenerationInfo {
  return (
    !isNaN(tgi.probability) &&
    !isNaN(tgi.powerValue) &&
    tgi.probabilityModifiers.every((pm: any) => isTraitProbabilityModifier(pm))
  );
}

export interface TraitProbabilityModifier {
  calculationType: CalculationType;
  condition: TraitCondition;
  value: number;
}
export function isTraitProbabilityModifier(tpm: any): tpm is TraitProbabilityModifier {
  return isCalculationTyp(tpm.calculationType) && isTraitCondition(tpm.condition) && !isNaN(tpm.value);
}

export enum CalculationType {
  FLAT = 'flat',
  MULTIPLICATIVE = 'multiplicative',
  ADDITIVE = 'additive',
  BASE_FLAT = 'baseFlat',
}
export const calculationType = Object.values(CalculationType);
export function isCalculationTyp(ct: any): ct is CalculationType {
  return Object.values(CalculationType).includes(ct);
}

export type TraitCondition =
  | StatCondition
  | ProficiencyCondition
  | TraitNameCondition
  | TraitTagCondition
  | SuperficialElementCondition;

export function isTraitCondition(traitCondition: any): traitCondition is TraitCondition {
  return (
    isStatCondition(traitCondition) ||
    isProficiencyCondition(traitCondition) ||
    isTraitNameCondition(traitCondition) ||
    isTraitTagCondition(traitCondition) ||
    isSuperficialElementCondition(traitCondition)
  );
}

export enum TraitConditionType {
  STAT_CONDITION = 'StatCondition',
  PROFICIENCY_CONDITION = 'ProficiencyCondition',
  TRAIT_NAME_CONDITION = 'TraitNameCondition',
  TRAIT_TAG_CONDITION = 'TraitTagCondition',
  SUPERFICIAL_ELEMENT_CONDITION = 'SuperficialElementCondition',
}
export const traitConditionType = Object.values(TraitConditionType);
export function isTraitConditionType(tcp: any): tcp is TraitConditionType {
  return Object.values(TraitConditionType).includes(tcp);
}

export interface StatCondition {
  type: TraitConditionType.STAT_CONDITION;
  statName: SkillName | CsMap | CsRole | StatSelector;
  value: number;
  check: MathConditionCheck;
}

export function isStatCondition(condition: TraitCondition): condition is StatCondition {
  return (
    condition.type === TraitConditionType.STAT_CONDITION &&
    (isSkillName(condition.statName) ||
      isCsMap(condition.statName) ||
      isCsRole(condition.statName) ||
      isStatSelector(condition.statName)) &&
    !isNaN(condition.value) &&
    isMathConditionCheck(condition.check)
  );
}

export interface ProficiencyCondition {
  type: TraitConditionType.PROFICIENCY_CONDITION;
  proficiencyType: 'role' | 'map';
  proficiencyRank: 'primary' | 'secondary' | 'tertiary' | 'other';
  value: CsMap | CsRole;
  check: IsConditionCheck;
}

export function isProficiencyCondition(condition: TraitCondition): condition is ProficiencyCondition {
  return (
    condition.type === TraitConditionType.PROFICIENCY_CONDITION &&
    (condition.proficiencyType === 'role' || condition.proficiencyType === 'map') &&
    condition.proficiencyRank !== undefined &&
    (isCsMap(condition.value) || isCsRole(condition.value)) &&
    isConditionCheck(condition.check)
  );
}

export interface TraitNameCondition {
  type: TraitConditionType.TRAIT_NAME_CONDITION;
  traitName: string;
  check: HasConditionCheck;
}

export function isTraitNameCondition(condition: TraitCondition): condition is TraitNameCondition {
  return (
    condition.type === TraitConditionType.TRAIT_NAME_CONDITION &&
    condition.traitName !== undefined &&
    isHasConditionCheck(condition.check)
  );
}

export interface TraitTagCondition {
  type: TraitConditionType.TRAIT_TAG_CONDITION;
  tagName: string;
  check: HasConditionCheck;
}

export function isTraitTagCondition(condition: TraitCondition): condition is TraitTagCondition {
  return (
    condition.type === TraitConditionType.TRAIT_TAG_CONDITION &&
    condition.tagName !== undefined &&
    isHasConditionCheck(condition.check)
  );
}

export interface SuperficialElementCondition {
  type: TraitConditionType.SUPERFICIAL_ELEMENT_CONDITION;
  elementName: string;
  value: string;
  check: HasConditionCheck;
}

export function isSuperficialElementCondition(condition: TraitCondition): condition is SuperficialElementCondition {
  return (
    condition.type === TraitConditionType.SUPERFICIAL_ELEMENT_CONDITION &&
    condition.elementName !== undefined &&
    condition.value !== undefined &&
    isHasConditionCheck(condition.check)
  );
}
