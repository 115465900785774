export enum AppRoute {
  LANDING = '/',
  HELP = '/help',

  /** auth */
  LOGIN = '/auth/login',
  REGISTRATION = '/auth/registration',
  RECOVER = '/auth/recover',
  RESET_PASSWORD = '/auth/reset-password',
  ACTIVATE_ACCOUNT = '/auth/activate-account',

  /** athlete hub */
  ATHLETE_HUB = '/athlete-hub',

  /** team */
  TEAM = '/team',
  CREATE_TEAM = '/team/create-team',

  ACCOUNT = '/account',
  CHANGE_PASSWORD = '/account/change-password',
  PRIVACY_SETTINGS = '/account/privacy-settings',
  MARKETING_PREFERENCES = '/account/marketing-preferences',
  HISTORY_TRANSACTION = '/account/transaction-history',
  CHANGE_EMAIL = '/account/change-email',
  CHECKOUT_SUCCESS = '/messages/checkout/success',
  EDIT_PROFILE = '/account/edit-profile',
  DASHBOARD = '/dashboard',
  FOUR_OH_FOUR = '/404',
  CHECKOUT_CANCEL = '/messages/checkout/cancel',
  CARD = '/card',
  ECONOMY = '/economy',
  FACILITY = '/facility',
  INVENTORY = '/inventory',

  /** league */
  LEAGUE = '/league',
  LEAGUE_QUALIFIERS = '/league/qualifiers',

  /** matches */
  MATCH_PREPARATION = '/match-preparation',
  MATCH_RESULT = '/match-result',
  MATCH_SIMULATION = '/match-simulation',

  /** tournaments */
  // TOURNAMENTS = '/tournaments',

  // /** Training  */
  TRAINING = '/training',
  TRAINING_MATCH_RESULT = '/training-match-result',
  TACTICS = '/tactics',

  /** shop */
  SHOP = '/shop',
  REDEEM_VOUCHER = '/redeem-voucher',
}

export const publicRoutes: AppRoute[] = [
  AppRoute.LANDING,
  AppRoute.LOGIN,
  AppRoute.REGISTRATION,
  AppRoute.RECOVER,
  AppRoute.RESET_PASSWORD,
  AppRoute.ACTIVATE_ACCOUNT,
  AppRoute.HELP,
  AppRoute.FOUR_OH_FOUR,
];

export const appRoutes: AppRoute[] = Object.values(AppRoute);

export const privateRoutes: AppRoute[] = appRoutes.filter((route) => !publicRoutes.includes(route)); // paths that will be protected by auth in middleware
export const geoBlockedRoutes: AppRoute[] = [AppRoute.LOGIN, AppRoute.REGISTRATION]; // paths that will be blocked by geo in middleware
export const middlewareMatcher = [...privateRoutes, ...geoBlockedRoutes]; // paths that will trigger the middleware
export const matchRoutes: AppRoute[] = [AppRoute.MATCH_PREPARATION, AppRoute.MATCH_RESULT];
