export interface TeamStateEventBase {
  teamId: string;
  athleteId: string;
  type: TeamStateEventType;
}
export function isTeamStateEventBase(event: any): event is TeamStateEventBase {
  return isTeamStateEventType(event.type) && typeof event.teamId === 'string' && typeof event.athleteId === 'string';
}

export interface TeamStateDamageEvent extends TeamStateEventBase {
  type: TeamStateEventType.DAMAGE_TAKEN;
  damageTaken: number;
}
export function isTeamStateDamageEvent(event: any): event is TeamStateDamageEvent {
  return event.type === TeamStateEventType.DAMAGE_TAKEN && !isNaN(event.damageTaken) && isTeamStateEventBase(event);
}

export interface TeamStateDeathEvent extends TeamStateEventBase {
  type: TeamStateEventType.DEATH;
}
export function isTeamStateDeathEvent(event: any): event is TeamStateDeathEvent {
  return event.type === TeamStateEventType.DEATH && isTeamStateEventBase(event);
}

export enum TeamStateEventType {
  DAMAGE_TAKEN = 'damageTaken',
  // ASSIST = 'assist',
  // KILL = 'kill',
  DEATH = 'death',
}
export const teamStateEventTypeValues = Object.values(TeamStateEventType);
export function isTeamStateEventType(type: any): type is TeamStateEventType {
  return teamStateEventTypeValues.includes(type);
}

export type TeamStateEvent = TeamStateDamageEvent | TeamStateDeathEvent;
