import { StaffCard, StaffCardShort } from '@cstactics/types';
import { StateCreator } from 'zustand';
import { GameAssetsState } from '../game-assets.store';
import { _handleAuthStateChangeForCards } from '@/lib/store/gameAssets/facility/facility.utils';
import { getStaffCard } from '@/lib/store/gameAssets/facility/facility.actions';

export interface FacilitySlice {
  // states

  /** staff cards */
  staffCards?: StaffCardShort[];

  staffDetail?: StaffCard;

  /** get staff card by id */
  getStaffCard: (id: string) => Promise<StaffCard>;

  // init

  /**
   * !! Don't use this in code: It's called by InitCardsSlice.init !!
   * Initialize cards store
   * • listen to auth state
   * • query user cards if user is authorized
   * • empty store in logout
   */
  _initFacilities: () => void;
}

export const createFacilitySlice: StateCreator<GameAssetsState, [], [], FacilitySlice> = () => ({
  getStaffCard,
  _initFacilities: () => {
    _handleAuthStateChangeForCards();
  },
});
