import { debugError } from '@/lib/utils/debug';

const sendRequest = async (method: string, url: string, body?: any) => {
  const headers = { 'Content-Type': 'application/json' };
  const config: RequestInit = {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  };

  const response = await fetch(`${process.env.NEXT_PUBLIC_URL}${url}`, config);

  if (!response.ok) {
    const errorData = await response.json();
    console.error('Error occurred', errorData.message ?? '');
    return null;
  }

  try {
    return response.json();
  } catch (e: any) {
    debugError(e, 'Error while sending request');
    return null;
  }
};

const sendRequestWithHandlingErrors = async (method: string, url: string, body?: any) => {
  const headers = { 'Content-Type': 'application/json' };
  const config: RequestInit = {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  };

  const response = await fetch(`${process.env.NEXT_PUBLIC_URL}${url}`, config);

  if (!response.ok) {
    const errorData = await response.json();
    const errorMessage = errorData.message || 'Unknown error occurred';

    throw new Error(errorMessage);
  }

  try {
    return response.json();
  } catch (e: any) {
    debugError(e, 'Error while sending request');
    throw new Error('Failed to parse response data');
  }
};

export const postRequest = async (url: string, body?: any) => sendRequest('POST', url, body);

export const postRequestWithHandlingErrors = async (url: string, body: any) =>
  sendRequestWithHandlingErrors('POST', url, body);

export const getRequest = async <T>(url: string): Promise<T> => sendRequest('GET', url);
