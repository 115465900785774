import { ShopOfferInGameCurrency, ShopOfferRealMoney } from './shop-offers';

export enum ShopOfferPurchaseState {
  PURCHASED = 'purchased',
  NOT_PURCHASED = 'notPurchased',
  IN_PROGRESS = 'inProgress',
}

export interface UserShopOfferInGameCurrency {
  offer: ShopOfferInGameCurrency;
  purchased?: ShopOfferPurchaseState;
}

export interface UserShopOfferCategory {
  /** List of Offers shown to user */
  current: UserShopOfferInGameCurrency[];
  /** List of already shown Offer Ids*/
  alreadyShown: string[];
}

export interface UserShop {
  userId: string;
  reRollCost: number;
  cycling: UserShopOfferCategory;
  daily: UserShopOfferCategory;
}

export interface StaticShop {
  /** List of Real Money Offers shown to user */
  realMoney: ShopOfferRealMoney[];

  /** List of Static Offers shown to user */
  static: ShopOfferInGameCurrency[];
}

export type Shop = UserShop & StaticShop;
