import { RoomId, StaffType } from '../../../../../../facility';
import { MinMax } from '../../../../../athlete';
import { Rarity } from '../../../../../rack';
import { TEAMA_OR_TEAMB } from '../filter';

export interface TeamStatusFilter extends TeamStatusFilterDef {
  /** teamA or teamB */
  team?: TEAMA_OR_TEAMB;
  /** does the selected team have a sponsor with this name? */
  sponsorName?: string;
  /** does the selected team have a sponsor with this tags */
  sponsorTags?: string[];
  /** does the selected team have this rooms of this level?
   * ) 0 = any level fits
   * ) -1 = next level is currently buing built
   */
  room?: { type: RoomId | 'any'; level: number };
  /** does the selected team have a Staff of this Type and Rarity
   *  - for Type:  'any' = any type, as long as staff exists
   * - for Rarity: '-1' = any rarity fits
   */
  staff?: { type: StaffType | 'any'; rarity: Rarity | 'any' };
  /** What position does the team hold in their own bracket (percentile range)
   *  both values between 0 and 1
   */
  bracketPercentile?: MinMax;
  /** What league is this match in?
   * TODO: FIXME: not clear what is meant here
   * league.name or ladder.position
   */
  league?: string | number;
  /** Is there an Athlete for which this is the first match in this team?
   */
  firstLeagueMatch?: boolean;
}
export function isTeamStatusFilter(obj: any): obj is TeamStatusFilter {
  return Object.keys(obj).every((key) => isTeamStatusFilterChild(key) || isTeamStatusFilterParent(key));
}

export enum TeamStatusFilterParent {
  TEAM = 'team',
}
export const teamStatusFilterParentValues = Object.values(TeamStatusFilterParent);
export function isTeamStatusFilterParent(obj: any): obj is TeamStatusFilterParent {
  return teamStatusFilterParentValues.includes(obj);
}

export enum TeamStatusFilterChild {
  SPONSOR_NAME = 'sponsorName',
  SPONSOR_TAGS = 'sponsorTags',
  ROOM = 'room',
  STAFF = 'staff',
  BRACKET_PERCENTILE = 'bracketPercentile',
  LEAGUE = 'league',
  FIRST_LEAGUE_MATCH = 'firstLeagueMatch',
}
export const teamStatusFilterChildValues = Object.values(TeamStatusFilterChild);
export function isTeamStatusFilterChild(obj: any): obj is TeamStatusFilterChild {
  return teamStatusFilterChildValues.includes(obj);
}

export type TeamStatusFilterDef = Partial<Record<TeamStatusFilterChild | TeamStatusFilterParent, any>>;
