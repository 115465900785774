// import { Hardware } from './hardware/hardware';
import { Athlete, AthleteShort } from '../athlete';
import { Rarity } from '../rack';
import { MediaItem } from '../../utils';
// import { Consumable } from './consumable/consumable';
// import { AthleteUpgrade } from '../buffs/upgrade/upgrade';
import { CurrencyCardContent } from './currency/currencyCard';
import { Staff, StaffShort } from './staff/staffCard';
import { SponsorshipContractUnsigned } from '../../facility';

export enum CardType {
  ATHLETE = 'athlete',
  PREMIUM_CURRENCY = 'premiumCurrency',
  SOFT_CURRENCY = 'softCurrency',
  // CURRENCY = 'currency',
  STAFF = 'staff',
  SPONSORSHIP_CONTRACT = 'sponsorshipContract',
  // HARDWARE = 'hardware', // not in scope for launch
  // UPGRADE = 'upgrade', // not in scope for launch
  // CONSUMABLE = 'consumable', // not in scope for launch
}

export const cardTypeValues = Object.values(CardType);
export function isCardType(ctv: any): ctv is CardType {
  return cardTypeValues.includes(ctv);
}

// CARD

export interface Card {
  uuid: string;
  type: CardType;
  image: MediaItem;
  rarity: Rarity;
  content: CardContent;
}

export type CardContent =
  // | Hardware | Consumable | AthleteUpgrade | // not in scope for launch
  Athlete | CurrencyCardContent | Staff | SponsorshipContractUnsigned;

// CARD SHORT
export interface CardShort {
  uuid: string;
  type: CardType;
  image: MediaItem;
  rarity: Rarity;
  content: CardContentShort;
}

export type CardContentShort = AthleteShort | StaffShort;
