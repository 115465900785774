import { ActionTimeCost, MatchRoundDuration, isActionTimeCost, isMatchRoundDuration } from '../csgo-manager';

export interface MatchDefinition {
  /** The amount of rounds that should be played in this match.  */
  amountRounds: number;

  /** The tie breaker method to be used in this match. FIRST_TO_X */
  tieBreaker: TieBreakerType;

  /** Values used for stat value changes after match */
  statValueChanges: MatchStatValueChanges;

  /** Duration of pre and post bomb phase */
  timeLimits: MatchRoundDuration;

  /** Amount time used for actions (movement , encounter) */
  actionCosts: ActionTimeCost;
}

export function isMatchDefintion(obj: any): obj is MatchDefinition {
  return (
    typeof obj.amountRounds === 'number' &&
    typeof obj.tieBreaker === 'number' &&
    isMatchStatValueChanges(obj.statValueChanges) &&
    isMatchRoundDuration(obj.timeLimits) &&
    isActionTimeCost(obj.timeCost)
  );
}

/** Tie breaker method: FIRST TO X*/
export enum TieBreakerType {
  /** No tie breaker. Ties are allowed */
  NONE = 0,

  /** First team to lead with 1 round wins*/
  DISTANCE_1 = 1,

  /** First team to lead with 2 rounds wins*/
  DISTANCE_2 = 2,
}

export interface MatchStatValueChanges {
  /** Values to used to change chemistry */
  chemistry: {
    /** How much chemistry do athletes gain for a won encounter? */
    win: number;

    /** How much chemistry do athletes gain for a lost encounter? */
    loss: number;
  };

  /** Values used to change renown */
  renown: {
    /** Balancing Value for Renown calculation at the end of the match. */
    factor: number;

    /** Balancing Value for Renown calculation at the end of the match. */
    offset: number;
  };

  /** How much endurance is used up by each athlete for this match. */
  enduranceLoss: number;

  /** Values used to change stat and proficiency values after training matches */
  trainingFactors?: {
    /** What is the multiplier for training core stats within this match. */
    stat: number;

    /** What is the multiplier for training proficiencies with this match */
    proficiency: number;
  };

  // fame: {
  //   win: number;
  //   loss: number;
  // };
}

export function isMatchStatValueChanges(obj: any): obj is MatchStatValueChanges {
  return typeof obj.chemistry.loss === 'number' &&
    typeof obj.chemistry.win === 'number' &&
    typeof obj.renown.factor === 'number' &&
    typeof obj.renown.offset === 'number' &&
    typeof obj.enduranceLoss === 'number' &&
    // typeof obj.fame.win === 'number' &&
    // typeof obj.fame.loss === 'number' &&
    obj.trainingFactors
    ? typeof obj.trainingFactors.stat === 'number' && typeof obj.trainingFactors.proficiency === 'number'
    : true;
}
