export interface StripeEvent<T> {
  id: string;
  object: string;
  api_version: string;
  created: number;
  data: T;
}

export interface StripeSessionCompletedEvent {
  object: {
    id: string;
    object: StripeEventObject.CHECKOUT_SESSION;
    // after_expiration: {
    //   recovery: { ... }
    // }
    allow_promotion_codes: boolean | null;
    amount_subtotal: number;
    amount_total: number;
    automatic_tax: {
      enabled: boolean;
      status: 'requires_location_inputs' | 'complete' | 'failed' | null;
    };
    cancel_url: string;
    /** Cognito User Id */
    client_reference_id: string;
    created: number;
    currency: 'eur';
    /** stripe customer */
    customer: string;
    customer_details: {
      email: string;
      name: string;
      tax_exempt: string;
      tax_ids: Array<{ type: string; value: string }>;
    };
    customer_email: string;
    expires_at: number;
    livemode: boolean;
    locale: string;
    metadata: {
      /** Card Pack Offer Id */
      cardPackId: string;
    };
    mode: string;
    /** stripe transaction id */
    payment_intent: string;
    // payment_link: null,
    // payment_method_collection: 'always',
    // payment_method_options: {},
    // payment_method_types: ['card', 'bancontact', 'eps', 'giropay', 'ideal'],
    payment_status: 'paid' | 'unpaid' | 'no_payment_required';
    // phone_number_collection: {
    //   enabled: false;
    // };
    recovered_from: string;
    // setup_intent: null;
    // shipping: null;
    // shipping_address_collection: null;
    // shipping_options: [];
    // shipping_rate: null;
    status: 'open' | 'complete' | 'expired';
    submit_type: null;
    subscription: null;
    success_url: string;
    total_details: {
      amount_discount: 0;
      amount_shipping: 0;
      amount_tax: 0;
    };
    url: null;
  };
}

export const isStripeSessionCompletedEvent = (event: any): event is StripeSessionCompletedEvent => {
  return event.data.object.object === StripeEventObject.CHECKOUT_SESSION;
};

export enum StripeEventObject {
  CHECKOUT_SESSION = 'checkout.session',
}

export interface StripeCreateCheckoutSessionProps {
  userId: string;
  email: string;
  stripePriceId: string;
  successUrl: string;
  cancelUrl: string;
}
