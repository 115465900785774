export enum AnalystType {
  ANALYZER = 'analyzer', // boosts gameSense
  TRAINER = 'trainer', // boosts success from training
  MAP_KNOWLEDGE = 'mapKnowledge', // increase proficiency on all maps
  ROLE_KNOWLEDGE = 'roleKnowledge', // increase proficiency on all roles
}

export const analystTypeValues = Object.values(AnalystType);
export function isAnalystType(obj: any): obj is AnalystType {
  return analystTypeValues.includes(obj);
}
