import { ChevronDownIcon } from '@heroicons/react/outline';
import { Components } from '@mui/material';
import { FC } from 'react';
import IconContainer from '@/components/atoms/IconContainer/IconContainer';

const AppSelectDropdownIcon: FC = () => {
  return (
    <IconContainer>
      <ChevronDownIcon />
    </IconContainer>
  );
};

const MuiSelectOverrides: Components = {
  MuiSelect: {
    defaultProps: {
      IconComponent: AppSelectDropdownIcon,
    },
  },
};

export default MuiSelectOverrides;
