import { useAuthStore } from '../../auth/auth.store';
import { AuthState } from '../../auth/auth.utils';
import { useGameAssetsStore } from '../game-assets.store';
import { queryUserSponsorshipContracts, queryUserSponsorshipContractsSlots } from '@/lib/aws/user/user-facility.api';

/**
 * Subscribe on auth store's auth state
 * • query cards on authorized
 * • empty store on unauthorized
 */
export const _handleAuthStateChangeForSponsors = () => {
  useAuthStore.subscribe(({ authState }) => {
    if (authState === AuthState.AUTHORIZED) {
      _onAuthorized();
    }
    if (authState === AuthState.UNAUTHORIZED) {
      _onUnauthorized();
    }
  });
};
/**
 * Handle Auth State Change to AUTHORIZED
 * Query and Sort User Cards
 * @param appUser
 */
const _onAuthorized = async () => {
  try {
    // query sponsors
    const sponsors: any = await queryUserSponsorshipContracts();
    const signedContracts: any = await queryUserSponsorshipContractsSlots();
    console.debug('[sponsors.utils] onAuthorized found sponsors::', sponsors.length);
    // set sponsors in store
    useGameAssetsStore.setState({ sponsors });
    useGameAssetsStore.setState({ signedContracts });
  } catch (error) {
    console.error(`[sponsors.utils] _onAuthorized::error`, error);
  }
};
/**
 * Handle Auth State Change to UNAUTHORIZED
 * Empty User Cards
 */
const _onUnauthorized = async () => {
  useGameAssetsStore.setState({ sponsors: undefined, signedContracts: undefined });
};
