import { CsRole, Rarity } from '../../csgo-manager';
import { UpcomingMatchType } from '../../matches';
import { Reward } from '../../rewards';
import { RoomId } from '../rooms';
import { StaffType } from '../staff';

export interface SponsorshipContractBase {
  uuid: string;
  rarity: Rarity;
  image: string;
  tags: string[];
  name: string;
  description: string;
  duration: number;
  isDefault?: boolean;
}

export interface SponsorshipContractUnsigned extends SponsorshipContractBase {
  successConditions: SponsorshipSuccessCondition[];
  signUpReward: SponsorshipRewardFameCondition[];
  successReward: SponsorshipRewardFameCondition[];
  failReward: SponsorshipRewardFameCondition[];
}
export interface SponsorshipContractSigned extends SponsorshipContractBase {
  successConditions: SponsorshipSuccessCondition[];
  signDate: number;
  endDate: number;
  signUpReward: Reward;
  successReward: Reward;
  failReward: Reward;
}

export interface UserSponsorshipContractUnsigned extends SponsorshipContractBase {
  successConditions: UserSponsorshipSuccessCondition[];
  signUpReward: Reward;
  successReward: Reward;
  failReward: Reward;
}
export interface UserSponsorshipContractSigned extends SponsorshipContractBase {
  successConditions: UserSponsorshipSuccessCondition[];
  signDate: number;
  endDate: number;
  signUpReward: Reward;
  successReward: Reward;
  failReward: Reward;
}

export interface SponsorshipContractOverrides {
  contractId?: string;
}

export interface SponsorshipRewardFameCondition {
  minFame: number;
  reward: Reward;
}

export enum SponsorshipSuccessConditionType {
  WIN_X_MATCHES = 'winXMatches',
  PLAY_X_MATCHES = 'playXMatches',
  PLAY_X_MATCHES_WITH_Y_WIN_RATE = 'playXMatchesWithYWinRate',
  X_AMOUNT_KILLS = 'xAmountKills',
  UPGRADE_ROOM_TO_LEVEL_X = 'upgradeRoomToLevelX',
  HIRE_FIRE_X_AMOUNT_OF_STAFFS = 'hireFireXAmountOfStaffs',
  HIRE_FIRE_X_AMOUNT_OF_ATHLETES = 'hireFireXAmountOfAthletes',
}

export type SponsorshipSuccessCondition =
  | SponsorshipSuccessConditionWinXMatches
  | SponsorshipSuccessConditionPlayXMatches
  | SponsorshipSuccessConditionPlayXMatchesWithYWinRate
  | SponsorshipSuccessConditionXAmountKills
  | SponsorshipSuccessConditionUpgradeRoomToLevelX
  | SponsorshipSuccessConditionHireFireXAmountOfStaffs
  | SponsorshipSuccessConditionHireFireXAmountOfAthletes;

export type UserSponsorshipSuccessCondition = SponsorshipSuccessCondition & { label: string };

export interface SponsorshipSuccessConditionBase {
  type: SponsorshipSuccessConditionType;
  label?: string;
  x: { current: number; target: number };
}

export interface SponsorshipSuccessConditionBaseWithY extends SponsorshipSuccessConditionBase {
  y: { current: number; target: number };
}

export function isSponsorshipSuccessConditionWithY(obj: any): obj is SponsorshipSuccessConditionBaseWithY {
  return obj.y !== undefined;
}

export interface SponsorshipSuccessConditionWinXMatches extends SponsorshipSuccessConditionBase {
  type: SponsorshipSuccessConditionType.WIN_X_MATCHES;
  matchType: UpcomingMatchType | 'any';
}

export interface SponsorshipSuccessConditionPlayXMatches extends SponsorshipSuccessConditionBase {
  type: SponsorshipSuccessConditionType.PLAY_X_MATCHES;
  matchType: UpcomingMatchType | 'any';
}

export interface SponsorshipSuccessConditionPlayXMatchesWithYWinRate extends SponsorshipSuccessConditionBaseWithY {
  type: SponsorshipSuccessConditionType.PLAY_X_MATCHES_WITH_Y_WIN_RATE;
  matchType: UpcomingMatchType | 'any';
}

export interface SponsorshipSuccessConditionXAmountKills extends SponsorshipSuccessConditionBase {
  type: SponsorshipSuccessConditionType.X_AMOUNT_KILLS;
  matchType: UpcomingMatchType | 'any';
}

export interface SponsorshipSuccessConditionUpgradeRoomToLevelX extends SponsorshipSuccessConditionBase {
  type: SponsorshipSuccessConditionType.UPGRADE_ROOM_TO_LEVEL_X;
  roomId: RoomId | 'any';
}

export interface SponsorshipSuccessConditionHireFireXAmountOfStaffs extends SponsorshipSuccessConditionBase {
  type: SponsorshipSuccessConditionType.HIRE_FIRE_X_AMOUNT_OF_STAFFS;
  actionType: 'hire' | 'fire';
  staffType: StaffType | 'any';
}

export interface SponsorshipSuccessConditionHireFireXAmountOfAthletes extends SponsorshipSuccessConditionBase {
  type: SponsorshipSuccessConditionType.HIRE_FIRE_X_AMOUNT_OF_ATHLETES;
  actionType: 'hire' | 'fire';
  role: CsRole | 'any';
}

export function isSuccessConditionType<T extends SponsorshipSuccessConditionBase>(obj: any, type: T['type']): obj is T {
  return obj.type === type;
}
