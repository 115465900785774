export interface TrainingDifficultiesResponse {
  name: TrainingDifficulty;
  difficulty: TrainingDifficultyIndicator;
}

export type TrainingDifficulties = TrainingDifficultiesResponse[];

export interface TrainingDifficultyMapping {
  name: TrainingDifficulty;
  power: number;
  winStatIncreaseMin: number;
  winStatIncreaseMax: number;
  minStrength: number;
  maxStrength: number;
}

export enum TrainingDifficulty {
  Rookie = 'Rookie',
  Noob = 'Noob',
  Amateurs = 'Amateurs',
  LocalProTeam = 'Local Pro Team',
  ProTeam = 'Pro Team',
  RisingStars = 'Rising Stars',
  SuperstarTeam = 'Superstar Team',
}
export enum TrainingDifficultyIndicator {
  VeryEasy = 'Very Easy',
  Easy = 'Easy',
  LikelyWin = 'Likely Win',
  Normal = 'Normal',
  SlightChallenge = 'Slight Challenge',
  Hard = 'Hard',
  Impossible = 'Impossible',
}
