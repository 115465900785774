import { CsRole, isCsRole } from '../../../athlete/proficiencies';
import { SkillName, isSkillName } from '../../../athlete/skills';
import { Trait } from '../../../athlete/traits';

export enum EncounterApproach {
  ATTACK = 'attack',
  DEFEND = 'defend',
  USE_UTIL = 'use_util',
}
export const encounterApproachValues = Object.values(EncounterApproach);
export function isEncounterApproach(approach: any): approach is EncounterApproach {
  return encounterApproachValues.includes(approach);
}

export type Approaches = ApproachDefinition[];

export interface ApproachDefinition {
  name: string;
  type: EncounterApproach;
  tags: string[];
  strongAgainst: EncounterApproach;
  strongBonus: number;
  weakAgainst: EncounterApproach;
  offensiveSkill: SkillName;
  defensiveSkill: SkillName;
  successDamage: number;
  failDamage: number;
  weight: number;
  probabilityModifiers: ApproachProbabilityModifier[];
}
export function isApproachDefinition(def: any): def is ApproachDefinition {
  return (
    typeof def.name === 'string' &&
    [def.type, def.strongAgainst, def.weakAgainst].every((type) => isEncounterApproach(type)) &&
    def.tags.every((tag: any) => typeof tag === 'string') &&
    [def.weight, def.failDamage, def.successDamage, def.strongBonus].every((entry) => typeof entry === 'number') &&
    [def.offensiveSkill, def.defensiveSkill].every((entry) => isSkillName(entry))
  );
}

export type ApproachDefinitionName = ApproachDefinition['name'];

export interface ApproachProbabilityModifier {
  quantity: number;
  type: ApproachProbabilityModifierType;
  trigger: ApproachProbabilityModifierTriggerRole | ApproachProbabilityModifierTriggerTrait;
}
export function isApproachProbabilityModifier(modifier: any): modifier is ApproachProbabilityModifier {
  return (
    typeof modifier.quantity === 'number' &&
    isApproachProbabilityModifierType(modifier.type) &&
    (isApproachProbabilityModifierTriggerRole(modifier.trigger) ||
      isApproachProbabilityModifierTriggerTrait(modifier.trigger))
  );
}

export interface ApproachProbabilityModifierTriggerRole {
  type: ApproachProbabilityModifierTriggerType.ROLE;
  value: CsRole;
}
export function isApproachProbabilityModifierTriggerRole(
  modTrigger: any
): modTrigger is ApproachProbabilityModifierTriggerRole {
  return modTrigger.type === ApproachProbabilityModifierTriggerType.ROLE && isCsRole(modTrigger.value);
}

export interface ApproachProbabilityModifierTriggerTrait {
  type: ApproachProbabilityModifierTriggerType.TRAIT;
  value: Trait['name'];
}
export function isApproachProbabilityModifierTriggerTrait(
  modTrigger: any
): modTrigger is ApproachProbabilityModifierTriggerTrait {
  return modTrigger.type === ApproachProbabilityModifierTriggerType.TRAIT && typeof modTrigger.value === 'string';
}

export enum ApproachProbabilityModifierType {
  MULTIPLICATIVE = 'multiplicative',
  ADDITIVE = 'additive',
}
export const approachProbabilityModifierTypeValues = Object.values(ApproachProbabilityModifierType);
export function isApproachProbabilityModifierType(type: any): type is ApproachProbabilityModifierType {
  return approachProbabilityModifierTypeValues.includes(type);
}

export enum ApproachProbabilityModifierTriggerType {
  ROLE = 'role',
  TRAIT = 'trait',
}
export const approachProbabilityModifierTriggerTypeValues = Object.values(ApproachProbabilityModifierTriggerType);
export function isApproachProbabilityModifierTriggerType(type: any): type is ApproachProbabilityModifierTriggerType {
  return approachProbabilityModifierTriggerTypeValues.includes(type);
}

export enum EncounterActionRole {
  OFFENSE = 'offense',
  DEFENSE = 'defense',
}
export const encounterActionRoleValues = Object.values(EncounterActionRole);
export function isEncounterActionRole(role: any): role is EncounterActionRole {
  return encounterActionRoleValues.includes(role);
}
