export enum NutritionistType {
  FITNESS_FOOD = 'fitnessFood', // modifies endurance regeneration
  POWER_FOOD = 'powerFood', // slightly increases accuracy and reflexes
}

export const nutritionistTypeValues = Object.values(NutritionistType);

export function isNutritionistType(obj: any): obj is NutritionistType {
  return nutritionistTypeValues.includes(obj);
}
