import { StaffCardShort } from '../../csgo-manager';
import { AnalystType } from './analyst/analyst';
import { CoachType } from './coach/coach';
import { FacilityManagerType } from './facilityManager/facilityManager';
import { FitnessTrainerType } from './fitnessTrainer/fitnessTrainer';
import { ItTechType } from './itTech/itTech';
import { NutritionistType } from './nutritionist/nutritionist';
import { PrManagerType } from './prManager/prManager';
import { PsychologistType } from './psychologist/psychologist';

export enum StaffType {
  COACH = 'coach',
  FITNESS_TRAINER = 'fitnessTrainer',
  ANALYST = 'analyst',
  FACILITY_MANAGER = 'facilityManager',
  PR_MANAGER = 'prManager',
  SPORT_PSYCHOLOGIST = 'sportPsychologist',
  NUTRITIONIST = 'nutritionist',
  IT_TECH = 'itTech',
}

export type StaffArchetype =
  | AnalystType
  | CoachType
  | FacilityManagerType
  | FitnessTrainerType
  | ItTechType
  | NutritionistType
  | PrManagerType
  | PsychologistType;

export const staffArchetypeValues = [
  ...Object.values(AnalystType),
  ...Object.values(CoachType),
  ...Object.values(FacilityManagerType),
  ...Object.values(FitnessTrainerType),
  ...Object.values(ItTechType),
  ...Object.values(NutritionistType),
  ...Object.values(PrManagerType),
  ...Object.values(PsychologistType),
];

export enum StaffSlotType {
  NON_SPECIALIZED = 'nonSpecialized',
  COACH = 'coach',
  ANALYST = 'analyst',
}

export const staffTypeValues = Object.values(StaffType);

export function isStaffType(type: string): type is StaffType {
  return staffTypeValues.includes(type as any);
}

// STAFF SLOTS
export interface AssignedStaffDB {
  uuid: string;
  type: StaffType;
}

export interface StaffSlotCategoryDB {
  type: StaffSlotType;
  amount: number;
  assignedStaffs: Array<AssignedStaffDB>;
}

export type StaffSlotsDB = Record<StaffSlotType, StaffSlotCategoryDB>;

export interface StaffSlotCategory {
  type: StaffSlotType;
  amount: number;
  assignedStaffs: StaffCardShort[];
}

export type StaffSlots = Record<StaffSlotType, StaffSlotCategory>;

export interface LostStaffSlotsCategory {
  type: StaffSlotType;
  amount: number;
  staffsToFireIds: string[];
}

export interface StaffOverrides {
  type?: StaffType;
}
