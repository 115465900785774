import { Rarity } from '../rack';
import { Gene, isGene } from './genes';

//SUPERFICIALS
export type Superficials = {
  country: CountryNames;
  gender: Gender;
  firstName: string;
  lastName: string;
  nickName: string;
  appearance: Appearance;
};
export function isSuperficials(superficial: any): superficial is Superficials {
  return (
    isCountryName(superficial.country) &&
    isGender(superficial.gender) &&
    superficial.firstName !== undefined &&
    superficial.lastName !== undefined &&
    superficial.nickName !== undefined &&
    isAppearance(superficial.appearance)
  );
}

// COUNTRY
export enum CountryNames {
  UNITED_STATES = 'United States',
  SWEDEN = 'Sweden',
  RUSSIAN_FEDERATION = 'Russian Federation',
  BRAZIL = 'Brazil',
  FRANCE = 'France',
  GERMANY = 'Germany',
  POLAND = 'Poland',
  AUSTRALIA = 'Australia',
  CANADA = 'Canada',
  UNITED_KINGDOM = 'United Kingdom',
  DENMARK = 'Denmark',
  CHINA = 'China',
  FINLAND = 'Finland',
  NORWAY = 'Norway',
  UKRAINE = 'Ukraine',
  SOUTH_AFRICA = 'South Africa',
  SPAIN = 'Spain',
  PORTUGAL = 'Portugal',
  TURKEY = 'Turkey',
  INDIA = 'India',
  ARGENTINA = 'Argentina',
  KOREA = 'Korea',
  NETHERLANDS = 'Netherlands',
  LATVIA = 'Latvia',
  HUNGARY = 'Hungary',
  ROMANIA = 'Romania',
  BULGARIA = 'Bulgaria',
  THAILAND = 'Thailand',
  LITHUANIA = 'Lithuania',
  CZECH_REPUBLIC = 'Czech Republic',
  VIET_NAM = 'Viet Nam',
  MALAYSIA = 'Malaysia',
  BELGIUM = 'Belgium',
  ESTONIA = 'Estonia',
  SINGAPORE = 'Singapore',
  PERU = 'Peru',
  SLOVAKIA = 'Slovakia',
  CHILE = 'Chile',
  PHILIPPINES = 'Philippines',
  BELARUS = 'Belarus',
  MONGOLIA = 'Mongolia',
  SERBIA = 'Serbia',
  KAZAKHSTAN = 'Kazakhstan',
  AUSTRIA = 'Austria',
  JAPAN = 'Japan',
  INDONESIA = 'Indonesia',
  SWITZERLAND = 'Switzerland',
  HONG_KONG = 'Hong Kong',
  ITALY = 'Italy',
  ICELAND = 'Iceland',
  ISRAEL = 'Israel',
  TAIWAN = 'Taiwan',
  MALTA = 'Malta',
  IRAN = 'Iran',
  GREECE = 'Greece',
  NEW_ZEALAND = 'New Zealand',
  PAKISTAN = 'Pakistan',
  UNITED_ARAB_EMIRATES = 'United Arab Emirates',
  MEXICO = 'Mexico',
  CAMBODIA = 'Cambodia',
  BRUNEI = 'Brunei',
  GEORGIA = 'Georgia',
  MYANMAR = 'Myanmar',
  NORTH_MACEDONIA = 'North Macedonia',
  LEBANON = 'Lebanon',
  IRELAND = 'Ireland',
  VENEZUELA = 'Venezuela',
  SLOVENIA = 'Slovenia',
  COLOMBIA = 'Colombia',
  CROATIA = 'Croatia',
  BOSNIA_AND_HERZEGOVINA = 'Bosnia and Herzegovina',
  AZERBAIJAN = 'Azerbaijan',
  ALGERIA = 'Algeria',
  KUWAIT = 'Kuwait',
  LAOS = 'Laos',
  TUNISIA = 'Tunisia',
  FAROE_ISLANDS = 'Faroe Islands',
  UZBEKISTAN = 'Uzbekistan',
  JORDAN = 'Jordan',
  KOSOVO = 'Kosovo',
  MOROCCO = 'Morocco',
  ALBANIA = 'Albania',
  MOLDOVA = 'Moldova',
  KYRGYZSTAN = 'Kyrgyzstan',
  IRAQ = 'Iraq',
  URUGUAY = 'Uruguay',
  SAUDI_ARABIA = 'Saudi Arabia',
  SYRIAN_ARAB_REPUBLIC = 'Syrian Arab Republic',
  GUATEMALA = 'Guatemala',
  SRI_LANKA = 'Sri Lanka',
  BANGLADESH = 'Bangladesh',
  MAURITIUS = 'Mauritius',
  PARAGUAY = 'Paraguay',
  PANAMA = 'Panama',
  MONTENEGRO = 'Montenegro',
  ECUADOR = 'Ecuador',
  LUXEMBOURG = 'Luxembourg',
  CUBA = 'Cuba',
  AFGHANISTAN_ = 'Afghanistan ',
  PALESTINIAN_TERRITORY = 'Palestinian Territory',
  DOMINICAN_REPUBLIC = 'Dominican Republic',
  QATAR = 'Qatar',
  BAHRAIN = 'Bahrain',
  ARMENIA = 'Armenia',
  COSTA_RICA = 'Costa Rica',
  CYPRUS = 'Cyprus',
  NEW_CALEDONIA = 'New Caledonia',
  EGYPT = 'Egypt',
  MALDIVES = 'Maldives',
  JERSEY = 'Jersey',
  SAINT_MARTIN = 'Saint Martin',
  BOLIVIA = 'Bolivia',
}
export const countryNames = Object.values(CountryNames);
export function isCountryName(countryName: any): countryName is CountryNames {
  return Object.values(CountryNames).includes(countryName);
}

export type probability = number;

export type CountryDefinition = {
  name: CountryNames;
  flag: string;
  gene: Gene;
  weight: number;
  namingGroups: [NamingGroups, probability][];
  visualGroups: [VisualGroups, probability][];
};

export function isCountryDefinition(countryDef: any): countryDef is CountryDefinition {
  return (
    isCountryName(countryDef.name) &&
    countryDef.flag !== undefined &&
    isGene(countryDef.gene) &&
    !isNaN(countryDef.weight) &&
    countryDef.namingGroups.every((element: any) => isNamingGroups(element[0]) && !isNaN(element[1])) &&
    countryDef.visualGroups.every((element: any) => isVisualGroups(element[0]) && !isNaN(element[1]))
  );
}

// GENDER
export enum Gender {
  MALE = 'Male', // or the Gene 'MA'
  FEMALE = 'Female', // or the Gene 'FE'
  NON_BINARY = 'Non-Binary', // or the Gene 'nb'
}
export const genders = Object.values(Gender);
export function isGender(gender: Gender): gender is Gender {
  return Object.values(Gender).includes(gender);
}

// NAME

export enum NamingGroups {
  AFRICAN = 'African',
  ARABIC = 'Arabic',
  ASIAN = 'Asian',
  BALKANS = 'Balkans',
  BALTIC = 'Baltic',
  BRAZIL = 'Brazil',
  CENTRALEUROPE = 'CentralEurope',
  CHINESE = 'Chinese',
  DANISH = 'Danish',
  EASTERNBALTIC = 'EasternBaltic',
  ENGLISH = 'English',
  ESTONIA = 'Estonia',
  FRENCH = 'French',
  GREEK = 'Greek',
  HISPANIC = 'Hispanic',
  INDIAN = 'Indian',
  ITALIAN = 'Italian',
  JAPANESE = 'Japanese',
  LATVIA = 'Latvia',
  LITHUANIA = 'Lithuania',
  POLISH = 'Polish',
  RUSSIAN = 'Russian',
  SWEDISH = 'Swedish',
  SOUTHEASTASIAN = 'SouthEastAsian',
  TURKIC = 'Turkic',
}
export const namingGroups = Object.values(NamingGroups);
export function isNamingGroups(namingGroup: any): namingGroup is NamingGroups {
  return Object.values(NamingGroups).includes(namingGroup);
}

// NAMINGTABLE
export type NamingTable = {
  name: string;
  genders: Gender[];
  weight: number;
}[];
export function isNamingTable(namingTable: any): namingTable is NamingTable {
  return (
    !!namingTable.length &&
    namingTable.every((element: any) => {
      element.name !== undefined &&
        !isNaN(element.weight) &&
        namingTable.genders.length &&
        namingTable.genders.every((gender: any) => isGender(gender));
    })
  );
}

// APPEARANCE

export enum VisualGroups {
  ALL = 'ALL',
  AFRICAN = 'African',
  AMERICAN = 'American',
  ARABIC = 'Arabic',
  ASIAN = 'Asian',
  BALKANS = 'Balkans',
  EUROPEAN = 'European',
  INDIAN = 'Indian',
  LATINO = 'Latino',
}
export const visualGroups = Object.values(VisualGroups);
export function isVisualGroups(visualGroup: any): visualGroup is VisualGroups {
  return Object.values(VisualGroups).includes(visualGroup);
}

export type Appearance = Record<AppearanceSlot, { identifier: string; gene: Gene; blender_ref: string }>;
export function isAppearance(appearance: any): appearance is Appearance {
  if (appearance as Appearance) return true;
  else return false;
}
// old !! derived from definitions in confluence
// export enum AppearanceSlot {
//   HAIR = 'hair',
//   FACE_ACCESSOIRE = 'faceAccessoire',
//   EYES = 'eyes',
//   SKIN_COLOR = 'skinColor',
//   FACIAL_HAIR = 'facialHair',
//   FACIAL_EXPRESSION = 'facialExpression',
//   TORSO = 'torso',
//   JEWELRY = 'jewelry',
//   ATTACHABLE = 'attachable',
//   BACKGROUND = 'background',
// }

// definition derived from blender script parameter structure
export enum AppearanceSlot {
  EYES = 'Eyes',
  EYEWEAR = 'Eyewear',
  HAIR = 'Hair',
  FACE_COVERS = 'FaceCovers',
  TORSO = 'Torso',
  BACKGROUND = 'Background',
  BODY = 'Body',
  FACE = 'Face',
}

export const appearanceSlots = Object.values(AppearanceSlot);
export function isAppearanceSlots(appearanceSlot: AppearanceSlot): appearanceSlot is AppearanceSlot {
  return Object.values(AppearanceSlot).includes(appearanceSlot);
}

// VISUALSTABLE
export type AppearanceDef = {
  identifier: string;
  gene: Gene;
  blender_ref: string;
  rarity?: Rarity;
  gender: Gender[];
  visualGroups: VisualGroups[];
  tags: string[];
  weight: number;
};

export function isAppearanceDef(appearanceDef: any): appearanceDef is AppearanceDef {
  return (
    appearanceDef.identifier !== undefined &&
    isGene(appearanceDef.gene) &&
    !!appearanceDef.genders.length &&
    appearanceDef.genders.every((gender: any) => isGender(gender)) &&
    !!appearanceDef.visualGroups.length &&
    appearanceDef.visualGroups.every((group: any) => isVisualGroups(group)) &&
    !!appearanceDef.tags.length &&
    isNaN(appearanceDef.weight)
  );
}

export type AppearanceTable = AppearanceDef[];

export function isAppearanceTable(appearanceTable: any): appearanceTable is AppearanceTable {
  return !!appearanceTable.length && appearanceTable.every((def: any) => isAppearanceDef(def));
}

export const CharacterReplace: Record<string, string> = {
  a: '4',
  b: '8',
  c: '(',
  d: '|)',
  e: '3',
  f: '|:',
  g: '9',
  h: '#',
  i: '!',
  j: '¿',
  k: '|<',
  l: '1',
  m: '|v|',
  n: '|v',
  o: '0',
  p: '|°',
  r: '|2',
  s: '5',
  t: '7',
  u: '|_|',
  v: '|/',
  w: "\\'",
  x: '><',
  y: '°/',
  z: '2',
};
export const characterReplace = Object.keys(CharacterReplace);

export const specialCharacters = ['_', '#', '*', '%', '$', '°'];
