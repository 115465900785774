import { isMinMax, MinMax } from './utils';

export enum SkillName {
  COMMUNICATION = 'Communication',
  ACCURACY = 'Accuracy',
  REFLEXES = 'Reflexes',
  MOVEMENT = 'Movement',
  GAME_SENSE = 'GameSense',
  CLUTCH_ABILITY = 'ClutchAbility',
  UTILITY_USAGE = 'UtilityUsage',
  RESILIENCE = 'Resilience',
  MONEY_MANAGEMENT = 'MoneyManagement',
  STAMINA = 'Stamina',
  SPRAY_CONTROL = 'SprayControl',
  ECONOMY_MANAGEMENT = 'EconomyManagement',
}
export const skillNames = Object.values(SkillName);
export function isSkillName(name: string): name is SkillName {
  return skillNames.includes(name as any);
}

export type SkillRanges = MinMax[];
export function isSkillRanges(sr: any): sr is SkillRanges {
  return sr.every((mm: any) => isMinMax(mm));
}

export type SkillProbabilities = Record<SkillName, number>;
export function isSkillProbabilities(sp: any): sp is SkillProbabilities {
  return skillNames.every((sn: any) => !isNaN(sp[sn]));
}

export interface SkillDistributionTable {
  ranges: SkillRanges;
  probabilities: SkillProbabilities;
}
export function isSkillDistributionTable(sdt: any): sdt is SkillDistributionTable {
  return isSkillRanges(sdt.ranges) && isSkillProbabilities(sdt.probabilities);
}

export type AthleteSkills = Record<SkillName, number>;
export function isAthleteSkills(as: any): as is AthleteSkills {
  return skillNames.every((sn) => !isNaN(as[sn]));
}
