import { StrapiImage } from '../csgo-manager';

export enum ShopOfferType {
  IN_GAME_CURRENCY = 'inGameCurrency',
  REAL_MONEY = 'realMoney',
}

export interface ShopOffer {
  uuid: string;
  name: string;
  image: StrapiImage;
  description: string;
  type: ShopOfferType;
  displayType: ShopOfferDisplayType;
  cost: number;
  content: ShopOfferContent[];
}

type ShopOfferContent = ShopOfferInGameCurrencyContent | ShopOfferRealMoneyContent;

export enum ShopOfferDisplayType {
  STATIC = 'static',
  CYCLING = 'cycling',
  DAILY = 'daily',
}

// IN GAME CURRENCY OFFER

export const SHOP_OFFER_IN_GAME_CURRENCY_STRAPI_MODEL_NAME = 'shop-offer-in-game-currency';

export interface ShopOfferInGameCurrency extends ShopOffer {
  type: ShopOfferType.IN_GAME_CURRENCY;
  displayType: ShopOfferDisplayType;
  currency: IN_GAME_CURRENCY;
  content: ShopOfferInGameCurrencyContent[];
}

export enum IN_GAME_CURRENCY {
  SOFT_CURRENCY = 'softCurrency',
  PREMIUM_CURRENCY = 'premiumCurrency',
}

export enum ShopOfferInGameCurrencyContentType {
  CARD_PACK = 'cardPack',
}

export interface ShopOfferInGameCurrencyContent {
  type: ShopOfferInGameCurrencyContentType;
}

export interface ShopOfferInGameCurrencyCardPack extends ShopOfferInGameCurrencyContent {
  type: ShopOfferInGameCurrencyContentType.CARD_PACK;
  cardPackLootCycleId: string;
}

export function isShopOfferInGameCurrencyCardPack(
  contentEntry: ShopOfferInGameCurrencyContent
): contentEntry is ShopOfferInGameCurrencyCardPack {
  return contentEntry.type === ShopOfferInGameCurrencyContentType.CARD_PACK;
}

// REAL MONEY OFFER

export const SHOP_OFFER_REAL_MONEY_STRAPI_MODEL_NAME = 'shop-offer-real-money';

export interface ShopOfferRealMoney extends ShopOffer {
  type: ShopOfferType.REAL_MONEY;
  displayType: ShopOfferDisplayType.STATIC;
  content: ShopOfferRealMoneyContent[];
  stripeProductId?: string;
  stripePriceId?: string;
}

export enum ShopOfferRealMoneyContentType {
  PREMIUM_CURRENCY = 'premiumCurrency',
}

export interface ShopOfferRealMoneyContent {
  type: ShopOfferRealMoneyContentType;
}

export interface ShopOfferRealMoneyPremiumCurrency extends ShopOfferRealMoneyContent {
  type: ShopOfferRealMoneyContentType.PREMIUM_CURRENCY;
  premiumCurrencyAmount: number;
  discount?: number;
}

export function isShopOfferRealMoneyPremiumCurrency(
  contentEntry: ShopOfferRealMoneyContent
): contentEntry is ShopOfferRealMoneyPremiumCurrency {
  return contentEntry.type === ShopOfferRealMoneyContentType.PREMIUM_CURRENCY;
}
