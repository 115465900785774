import { useAuthStore } from '../../auth/auth.store';
import { AuthState } from '../../auth/auth.utils';
import { useGameAssetsStore } from '../game-assets.store';
import { queryUserRooms } from '@/lib/aws/user/user-facility.api';
import { BuildingRoom, UserRoom } from '@cstactics/types';

/**
 * Subscribe on auth store's auth state
 * • query cards on authorized
 * • empty store on unauthorized
 */
export const _handleAuthStateChangeForRooms = () => {
  useAuthStore.subscribe(({ authState }) => {
    if (authState === AuthState.AUTHORIZED) {
      _onAuthorized();
    }
    if (authState === AuthState.UNAUTHORIZED) {
      _onUnauthorized();
    }
  });
};

/**
 * Handle Auth State Change to AUTHORIZED
 * Query and Sort User Cards
 * @param appUser
 */
const _onAuthorized = async () => {
  try {
    // query rooms
    const allRooms: any = await queryUserRooms();
    const rooms: UserRoom[] = Object.values(allRooms.rooms);
    const buildingRoom: BuildingRoom = allRooms.building;
    console.debug('[rooms.utils] onAuthorized found rooms::', rooms.length);

    // set cards in store
    useGameAssetsStore.setState({ rooms });
    useGameAssetsStore.setState({ buildingRoom });
  } catch (error) {
    console.error(`[rooms.utils] _onAuthorized::error`, error);
  }
};

/**
 * Handle Auth State Change to UNAUTHORIZED
 * Empty User Cards
 */
const _onUnauthorized = async () => {
  useGameAssetsStore.setState({ rooms: undefined, buildingRoom: undefined });
};
