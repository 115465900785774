import { CsMap, MinMax } from '../../../../../athlete';
import { TEAMA_OR_TEAMB } from '../filter';

export interface BombPlacementFilter extends BombPlacementFilterDef {
  /** Which map is this match taking place in? */
  map?: CsMap;
  /** In which locations has the bomb been placed? */
  location?: string;
  /** Is the Athlete placing the bomb the first BombCarrier of this Round? */
  originalCarrier?: boolean;
  /** TEAM-A or Team_B __PARENT__*/
  team?: TEAMA_OR_TEAMB;
  /**  When was the bomb placed? 
ex: [5, 20] → if the bomb was planted between 5 and 20 than the filter is true*/
  timeWindow?: MinMax;
}

export function isBombPlacementFilter(obj: any): obj is BombPlacementFilter {
  return Object.keys(obj).every((key) => isBombPlacementFilterWildCard(key) || isBombPlacementFilterParent(key));
}

export enum BombPlacementFilterParent {
  TEAM = 'team',
}
export const bombPlacementFilterParentValues = Object.values(BombPlacementFilterParent);
export function isBombPlacementFilterParent(obj: any): obj is BombPlacementFilterParent {
  return bombPlacementFilterParentValues.includes(obj);
}

// export enum BombPlacementFilterChild {}
// export const bombPlacementFilterChildValues = Object.values(BombPlacementFilterChild);
// export function isBombPlacementFilterChild(obj: any): obj is BombPlacementFilterChild {
//   return bombPlacementFilterChildValues.includes(obj);
// }

export enum BombPlacementFilterWildCard {
  MAP = 'map',
  LOCATION = 'location',
  ORIGINAL_CARRIER = 'originalCarrier',
  TIME_WINDOW = 'timeWindow',
}
export const bombPlacementFilterWildCardValues = Object.values(BombPlacementFilterWildCard);
export function isBombPlacementFilterWildCard(obj: any): obj is BombPlacementFilterWildCard {
  return bombPlacementFilterWildCardValues.includes(obj);
}

export type BombPlacementFilterDef = Partial<Record<BombPlacementFilterWildCard | BombPlacementFilterParent, any>>;
