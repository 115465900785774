import { Components } from '@mui/material/styles';

const MuiSvgButtonOverrides: Components = {
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        stroke: 'currentColor',
      },
    },
  },
};

export default MuiSvgButtonOverrides;
