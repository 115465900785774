import {
  CsMap,
  CsRole,
  SkillName,
  StatSelector,
  SystemStatModifierName,
  isCsMap,
  isCsRole,
  isSkillName,
} from '../../csgo-manager/athlete';

export type AthleteEffect = AthleteStatModifierEffect | TalentModifierEffect;

export function isAthleteEffect(effect: any): effect is AthleteEffect {
  return isStatModifierEffect(effect) || isTalentModifierEffect(effect);
}

export enum AthleteEffectType {
  STAT_MODIFIER_EFFECT = 'StatModifierEffect',
  TALENT_MODIFIER_EFFECT = 'TalentModifierEffect',
}

export const athleteEffectName = Object.values(AthleteEffectType);
export function isAthleteEffectName(ten: any): ten is AthleteEffectType {
  return Object.values(AthleteEffectType).includes(ten);
}

// STAT MODIFIER EFFECT
export type AthleteStatName = SkillName | CsMap | CsRole;

export interface AthleteStatModifierEffect {
  uuid: string;
  type: AthleteEffectType.STAT_MODIFIER_EFFECT;
  statName: AthleteStatName | SystemStatModifierName | StatSelector.ALL;
  description: string;
  value: number;
  isRelative?: boolean;
}

export function isStatModifierEffect(effect: AthleteEffect): effect is AthleteStatModifierEffect {
  return (
    effect.type === AthleteEffectType.STAT_MODIFIER_EFFECT &&
    (isCsMap(effect.statName) ||
      isCsRole(effect.statName) ||
      isSkillName(effect.statName) ||
      effect.statName === StatSelector.ALL) &&
    !isNaN(effect.value)
  );
}

// TALENT MODIFIER EFFECT
export interface TalentModifierEffect {
  uuid: string;
  type: AthleteEffectType.TALENT_MODIFIER_EFFECT;
  talentName: AthleteStatName | StatSelector.ALL;
  description: string;
  value: number;
  isRelative?: boolean;
}

export function isTalentModifierEffect(effect: AthleteEffect): effect is TalentModifierEffect {
  return (
    effect.type === AthleteEffectType.TALENT_MODIFIER_EFFECT &&
    (isCsMap(effect.talentName) ||
      isCsRole(effect.talentName) ||
      isSkillName(effect.talentName) ||
      effect.talentName === StatSelector.ALL) &&
    !isNaN(effect.value)
  );
}
