import { Components } from '@mui/material';

const MuiAvatarOverrides: Components = {
  MuiAvatar: {
    styleOverrides: {
      root: {
        height: 52,
        width: 52,
      },
    },
  },
};

export default MuiAvatarOverrides;
