import { Season } from './seasons';

export enum LadderState {
  PRE_SEASON = 'preSeason',
  SEASON = 'season',
}

export interface Ladder {
  /** name of the ladder */
  name: string;

  /** uuid */
  uuid: string;

  /** is ladder in pre-season or season */
  state: LadderState;

  /** current season while first pre-season no current season exists */
  currentSeason?: Season;

  /** upcoming season id */
  nextSeasonId?: string;
}

export interface LadderBaseInfo {
  /** name of the ladder */
  name: string;

  /** uuid */
  uuid: string;
}
