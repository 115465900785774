import { create } from 'zustand';
import { AppUser, AuthState, UserProfile } from './auth.utils';
import { withDevtools } from '@/lib/store/utils';

interface AuthStore {
  /** is initialized */
  isInitialized: boolean;

  /** is configured for auth state */
  configuredState?: AuthState.AUTHORIZED | AuthState.UNAUTHORIZED;

  /** current auth state */
  authState: AuthState;

  /** current app user undefined if unauthorized */
  appUser?: AppUser;
  /** current app user undefined if unauthorized */
  userProfile?: UserProfile;

  /** update avatar key */
  updateAvatarKey: (key: string) => void;

  /**
   * Initialize auth store
   * • init listen to aws auth hub
   * • handle auth state changes
   */
  init: () => void;
}

export const useAuthStore = create<AuthStore>()(
  withDevtools(
    (set, get) => ({
      isInitialized: false,
      authState: AuthState.UNKNOWN,
      updateAvatarKey: (key: string) => {
        set(({ userProfile }) => ({ userProfile: { ...(userProfile || {}), avatarImageKey: key } }));
      },
      init: () => {
        if (get().isInitialized) {
          console.warn('[auth-store] init called while already initialized');
          return;
        }
        set({ isInitialized: true });
      },
    }),
    { name: 'AuthStore' }
  )
);
