import { StaffType, isStaffType } from '../../facility/staff/staff';
import { AthleteEffect, AthleteStatModifierEffect } from '../effects/athlete-effects';
import { FacilityEffect } from '../effects/facility-effects';
import { MatchAthleteEffect } from '../effects/match-athlete-effects';
import { StaffEffect } from '../effects/staff-effects';

export type TeamStatus = FacilityStatus | StaffStatus | AthleteStatus | MatchAthleteStatus;

export enum TeamStatusType {
  MATH_ATHLETE_STATUS = 'matchAthleteStatus',
  ATHLETE_STATUS = 'athleteStatus',
  STAFF_STATUS = 'staffStatus',
  FACILITY_STATUS = 'facilityStatus',
}

export const teamBuffTypeValues = Object.values(TeamStatusType);
export function isTeamStatusType(type: any): type is TeamStatusType {
  return teamBuffTypeValues.includes(type);
}

export interface TeamStatusBase {
  uuid: string;
  type: TeamStatusType;
}

// STAFF STATUS
export interface StaffStatus extends TeamStatusBase {
  type: TeamStatusType.STAFF_STATUS;
  affects: StaffType[];
  effects: StaffEffect[];
}
export function isStaffStatus(obj: any): obj is StaffStatus {
  return obj.type === TeamStatusType.STAFF_STATUS && obj.affects.every((affected: any) => isStaffType(affected));
}

// FACILITY STATUS

export interface FacilityStatus extends TeamStatusBase {
  type: TeamStatusType.FACILITY_STATUS;
  effects: FacilityEffect[];
}
export function isFacilityStatus(obj: any): obj is FacilityStatus {
  return obj.type === TeamStatusType.FACILITY_STATUS;
}

// ATHLETE STATUS

export interface AthleteStatus extends TeamStatusBase {
  type: TeamStatusType.ATHLETE_STATUS;
  effects: AthleteEffect[];
}
export function isAthleteStatus(obj: any): obj is TeamStatus {
  return obj.type === TeamStatusType.ATHLETE_STATUS;
}

// MATCH ATHLETE STATUS

export interface MatchAthleteStatus extends TeamStatusBase {
  type: TeamStatusType.MATH_ATHLETE_STATUS;
  effects: MatchAthleteEffect[];
}

export function isMatchAthleteStatus(obj: any): obj is MatchAthleteStatus {
  return obj.type === TeamStatusType.MATH_ATHLETE_STATUS;
}
