import { FacilityStatus, StaffStatus } from '../../csgo-manager';
import { StaffSlotType, StaffType } from '../staff/staff';

export interface RoomBase {
  uuid: RoomId;
  name: string;
  description: string;
  priority: number;
}

export enum RoomId {
  SUPPORT_OFFICES = 'supportOffices',
  COACH_OFFICE = 'coachOffice',
  VIEWING_CINEMA = 'viewingCinema',
  PR_CENTER = 'prCenter',
  GAMING_ROOM = 'gamingRoom',
  GYM = 'gym',
  // IT_MAINTENANCE = 'itMaintenance',
  MERCH_WAREHOUSE = 'merchWarehouse',
}

export interface RoomDefinition extends RoomBase {
  levels: RoomLevelDefinition[];
}

export interface RoomDB {
  uuid: RoomId;
  activeLevelNumber: number;
}

export interface UserRoom extends RoomBase {
  status: UserRoomStatus;
  activeLevel?: ActiveRoomLevel;
  nextLevel?: RoomLevelDefinition;
}

export enum UserRoomState {
  LOCKED = 'locked',
  UPGRADE_LOCKED = 'upgradeLocked',
  UNLOCKED = 'unlocked',
  UPGRADE_AVAILABLE = 'upgradeAvailable',
  BUILDING = 'building',
  MAX_LEVEL_REACHED = 'maxLevelReached',
}

export interface UserRoomStatus {
  state: UserRoomState;
  buildRestrictionCheck: RoomRestrictionsCheckResult;
  functionRestrictionCheck: RoomRestrictionsCheckResult;
}

export interface BuildingRoom extends RoomBase {
  level: RoomLevelDefinition;
}

export interface ActiveRoomLevel {
  level: number;
  upkeep: number;
  functionRestrictions: RoomFunctionRestriction[];
  statusEffects: Array<FacilityStatus | StaffStatus>;
  staffSlots: RoomStaffSlotCategory[];
}

export interface RoomLevelDefinition {
  level: number;
  cost: number;
  upkeep: number;
  duration: number;
  buildRestrictions: RoomBuildRestriction[];
  functionRestrictions: RoomFunctionRestriction[];
  statusEffects: Array<FacilityStatus | StaffStatus>;
  staffSlots: RoomStaffSlotCategory[];
  sponsorshipSlotsAmount: number;
}

export interface RoomRestriction {
  type: RoomRestrictionType;
}

export enum RoomRestrictionType {
  FAME = 'FameRestriction',
  STAFF = 'StaffRestriction',
}

export interface RoomFameRestriction extends RoomRestriction {
  type: RoomRestrictionType.FAME;
  amount: number;
}
export function isRoomFameRestriction(obj: any): obj is RoomFameRestriction {
  return obj.type === RoomRestrictionType.FAME;
}

export interface RoomStaffRestriction extends RoomRestriction {
  type: RoomRestrictionType.STAFF;
  staffType: StaffType;
}
export function isRoomStaffRestriction(obj: any): obj is RoomStaffRestriction {
  return obj.type === RoomRestrictionType.STAFF;
}

export type RoomBuildRestriction = RoomFameRestriction | RoomStaffRestriction;
export type RoomFunctionRestriction = RoomStaffRestriction;

export interface RoomStaffSlotCategory {
  type: StaffSlotType;
  amount: number;
}

export interface RoomRestrictionsCheckPassedResult {
  passed: true;
}

export interface RoomRestrictionsCheckFailedResult {
  passed: false;
  failedRestrictions: FailedRoomRestriction[];
}

export type RoomRestrictionsCheckResult = RoomRestrictionsCheckPassedResult | RoomRestrictionsCheckFailedResult;

export type FailedRoomRestriction = FailedRoomFameRestriction | FailedRoomStaffRestriction;

export interface FailedRoomRestrictionBase {
  type: RoomRestrictionType;
  label: string;
}

export interface FailedRoomFameRestriction extends FailedRoomRestrictionBase {
  type: RoomRestrictionType.FAME;
  amount: number;
  current: number;
}
export function isFailedRoomFameRestriction(obj: any): obj is FailedRoomFameRestriction {
  return obj.type === RoomRestrictionType.FAME;
}

export interface FailedRoomStaffRestriction extends FailedRoomRestrictionBase {
  type: RoomRestrictionType.STAFF;
  staffType: StaffType;
}
export function isFailedRoomStaffRestriction(obj: any): obj is FailedRoomStaffRestriction {
  return obj.type === RoomRestrictionType.STAFF;
}
