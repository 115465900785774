import { Components } from '@mui/material';
import { palette } from '../palette';

const MuiAccordionOverrides: Components = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        'backgroundColor': palette.bgPrimary.primary,
        'color': palette.contentPrimary.secondary,
        'width': '100%',
        'boxShadow': 'none',

        // border styling
        '&:before': {
          display: 'none',
        },
        'borderBottom': `1px solid ${palette.contentPrimary.tertiary}`,
        'borderTop': `1px solid ${palette.contentPrimary.tertiary}`,
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:first-of-type': {
          borderTop: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: '38px 0px 38px 0px',
      },
      content: {
        margin: 0,
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '0px 0px 24px 0px',
      },
    },
  },
};

export default MuiAccordionOverrides;
