import { clearUserNotifications, queryUserNotifications } from '@/lib/aws/user/user.api';
import { UserNotification } from '@cstactics/types';
import { useNotificationsStore } from './notifications.store';

export async function clearNotifications(): Promise<void> {
  await clearUserNotifications();
  const notifications = await queryUserNotifications();
  await setNotifications(notifications);
}

export async function setNotifications(notifications: {
  notifications: UserNotification[];
  ok: boolean;
}): Promise<void> {
  const { notifications: allNotifications } = notifications;

  const unreadNotifications = allNotifications.filter((notification) => !notification.isRead);
  const sortedNotifications = allNotifications.slice().sort((a, b) => (a.isRead === b.isRead ? 0 : a.isRead ? 1 : -1));
  const hasNotifications = allNotifications.length > 0;

  useNotificationsStore.setState({
    unreadCount: unreadNotifications.length,
    notifications: sortedNotifications,
    hasNotifications,
  });
}
