import { Card, UserCardPack } from '@cstactics/types';
import { StateCreator } from 'zustand';
import { GameAssetsState } from '../game-assets.store';
import { openCardPack } from './cardPack.actions';
import { _handleAuthStateChangeForCardPacks } from './cardPacks.utils';

export interface CardPacksSlice {
  // states

  /** user card packs */
  cardPacks?: UserCardPack[];

  // actions

  /** get card pack by id */
  getCardPack: (id?: string | string[]) => UserCardPack | undefined;

  /** open cards pack */
  openCardPack: (id: string) => Promise<Card[]>;

  // init

  /**
   * !! Don't use this in code: It's called by InitCardsSlice.init !!
   * Initialize card packs
   * • listen to auth state
   * • query user card packs if user is authorized
   * • empty store on logout
   */
  _initCardPacks: () => void;
}

export const createCardPacksSlice: StateCreator<GameAssetsState, [], [], CardPacksSlice> = (set, get) => ({
  getCardPack: (id?: string | string[]) => {
    const cardPacks = get().cardPacks;
    if (!cardPacks || typeof id !== 'string') return;
    return cardPacks.find((pack) => pack.cardPackId === id);
  },
  openCardPack,
  _initCardPacks: () => {
    _handleAuthStateChangeForCardPacks();
  },
});
