import { UpcomingMatchType } from '../../matches';
import { AthleteCard } from '../card';

export enum SystemStatModifierName {
  RENOWN = 'renown',
  CHEMISTRY = 'chemistry',
  SALARY = 'salary',
  MOTIVATION_GAIN = 'motivationGain',
  MOTIVATION_LOSS = 'motivationLoss',
  ENDURANCE_RECOVERY = 'enduranceRecovery',
}
export const systemStatModifierNames = Object.values(SystemStatModifierName);
export function isSystemStatModifierName(sm: string): sm is SystemStatModifierName {
  return systemStatModifierNames.includes(sm as any);
}

export type AthleteSystemStatModifiers = Record<SystemStatModifierName, number>;
export function isAthleteSystemStatModifiers(statModifier: any): statModifier is AthleteSystemStatModifiers {
  return Object.entries(statModifier).every(
    ([key, value]) => isSystemStatModifierName(key) && typeof value === 'number'
  );
}

export type AthleteChemistryStats = Record<AthleteCard['uuid'], { lastPlayedWith: number; value: number }>;
export function isAthleteChemistryStats(chemStats: any): chemStats is AthleteChemistryStats {
  return Object.entries(chemStats).every(
    ([key, object]) =>
      typeof key === 'string' &&
      Object.entries(object as any).every(
        ([key, value]) => (key === 'lastPlayedWith' || key === 'value') && typeof value === 'number'
      )
  );
}

export interface AthleteMatchHistoryEntry {
  matchId: string;
  matchDate: number;
  matchType: UpcomingMatchType;
  kills: number;
  death: number;
  assists: number;
  mvp: boolean;
  averageRoundDamage: number;
}
