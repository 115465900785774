import { StateCreator } from 'zustand';
import { NotificationsState } from './notifications.store';
import { clearNotifications } from './notifications.actions';
import { UserNotification } from '@cstactics/types';

export interface NotificationsSlice {
  // states
  notifications: UserNotification[];
  unreadCount: number;
  hasNotifications: boolean;
  // actions
  clearNotifications: () => Promise<void>;
}

export const createNotificationsSlice: StateCreator<NotificationsState, [], [], NotificationsSlice> = () => ({
  /** states */
  notifications: [],
  unreadCount: 0,
  hasNotifications: false,
  clearNotifications,
});
