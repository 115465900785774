import { useGameAssetsStore } from '@/lib/store/gameAssets/game-assets.store';
import { BuildingRoom, UserRoom } from '@cstactics/types';
import {
  queryUserRooms,
  queryUserRoomsDowngrade,
  queryUserRoomsFastUpgrade,
  queryUserRoomsUpgrade,
} from '@/lib/aws/user/user-facility.api';

export async function upgradeRoom(roomId: string, level: number): Promise<void> {
  await queryUserRoomsUpgrade(roomId, level);

  const allRooms: any = await queryUserRooms();
  const buildingRoom: BuildingRoom = allRooms.building;

  useGameAssetsStore.setState({ buildingRoom });
  useGameAssetsStore.setState(({ rooms }) => {
    if (!rooms) return {};
    const roomIndex = rooms.findIndex((ac: any) => ac.uuid === roomId);
    rooms[roomIndex].status.state = 'building';
    return { rooms: [...rooms] };
  });
}

export async function fastRoomUpgrade(): Promise<void> {
  await queryUserRoomsFastUpgrade();
  const buildingRoom: any = null;

  const allRooms: any = await queryUserRooms();
  const rooms: UserRoom[] = Object.values(allRooms.rooms);

  useGameAssetsStore.setState({ rooms });
  useGameAssetsStore.setState({ buildingRoom });
}

export async function downgradeRoom(roomId: string): Promise<void> {
  await queryUserRoomsDowngrade(roomId);

  const allRooms: any = await queryUserRooms();
  const rooms: UserRoom[] = Object.values(allRooms.rooms);
  const buildingRoom: BuildingRoom = allRooms.building;

  useGameAssetsStore.setState({ rooms });
  useGameAssetsStore.setState({ buildingRoom });
}
