import { getMatchResults, getUpcomingMatches } from '../../../aws/user/user.api';
import { useAuthStore } from '../../auth/auth.store';
import { AuthState } from '../../auth/auth.utils';
import { useGameAssetsStore } from '../game-assets.store';

/**
 * Subscribe on auth store's auth state
 * • query user matches on authorized
 * • empty store on unauthorized
 */
export const _handleAuthStateChangeForMatches = () => {
  useAuthStore.subscribe(({ authState }) => {
    if (authState === AuthState.AUTHORIZED) {
      _onAuthorized();
    }
    if (authState === AuthState.UNAUTHORIZED) {
      _onUnauthorized();
    }
  });
};

/**
 * Handle Auth State Change to AUTHORIZED
 * Query User Upcoming Matches
 * @param appUser
 */
const _onAuthorized = async () => {
  try {
    // query upcoming matches and match results
    let [upcomingMatches, matchResults] = await Promise.all([getUpcomingMatches(), getMatchResults()]);

    if (!upcomingMatches) {
      upcomingMatches = [];
      console.error('[matches.utils] _onAuthorized error :: upcomingMatches');
    }

    if (!matchResults) {
      matchResults = [];
      console.error('[matches.utils] _onAuthorized error :: matchResults');
    }

    console.debug('[matches.utils] _onAuthorized found matches::', upcomingMatches);

    // set upcoming matches in store
    useGameAssetsStore.setState({ upcomingMatches, matchResults });
  } catch (error) {
    console.error('[matches.utils] _onAuthorized error::', error);
  }
};

/**
 * Handle Auth State Change to UNAUTHORIZED
 * Empty User Upcoming Matches
 */
const _onUnauthorized = async () => {
  useGameAssetsStore.setState({ upcomingMatches: [], matchResults: [], activeMatchPreparation: undefined });
};
