import { ShopOfferDisplayType } from './shop-offers';

// MOVE CARD PACK EVENT
export interface MoveCardPackEvent {
  userId: string;
  cardPackLootCycleId: string;
  transactionId: string;
  amount: number;
}

// SHOP OFFER EVENT

export interface ShopOfferEvent {
  userId: string;
  uuid: string;
  date: number;
  shopOfferId: string;
  displayType: ShopOfferDisplayType;
  type: ShopOfferEventType;
}

export enum ShopOfferEventType {
  PURCHASE_IN_GAME_CURRENCY_SHOP_OFFER = 'purchaseInGameCurrencyShopOffer',
  PURCHASE_REAL_MONEY_SHOP_OFFER = 'purchaseRealMoneyShopOffer',
}

// PURCHASE IN GAME CURRENCY SHOP OFFER EVENT

export interface PurchaseInGameCurrencyShopOfferEvent {
  type: ShopOfferEventType.PURCHASE_IN_GAME_CURRENCY_SHOP_OFFER;
  cardPackIds?: string[];
}

// PURCHASE REAL MONEY SHOP OFFER EVENT

export interface PurchaseRealMoneyShopOfferEvent {
  type: ShopOfferEventType.PURCHASE_REAL_MONEY_SHOP_OFFER;
  stripeProductId?: string;
  stripePriceId?: string;
}
