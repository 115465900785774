import { _handleAuthStateChangeForSponsors } from '@/lib/store/gameAssets/sponsors/sponsors.utils';
import { completeContract, signContract } from '@/lib/store/gameAssets/sponsors/sponsors.action';
import { StateCreator } from 'zustand';
import { GameAssetsState } from '@/lib/store/gameAssets/game-assets.store';

export interface SponsorsSlice {
  // states
  sponsors?: any;
  signedContracts?: any;

  //actions
  signContract: (contractId: string, teamId: string) => Promise<void>;
  completeContract: (contractId: string) => Promise<void>;
  // init
  /**
   * !! Don't use this in code: It's called by InitCardsSlice.init !!
   * Initialize cards store
   * • listen to auth state
   * • query user cards if user is authorized
   * • empty store in logout
   */
  _initSponsors: () => void;
}
export const createSponsorsSlice: StateCreator<GameAssetsState, [], [], SponsorsSlice> = () => ({
  signContract,
  completeContract,
  _initSponsors: () => {
    _handleAuthStateChangeForSponsors();
  },
});
