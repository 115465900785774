import Router from 'next/router';

import { useAuthStore } from '../../auth/auth.store';
import { AuthState } from '../../auth/auth.utils';
import { useGameAssetsStore } from '../game-assets.store';
import { queryUserTeam } from '@/lib/aws/user/user.api';
import { AppRoute } from '../../../../_routes';

/**
 * Subscribe on auth store's auth state
 * • query user team on authorized
 * • empty store on unauthorized
 */
export const _handleAuthStateChangeForTeams = () => {
  useAuthStore.subscribe(({ authState }) => {
    if (authState === AuthState.AUTHORIZED) {
      _onAuthorized();
    }
    if (authState === AuthState.UNAUTHORIZED) {
      _onUnauthorized();
    }
  });
};

/**
 * Handle Auth State Change to AUTHORIZED
 * Query User Team
 * @param appUser
 */
const _onAuthorized = async () => {
  try {
    // query team
    let team = await queryUserTeam().catch((e) => {
      console.warn('[teams.utils] _onAuthorized error::', e);
    });

    if (!team) {
      await Router.push(AppRoute.CREATE_TEAM);
      return;
    }

    console.debug('[teams.utils] _onAuthorized found team::', team.name);
    // set cards in store
    useGameAssetsStore.setState({ team });
  } catch (error) {
    console.error('[teams.utils] _onAuthorized error::', error);
  }
};

/**
 * Handle Auth State Change to UNAUTHORIZED
 * Empty User Card Packs
 */
const _onUnauthorized = async () => {
  useGameAssetsStore.setState({ team: undefined });
};
