import { AthleteCard, AthleteCardShort, StaffCard, StaffCardShort, Team, TeamFame } from '../csgo-manager';
import { RoomId, UserRoom } from '../facility';

export type HistoryData = {
  date: string;
  balance: number;
};

export type Loan = {
  id: number;
  name: string;
  amount: number;
  repaymentAmount: number;
  remainingAmount: number;
  loanDate: string;
};

export enum EconomyPassiveIncomeType {
  VIEWS = 'views',
  MERCHANDISE = 'merchandise',
  MEMBERSHIPS = 'memberships',
  MONEY_MANAGEMENT = 'moneyManagement',
}

export enum EconomyOneTimeIncomeType {
  CARD_PACKS = 'cardPacks',
  MATCH_REWARD = 'matchReward',
  LEAGUE_REWARD = 'leagueReward',
  MARKET_ITEMS_SOLD = 'marketItemsSold',
  FACILITY_DOWNGRADE = 'facilityDowngrade',
  SPONSORSHIP = 'sponsorship',
}

export type EconomyIncomeType = EconomyPassiveIncomeType | EconomyOneTimeIncomeType;
export const EconomyIncomeTypes = { ...EconomyPassiveIncomeType, ...EconomyOneTimeIncomeType };

export enum EconomyPassiveExpenseType {
  ATHLETE_SALARY = 'athleteSalary',
  STAFF_SALARY = 'staffSalary',
  ROOM_UPKEEP = 'roomUpkeep',
}

export enum EconomyOneTimeExpenseType {
  FACILITY_EXPANSION = 'facilityExpansion',
  TRAINING_CAMPUS = 'trainingCampus',
  SHOP_PURCHASE = 'shopPurchase',
  MARKET_PURCHASE = 'marketPurchase',
}

export type EconomyExpenseType = EconomyPassiveExpenseType | EconomyOneTimeExpenseType;
export const EconomyExpenseTypes = { ...EconomyPassiveExpenseType, ...EconomyOneTimeExpenseType };

export interface EconomyPassiveIncome extends Partial<Record<EconomyPassiveIncomeType, EconomyResponse>> {
  total: number;
  views?: EconomyOnlineViewsResponse;
  memberships?: EconomyMembershipResponse;
}
export interface EconomyOneTimeIncome extends Partial<Record<EconomyOneTimeIncomeType, EconomyResponse>> {
  total: number;
}

export interface EconomyIncomeBreakDown {
  total: number;
  passive: EconomyPassiveIncome;
  oneTime: EconomyOneTimeIncome;
}

export interface EconomyPassiveExpense extends Partial<Record<EconomyPassiveExpenseType, EconomyResponse>> {
  total: number;
  athleteSalary?: EconomyAthletesSalaryResponse;
  staffSalary?: EconomyStaffSalaryResponse;
  roomUpkeep?: EconomyRoomUpkeepResponse;
}

export interface EconomyOneTimeExpense extends Partial<Record<EconomyOneTimeExpenseType, EconomyResponse>> {
  total: number;
}

export interface EconomyExpenseBreakDown {
  total: number;
  passive: EconomyPassiveExpense;
  oneTime: EconomyOneTimeExpense;
}

export type EconomyBreakDown = {
  date: string;
  incomes: EconomyIncomeBreakDown;
  expenses: EconomyExpenseBreakDown;
};

export interface CurrentBalance {
  balance: number;
  historyData: HistoryData[];
}

export interface MonthlyEconomy {
  month: number;
  year: number;
  income: number;
  expenses: number;
  balance: number;
}

export type Economy = {
  /** current balance */
  currentBalance: CurrentBalance;
  /** breakdown for current day is updated by economy events */
  prediction: EconomyBreakDown;
  /** see: https://skillshot-fdi-mb.atlassian.net/wiki/spaces/CSM/pages/1200259090/Loans+and+Bankruptcy */
  activeLoans: Loan[];
  /** list of last breakdowns */
  economyBreakDowns: EconomyBreakDown[];
  /** list of last months economy summary */
  monthlyEconomy: MonthlyEconomy[];
};

export interface PassiveIncomeParams {
  activeAthletes: AthleteCard[];
  fame: TeamFame;
  lastLeague: number;
  ranking: number;
  seasonProgress: number;
}
export interface PassiveIncomeBalancingParams {
  membership?: EconomyMemberShipBalancingValues;
  onlineViews?: EconomyOnlineViewsBalancingValues;
  merchandise?: EconomyMerchandiseBalancingValues;
  moneyManagement?: EconomyMoneyManagementBalancingValues;
}

export interface PassiveExpenseParams {
  activeAthletes: AthleteCard[];
  activeStaff: StaffCard[];
  rooms: Record<RoomId, UserRoom>;
}

export interface PassiveExpenseBalancingParams {
  athleteSalary?: EconomyAthleteSalaryBalancingValues;
}

export interface EconomyAthleteSalaryBalancingValues {
  salaryRenownFactor: number;
  salarySkillExponentialFactor: number;
  salarySkillLinearFactor: number;
  salaryOffset: number;
}

// MEMBERSHIP
export type EconomyMemberShipIncomeParams = Pick<PassiveIncomeParams, 'fame' | 'lastLeague'>;
export interface EconomyMemberShipBalancingValues {
  fameInfluence?: number;
  leagueInfluence?: number;
  paymentPerMemberInfluence?: number;
}

// ONLINE-VIEWS
export type EconomyOnlineViewsParams = Pick<PassiveIncomeParams, 'fame' | 'lastLeague' | 'seasonProgress' | 'ranking'>;

export interface EconomyResponse {
  value: number;
}
export interface EconomyOnlineViewsResponse extends EconomyResponse {
  viewability: number;
  onlineViews: number;
}
export interface EconomyMembershipResponse extends EconomyResponse {
  members: number;
}

export interface EconomyAthletesSalaryResponse extends EconomyResponse {
  athletes: {
    athlete: AthleteCardShort;
    value: number;
  }[];
}

export interface EconomyStaffSalaryResponse extends EconomyResponse {
  staffs: Array<{
    staff: StaffCardShort;
    value: number;
  }>;
}

export interface EconomyRoomUpkeepResponse extends EconomyResponse {
  rooms: Array<{
    roomName: string;
    roomId: RoomId;
    value: number;
  }>;
}

export interface EconomyMoneyManagementBalancingValues {
  moneyManagement?: number;
}

export interface EconomyOnlineViewsBalancingValues {
  fameInfluence?: number;
  seasonInfluence?: number;
  rankingInfluence?: number;
  viewabilityRate?: number;
  onlineViewPayment?: number;
}

// MERCHANDISE
export type EconomyMerchandiseParams = Pick<PassiveIncomeParams, 'fame' | 'lastLeague' | 'activeAthletes'>;
export interface EconomyMerchandiseBalancingValues {
  marketabilityRateAthlete?: number;
  marketabilityRateTeam?: number;
  athleteExponentialFactor?: number;
  athleteLinearFactor?: number;
  athleteOffset?: number;
  teamOffset?: number;
  teamFactor?: number;
}
