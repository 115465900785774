import {
  getMatchPreparation,
  getMatchResult,
  playPlacementMatches,
  updateMatchPreparation,
} from '@/lib/aws/user/user.api';
import {
  MatchPreparationExtended,
  RankInProgressState,
  RankInResult,
  UpdateMatchPreparationInput,
} from '@cstactics/types';
import { useGameAssetsStore } from '../game-assets.store';

export async function getUserMatchPreparation(matchId: string): Promise<MatchPreparationExtended> {
  const match = await getMatchPreparation(matchId);
  useGameAssetsStore.setState({ activeMatchPreparation: match });
  return match;
}

export async function getUserMatchResult(matchId: string): Promise<any> {
  const match = await getMatchResult(matchId);
  return match;
}

export async function updateUserMatchPreparation(matchId: string, update: UpdateMatchPreparationInput): Promise<void> {
  /*@ts-ignore*/
  await updateMatchPreparation(matchId, update);
  const matchPreparation = await getMatchPreparation(matchId);
  useGameAssetsStore.setState({ activeMatchPreparation: matchPreparation });
}

/**
 * trigger rank in match and return matchId
 * @param teamId
 */
export async function playPlacement(teamId: string): Promise<{ matchId?: string; rankInResult?: RankInResult }> {
  const result = await playPlacementMatches(teamId);

  if (!result?.state) {
    return {};
  }

  if (result.state === RankInProgressState.RANKED_IN) {
    // update team with league info
    useGameAssetsStore.setState({ team: result.team });

    console.log('RankIn Result', result.rankInResult);
    return { matchId: result.matchId, rankInResult: result.rankInResult };
  }

  return { matchId: result.matchId };
}

/**
 * Update current matchId
 * @param matchId
 */
export async function updateMatchId(matchId: string): Promise<void> {
  useGameAssetsStore.setState({ activeMatchId: matchId });
}
