import { CsMap } from '../../../../../athlete';

export interface MapBanFilter extends MapBanFilterDef {
  /** The name of the map that was selected */
  selectedMap?: CsMap;
}

export function isMapBanFilter(obj: any): obj is MapBanFilter {
  return Object.keys(obj).every((key) => isMapBanFilterWildCard(obj));
}

export enum MapBanFilterWildCard {
  SELECTED_MAP = 'selectedMap',
}
export const mapBanFilterWildCardValues = Object.values(MapBanFilterWildCard);
export function isMapBanFilterWildCard(obj: any): obj is MapBanFilterWildCard {
  return mapBanFilterWildCardValues.includes(obj);
}

export type MapBanFilterDef = Partial<Record<MapBanFilterWildCard, any>>;
