import { useGameAssetsStore } from '@/lib/store/gameAssets/game-assets.store';
import {
  queryUserSponsorshipContracts,
  queryUserSponsorshipContractsFastEnd,
  queryUserSponsorshipContractsSign,
  queryUserSponsorshipContractsSlots,
} from '@/lib/aws/user/user-facility.api';

export async function signContract(contractId: string): Promise<void> {
  const teamId = useGameAssetsStore.getState().team?.uuid;
  if (!teamId) throw new Error('No Team set to sign contract');

  await queryUserSponsorshipContractsSign(contractId, teamId);

  const signedContracts: any = await queryUserSponsorshipContractsSlots();
  const sponsors: any = await queryUserSponsorshipContracts();
  useGameAssetsStore.setState({ signedContracts });
  useGameAssetsStore.setState({ sponsors });
}

export async function completeContract(contractId: string): Promise<void> {
  await queryUserSponsorshipContractsFastEnd(contractId);

  const signedContracts: any = await queryUserSponsorshipContractsSlots();
  const sponsors: any = await queryUserSponsorshipContracts();
  useGameAssetsStore.setState({ signedContracts });
  useGameAssetsStore.setState({ sponsors });
}
