import { Rarity } from '../rack';
import { AthleteMaps, AthleteRoles, CsMap, CsRole, CsRoleSuitabilities, isProficiencies } from './proficiencies';
import { AthleteSkills, isAthleteSkills } from './skills';
import {
  Appearance,
  countryNames,
  CountryNames,
  Gender,
  isAppearance,
  isGender,
  isNamingGroups,
  isVisualGroups,
  NamingGroups,
  VisualGroups,
} from './superficials';
import { isTalents, Talents } from './talents';
import { isTrait, Trait } from './traits';
import { isMinMax, MinMax, PowerDistribution } from './utils';
import { AthleteMatchHistoryEntry, AthleteChemistryStats, AthleteSystemStatModifiers } from './system-stats';
import { AthleteMatchCountStatsChanges } from '../match';
import { AppliedBuff } from '../../buff-system/buffs/buffs';
// import { AthleteUpgrade } from '../buffs/upgrade/upgrade'; // not in scope for launch

export interface RarityDefinition {
  powerValue: MinMax;
  amountTraits: MinMax;
}

export function isRarityDefinition(rd: any): rd is RarityDefinition {
  return isMinMax(rd.powerValue) && isMinMax(rd.amountTraits);
}

export interface AthleteStrength {
  total: number;
  values: number;
  talents: number;
}

export interface Athlete {
  rarity: Rarity;
  powerDistribution: PowerDistribution;
  country: CountryNames;
  visualGroup: VisualGroups;
  namingGroup: { firstName: NamingGroups; lastName: NamingGroups };
  gender: Gender;
  strength: AthleteStrength;
  firstName: string;
  lastName: string;
  nickName: string;
  appearance: Appearance;
  proficiencies: {
    roles: {
      primary: CsRole;
      secondary: CsRole;
      values: AthleteRoles;
      roleSuitabilities: CsRoleSuitabilities;
    };
    maps: {
      primary: CsMap;
      secondary: CsMap;
      tertiary: CsMap;
      values: AthleteMaps;
    };
  };
  skills: AthleteSkills;
  traits: Trait[];
  talents: Talents;
  endurance: { value: number; lastChanged: number };
  matchesLeft: number;
  systemStatModifiers: AthleteSystemStatModifiers;
  renown: number;
  chemistry: AthleteChemistryStats;
  matchHistory: AthleteMatchHistoryEntry[];
  countStats?: AthleteMatchCountStatsChanges & { mvps: number };
  buffs: { entries: AppliedBuff[]; nextInvalidAt: number | undefined };
  // upgrade: {
  //   amountUpgradeSlots: number;
  //   entries: AthleteUpgrade[];
  // };
}

export interface AthleteShort {
  uuid: string;
  nickName: string;
  role: CsRole;
  rarity: Rarity;
  strength: AthleteStrength;
}

export function isValidAthlete(athlete: any): athlete is Athlete {
  return (
    Object.values(Rarity).includes(athlete.rarity) &&
    countryNames.includes(athlete.country) &&
    isGender(athlete.gender) &&
    athlete.firstName !== undefined &&
    athlete.lasName !== undefined &&
    athlete.nickName !== undefined &&
    isAppearance(athlete.appearance) &&
    isProficiencies(athlete.proficiencies) &&
    isAthleteSkills(athlete.skills) &&
    athlete.traits.every((trait: any) => isTrait(trait)) &&
    isTalents(athlete.talents) &&
    !isNaN(athlete.age) &&
    isNamingGroups(athlete.namingGroup.firstName) &&
    isNamingGroups(athlete.namingGroup.lastName) &&
    isVisualGroups(athlete.visualGroup)
  );
}

/***
 * Checks if the athlete is exhausted
 * @param athlete
 * @returns true if the athlete is exhausted
 */
export function isAthleteExhausted(athlete: Athlete): boolean {
  return athlete.endurance.value <= 0;
}

/***
 * Checks if any athlete is exhausted
 * @param athletes
 * @returns true if any athlete is exhausted
 */
export function isAnyAthleteExhausted(athletes: Athlete[]): boolean {
  return athletes.some((athlete) => isAthleteExhausted(athlete));
}

export function isAthlete(athlete: any): athlete is Athlete {
  return Object(athlete).hasOwnProperty('skills') && Object(athlete).hasOwnProperty('talents');
}
