import { CsMap, isCsMap } from '../../../athlete/proficiencies';

export type TeamMapTactics = Record<CsMap, TacticsSelection>;
export function isTeamMapTactics(tmt: any): tmt is TeamMapTactics {
  return Object.keys(tmt).every((key) => isCsMap(key)) && Object.values(tmt).every((value) => isTacticSelection(value));
}

export type PartialTeamMapTactics = Partial<Record<CsMap, PartialTacticsSelection>>;

export type TacticsSelection = Record<RoundRole, Record<TacticSet, TacticDef['name']>>;
export function isTacticSelection(ts: any): ts is TacticsSelection {
  const keys = Object.keys(ts);
  const tacticSets = Object.keys(Object.values(ts));
  const chosenTactics = Object.values(Object.values);
  return (
    keys.every((key) => isRoundRole(key) && keys.length === roundRoleValues.length) &&
    tacticSets.every((set) => isTacticSet(set)) &&
    chosenTactics.every((tactic) => typeof tactic === 'string')
  );
}

export type PartialTacticsSelection = Partial<Record<RoundRole, Partial<Record<TacticSet, TacticDef['name']>>>>;

/** Current Role of Team - counterTerrorist or terrorist */
export enum RoundRole {
  COUNTER_TERRORIST = 'counterTerrorist',
  TERRORIST = 'terrorist',
}
export const roundRoleValues = Object.values(RoundRole);
export function isRoundRole(val: any): val is RoundRole {
  return roundRoleValues.includes(val);
}

/** Tactic choices for the different phases of a match round */
export enum TacticSet {
  /** Defines where athletes will move to in pre bomb phase*/
  PRE_WHERE = 'preWhere',
  /** Defines path of athletes in pre bomb phase */
  PRE_HOW = 'preHow',
  /** Defines general behavior in post bomb phase */
  POST = 'post',
}
export const tacticSetValues = Object.values(TacticSet);
export function isTacticSet(set: any): set is TacticSet {
  return tacticSetValues.includes(set);
}

export type TacticDefName = TacticDef['name'];
export interface TacticDef {
  name: string; // TODO: TACTICS_NAME_ENUM??
  roundRole: RoundRole;
  //   applicableTo: Record<TeamRole, TacticSet>;
  tacticSet: TacticSet;
  description: string;
  pathFilter: Record<'ath1' | 'ath2' | 'ath3' | 'ath4' | 'ath5', string[]>; // TODO: TAGS_ENUM[] // FIXME: perhaps CsRole
}
export function isTacticDef(def: any): def is TacticDef {
  return (
    typeof def.name === 'string' &&
    isRoundRole(def.roundRole) &&
    isTacticSet(def.tacticSet) &&
    typeof def.description === 'string' &&
    Object.keys(def.pathFilter).every(
      (key) => key === 'ath1' || key === 'ath2' || key === 'ath3' || key === 'ath4' || key === 'ath5'
    ) &&
    Object.values(def.pathFilter).every(
      (value) => Array.isArray(value) && value.every((_value) => typeof _value === 'string')
    )
  );
}
