import { CsMap, isCsMap } from '../../../athlete';
import { Team } from '../../../team/team';
import { ApproachDefinition, ApproachDefinitionName, isApproachDefinition } from '../encounter/encounter';
import { Path, LocationDefinition, MapLocation } from '../map/map';
import { WhoIs, MatchAthleteUuid, MatchTeam, MatchAthlete, MatchRoundAthlete, isWhoIs } from '../matchSim';
import {
  PRE_BOMB_PHASE_END,
  WinReason,
  RoundPhase,
  is_T_WinReason,
  is_CT_WinReason,
  isWinReason,
} from '../round/round';
import { RoundRole, isRoundRole } from '../tactic/tactic';

export type TimeLog = MoveLog | EncounterCollectionLog | BombActionLog;
export interface TimeLogBase {
  gameTime: number;
  logType: LogType;
}
export function isTimeLogBase(log: any): log is TimeLogBase {
  return !isNaN(log.gameTime) && isLogType(log.logType);
}

export enum LogType {
  MOVE = 'move',
  ENCOUNTER = 'encounter',
  BOMB_ACTION = 'bombAction',
}
export const logTypeValues = Object.values(LogType);
export function isLogType(type: any): type is LogType {
  return logTypeValues.includes(type);
}

export enum MoveType {
  QUICK_MOVE = 'quick',
  STANDARD_MOVE = 'standard',
}
export const moveTypeValues = Object.values(MoveType);
export function isMoveType(val: any): val is MoveType {
  return moveTypeValues.includes(val);
}
export interface MoveLog extends TimeLogBase {
  logType: LogType.MOVE;
  moveType: MoveType;
  entries: AthleteMove[];
  positions: PositionsLog;
}
export function isMoveLog(log: TimeLog): log is MoveLog {
  return (
    isTimeLogBase(log) &&
    log.logType === LogType.MOVE &&
    isPositionsLog(log.positions) &&
    isMoveType(log.moveType) &&
    log.entries.every((move) => isAthleteMove(move))
  );
}

export interface EncounterCollectionLog extends TimeLogBase {
  logType: LogType.ENCOUNTER;
  entries: EncounterLogCollection;
}
export function isEncounterCollectionLog(log: any): log is EncounterCollectionLog {
  return log.logType === LogType.ENCOUNTER && isEncounterLogCollection(log.entries) && isTimeLogBase(log);
}

export interface BombActionLog extends TimeLogBase {
  logType: LogType.BOMB_ACTION;
  actionValue: number;
  /** either holds athlete uuid of terrorist carrying the bomb or counterTerrorist trying to defuse bomb (pre / post bomb) */
  bombActor: string;
  bombLocation: string;
}
export function isBombActionLog(log: any): log is BombActionLog {
  return (
    log.logType === LogType.BOMB_ACTION &&
    typeof log.actionValue === 'number' &&
    typeof log.bombActor === 'string' &&
    typeof log.bombLocation === 'string' &&
    isTimeLogBase(log)
  );
}

export interface RoundPhaseResultLog {
  timeLogs: TimeLog[];
  result: PRE_BOMB_PHASE_END | WinReason;
  initialPathing?: PathingLog;
  realPathing?: PathingLog;
}
export function isRoundPhaseResultLog(log: any): log is RoundPhaseResultLog {
  return (
    Array.isArray(log.timeLogs) &&
    log.timeLogs.every((log: any) => isTimeLogBase(log)) &&
    (log.result === PRE_BOMB_PHASE_END.BOMB_PLACED || isWinReason(log.result))
  );
}

export interface RoundResult {
  [RoundPhase.PRE_BOMB]: RoundPhaseResultLog;
  [RoundPhase.POST_BOMB]?: RoundPhaseResultLog;
}
export function isRoundResult(result: any): result is RoundResult {
  return isRoundPhaseResultLog(result.preBomb) && (result.postBomb ? isRoundPhaseResultLog(result.postBomb) : true);
}

export interface RoundResultLog extends RoundResult {
  win: Team['uuid'];
  whoIs: WhoIs;
  winReason: WinReason;
  roundCount: number;
  streakCount: StreakCount;
  statChanges: Record<MatchAthleteUuid, AthleteMatchCountStatsChanges>;
  motivationChanges: Record<Team['uuid'], Record<MatchAthleteUuid, number>>;
}
export function isRoundResultLog(log: any): log is RoundResultLog {
  return (
    typeof log.win === 'string' &&
    isWhoIs(log.whoIs) &&
    isWinReason(log.winReason) &&
    typeof log.roundCount === 'number' &&
    isStreakCount(log.streakCount) &&
    Object.values(log.statChanges).every((stats) => isAthleteMatchCountStatsChanges(stats)) &&
    isRoundResult(log.postBomb ? { preBomb: log.preBomb, postBomb: log.postBomb } : { preBomb: log.preBomb })
  );
}

export interface FakeRoundResultLog
  extends Omit<RoundResultLog, 'preBomb' | 'postBomb' | 'streakCount' | 'motivationChanges'> {
  isFake: boolean;
}
export function isFakeRoundResultLog(frl: any): frl is FakeRoundResultLog {
  return (
    frl.isFake &&
    frl.win !== '' &&
    (is_T_WinReason(frl.winReason) || is_CT_WinReason(frl.winReason)) &&
    Object.entries(frl.whoIs).every(([key, value]) => key !== '' && isRoundRole(value))
  );
}

export interface StreakCount {
  teamId: string;
  amount: number;
}
export function isStreakCount(count: any): count is StreakCount {
  return count && typeof count.teamId === 'string' && typeof count.amount === 'number';
}

export type PathingLog = Record<MatchTeam['uuid'], Record<string, Path>>;

/**
 * location, [athl.uuid]
 */
export type PositionsLog = Record<LocationDefinition['name'], Partial<Record<RoundRole, MatchAthlete['uuid'][]>>>;
export function isPositionsLog(log: any): log is PositionsLog {
  return (
    Object.keys(log).every((logKey) => typeof logKey === 'string') &&
    Object.entries(log).every(([logKey, logValue]) =>
      Object.entries(log[logKey]).every(
        ([roundRole, athlIdArray]) => isRoundRole(roundRole) && Array.isArray(athlIdArray)
      )
    )
  );
}

export interface AthleteMove {
  athleteId: string;
  teamId: string;
  newLocation: MapLocation;
  oldLocation: MapLocation;
}
export function isAthleteMove(move: any): move is AthleteMove {
  return (
    typeof move.athleteId === 'string' && typeof move.newLocation === 'string' && typeof move.oldLocation === 'string'
  );
}
export interface MatchKill {
  athleteId: MatchAthleteUuid;
  assistedBy: MatchAthleteUuid[];
}
export function isMatchKill(kill: any): kill is MatchKill {
  return typeof kill.athleteId === 'string' && kill.assistedBy.every((assistant: any) => typeof assistant === 'string');
}

export interface ApproachDamage {
  athleteId: MatchAthleteUuid;
  damage: number;
}
export function isApproachDamage(damage: any): damage is ApproachDamage {
  return typeof damage.athleteId === 'string' && typeof damage.damage === 'number';
}

export interface ApproachResult {
  success: boolean;
  damageEffects: ApproachDamage[];
}
export function isApproachResult(result: any): result is ApproachResult {
  return typeof result.success === 'boolean' && result.damageEffects.every((effect: any) => isApproachDamage(effect));
}

export interface EncounterActionLog extends ApproachResult {
  athleteId: MatchAthleteUuid;
  kills: MatchKill[];
  approach: ApproachDefinition;
  initiative: number;
  health: number;
  teamId: string;
}
export function isEncounterActionLog(log: any): log is EncounterActionLog {
  return (
    typeof log.athleteId === 'string' &&
    log.kills.every((kill: any) => isMatchKill(kill)) &&
    isApproachDefinition(log.approach) &&
    [log.initiative, log.health].every((value) => typeof value === 'number') &&
    typeof log.teamId === 'string'
  );
}

export type ApproachLogEntry = {
  athleteId: MatchAthleteUuid;
  approach: ApproachDefinition;
  initiative: number;
  health: number;
  teamId: string;
};
export function isApproachLogEntry(entry: any): entry is ApproachLogEntry {
  return (
    typeof entry.initiative === 'number' &&
    typeof entry.health === 'number' &&
    typeof entry.teamId === 'string' &&
    isApproachDefinition(entry.approach) &&
    typeof entry.athleteId === 'string'
  );
}

export type Encounters = Array<MapLocation>;

export type EncounterRoundLog = {
  // approaches: ApproachLogEntry[];
  actions: EncounterActionLog[];
  roundCount: number;
};
export function isEncounterRoundLog(log: any): log is EncounterRoundLog {
  return log.actions.every((action: any) => isEncounterActionLog(action)) && typeof log.roundCount === 'number';
}

export interface EncounterLog {
  roundLogs: EncounterRoundLog[];
  location: MapLocation;
  pathingPenalties: Record<MatchTeam[`uuid`], Record<MatchRoundAthlete[`uuid`], number>>;
  /**teamId, athleteId[] */
  present: Record<MatchTeam['uuid'], MatchAthleteUuid[]>;
  killedInEncounter: Record<MatchTeam['uuid'], MatchAthleteUuid[]>;
}
export function isEncounterLog(log: any): log is EncounterLog {
  return (
    typeof log.location === 'string' &&
    log.roundLogs.every((log: any) => isEncounterRoundLog(log)) &&
    Object.entries(log.present).length === 2 &&
    Object.values(log.present).every(
      (value) => Array.isArray(value) && value.every((entry) => typeof entry === 'string')
    )
  );
}

export type EncounterLogCollection = EncounterLog[];
export function isEncounterLogCollection(log: any): log is EncounterLogCollection {
  return Array.isArray(log) && log.every((encounterLog) => isEncounterLog(encounterLog));
}

export interface MatchVeto {
  map: CsMap;
  counter: number;
  userId: string;
  teamId: string;
}
export function isMatchVeto(veto: any): veto is MatchVeto {
  return (
    isCsMap(veto.map) &&
    typeof veto.counter === 'number' &&
    [veto.userId, veto.teamId].every((foo) => typeof foo === 'string')
  );
}

export type VetoLog = MatchVeto[];
export function isVetoLog(log: any): log is VetoLog {
  return Array.isArray(log) && log.every((entry) => isMatchVeto(entry));
}

export interface AthleteMatchStatsChanges extends AthleteMatchCountStatsChanges {
  athleteId: string;
  renownChange: number;
  motivationChange: number;
  chemistryChange: ChemistryChange[];
  averageRoundDamage: number;
}
export function isAthleteMatchStatsChanges(statChanges: any): statChanges is AthleteMatchStatsChanges {
  return (
    typeof statChanges.athleteId === 'string' &&
    [statChanges.renownChange, statChanges.motivationChange, statChanges.averageRoundDamage].every(
      (value) => typeof value === 'number'
    ) &&
    Array.isArray(statChanges.chemistryChange) &&
    statChanges.chemistryChange.every((change: any) => isChemistryChange(change)) &&
    isAthleteMatchCountStatsChanges(statChanges)
  );
}

export interface AthleteMatchCountStatsChanges {
  kills: number;
  death: number;
  assists: number;
  damageGiven: number;
}
export function isAthleteMatchCountStatsChanges(statChanges: any): statChanges is AthleteMatchCountStatsChanges {
  return [statChanges.kills, statChanges.death, statChanges.assists, statChanges.damageGiven].every(
    (stat) => typeof stat === 'number'
  );
}

export interface TeamStats extends AthleteMatchCountStatsChanges {
  teamId: string;
  athleteStats: Record<string, AthleteMatchStatsChanges>;
  mvp?: string;
}
export function isTeamStats(stats: any): stats is TeamStats {
  return (
    typeof stats.teamId === 'string' &&
    Object.entries(stats.athleteStats).every(
      ([uuid, _stats]) => typeof uuid === 'string' && isAthleteMatchStatsChanges(_stats)
    ) &&
    isAthleteMatchCountStatsChanges(stats)
  );
}

export interface ChemistryChangeLog {
  chemistryWith: MatchAthleteUuid;
  change: number;
}
export function isChemistryChangeLog(log: any): log is ChemistryChangeLog {
  return typeof log.chemistryWith === 'string' && typeof log.change === 'number';
}
export interface ChemistryChange extends ChemistryChangeLog {
  athleteId: MatchAthleteUuid;
}
export function isChemistryChange(change: any): change is ChemistryChange {
  return typeof change.athleteId === 'string' && isChemistryChangeLog(change);
}
