export type Proficiencies = {
  roles: ChosenCsRoles & { values: AthleteRoles };
  maps: ChosenCsMaps & { values: AthleteMaps };
};
export function isProficiencies(prof: any): prof is Proficiencies {
  return (
    isChosenCsRole(prof.roles) &&
    isAthleteRoles(prof.roles.values) &&
    isChosenCsMaps(prof.maps) &&
    isAthleteMaps(prof.maps.values)
  );
}

// MAPS
export enum CsMap {
  ANUBIS = 'Anubis',
  MIRAGE = 'Mirage',
  NUKE = 'Nuke',
  INFERNO = 'Inferno',
  VERTIGO = 'Vertigo',
  ANCIENT = 'Ancient',
  OVERPASS = 'Overpass',
}

/**
 * List of Athlete Maps
 */
export const csMaps = Object.values(CsMap);

export function isCsMap(name: string): name is CsMap {
  return csMaps.includes(name as any);
}

export interface ChosenCsMaps {
  primary: CsMap;
  secondary: CsMap;
  tertiary: CsMap;
}
export function isChosenCsMaps(ccm: any): ccm is ChosenCsMaps {
  return isCsMap(ccm.primary) && isCsMap(ccm.secondary) && isCsMap(ccm.tertiary);
}

export type AthleteMaps = Record<CsMap, number>;
export function isAthleteMaps(am: any): am is AthleteMaps {
  return csMaps.every((map) => !isNaN(am[map]));
}

// ROLES

export enum CsRole {
  CAPTAIN = 'Captain',
  ENTRY_FRAGGER = 'EntryFragger',
  SUPPORT = 'Support',
  SNIPER = 'Sniper',
  FLEX = 'Flex',
}

/** How well an Athlete is suited for CsRoles */
export type CsRoleSuitabilities = Record<CsRole, number>;

/**
 * List of Athlete Roles
 */
export const csRoles = Object.values(CsRole);

export function isCsRole(name: string): name is CsRole {
  return csRoles.includes(name as any);
}

export interface ChosenCsRoles {
  primary: CsRole;
  secondary: CsRole;
}
export function isChosenCsRole(ccr: any): ccr is ChosenCsRoles {
  return isCsRole(ccr.primary) && isCsRole(ccr.secondary);
}

export type AthleteRoles = Record<CsRole, number>;
export function isAthleteRoles(ar: any): ar is AthleteRoles {
  return csRoles.every((role) => !isNaN(ar[role]));
}
