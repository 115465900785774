export type IdName = {
  id: number;
  name: string;
};

export type Timestamps = {
  createdAt: string;
  updatedAt: string;
  publishedAt: string | null;
};

export enum StrapiWebhookEventName {
  ENTRY_CREATE = 'entry.create',
  ENTRY_UPDATE = 'entry.update',
  ENTRY_DELETE = 'entry.delete',
  ENTRY_PUBLISH = 'entry.publish',
  ENTRY_UNPUBLISH = 'entry.unpublish',
  MEDIA_CREATE = 'media.create',
  MEDIA_UPDATE = 'media.update',
  MEDIA_DELETE = 'media.delete',
}

export interface StrapiWebhookEvent<T extends IdName & Timestamps> {
  event: StrapiWebhookEventName;
  createdAt: string;
  model: string;
  entry: T;
}

export interface StrapiImageFormat {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: string | null;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface StrapiImage {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: {
    thumbnail: StrapiImageFormat;
    small: StrapiImageFormat;
    medium: StrapiImageFormat;
    large: StrapiImageFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  provider_metadata: null;
  createdAt: string;
  updatedAt: string;
}
