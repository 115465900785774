import { EconomyOneTimeIncomeType } from '../economy';

export enum RewardType {
  SOFT_CURRENCY = 'softCurrency',
  PREMIUM_CURRENCY = 'premiumCurrency',
  CARD_PACK = 'cardPack',
  FAME_POINTS = 'famePoints',
}

const { MATCH_REWARD, LEAGUE_REWARD, SPONSORSHIP } = EconomyOneTimeIncomeType;
export const RewardSource = { MATCH_REWARD, LEAGUE_REWARD, SPONSORSHIP, SYSTEM_REWARD: 'systemReward' };
export type RewardSource = (typeof RewardSource)[keyof typeof RewardSource];

export interface SoftCurrencyReward {
  type: RewardType.SOFT_CURRENCY;
  amount: number;
}

export function isSoftCurrencyReward(reward: Reward): reward is SoftCurrencyReward {
  return reward.type === RewardType.SOFT_CURRENCY;
}

export interface PremiumCurrencyReward {
  type: RewardType.PREMIUM_CURRENCY;
  amount: number;
}

export function isPremiumCurrencyReward(reward: Reward): reward is PremiumCurrencyReward {
  return reward.type === RewardType.PREMIUM_CURRENCY;
}

export interface CardPackReward {
  type: RewardType.CARD_PACK;
  cardPackLootCycleId: string;
  amount: number;
}

export function isCardPackReward(reward: Reward): reward is CardPackReward {
  return reward.type === RewardType.CARD_PACK;
}

export interface FamePointsReward {
  type: RewardType.FAME_POINTS;
  amount: number;
}

export function isFamePointsReward(reward: Reward): reward is FamePointsReward {
  return reward.type === RewardType.FAME_POINTS;
}

export type Reward = SoftCurrencyReward | PremiumCurrencyReward | CardPackReward | FamePointsReward;

export interface LeagueReward {
  /** Minimum Rank to get Rewards */
  minRank: number;

  /** Rewards */
  rewards: Reward[];
}

export interface MatchReward {
  win: Reward[];
  loss: Reward[];
  tie: Reward[];
}
