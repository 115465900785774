import { MatchLineup, MatchTeamBase, Team, TeamBaseInfo } from '../csgo-manager';
import { AiTeam } from '../csgo-manager/team/ai-team';
import {
  Bracket,
  LadderBaseInfo,
  LeagueBaseInfo,
  LeaguePointsDistribution,
  LeagueTeam,
  SeasonBaseInfo,
} from '../leagues';
import { MatchReward } from '../rewards/rewards';
import { MatchDefinition } from './match';

export interface UpcomingMatchBase {
  /** iso string of match date */
  matchDate: string;
  /** type of match */
  type: UpcomingMatchType;
  /** match id */
  matchId: string;
  /** base info of teams if is not a tuple it's a free win for the team */
  teams: [TeamBaseInfo, TeamBaseInfo];
  /** rewards for win, loss and tie */
  rewards: MatchReward;
  /** match definition */
  matchDefinition: Partial<MatchDefinition>;
  /** SortKey for GSI upcomingMatchDate */
  upcomingMatchDateId: string;
}

// LEAGUE
export interface UpcomingLeagueMatch extends UpcomingMatchBase {
  type: UpcomingMatchType.LEAGUE;
  ladder: LadderBaseInfo;
  season: SeasonBaseInfo;
  league: LeagueBaseInfo;
  bracketId: string;
  lpDistribution: LeaguePointsDistribution;
}

export interface LeagueFreeWin {
  type: UpcomingMatchType.FREE_WIN;
  matchDate: string;
  matchId: string;
  team: TeamBaseInfo;
  ladder: LadderBaseInfo;
  season: SeasonBaseInfo;
  league: LeagueBaseInfo;
  bracketId: string;
  lpDistribution: LeaguePointsDistribution;
}

// RANK IN

export interface UpcomingRankInMatch extends UpcomingMatchBase {
  type: UpcomingMatchType.RANK_IN;
  ladder: LadderBaseInfo;
  season: SeasonBaseInfo;
  aiTeam: AiTeam;
}

export type UpcomingMatch = UpcomingLeagueMatch | LeagueFreeWin | UpcomingRankInMatch;

export enum UpcomingMatchType {
  LEAGUE = 'league',
  RANK_IN = 'rankIn',
  FREE_WIN = 'freeWin',
  TRAINING = 'training',
}

export interface LeagueMatchHandlingData {
  matchDate: string;
  matchId: string;
  match: {
    lineups: MatchLineup[];
    definition: Partial<MatchDefinition>;
    rewards: MatchReward;
    lpDistribution: LeaguePointsDistribution;
  };
  season: SeasonBaseInfo;
  league: LeagueBaseInfo;
  bracket: Bracket;
  leagueTeams: [LeagueTeam, LeagueTeam];
  userTeams: (Team | AiTeam)[];
}
