import { withDevtools } from '@/lib/store/utils';
import { create } from 'zustand';
import { CardPacksSlice, createCardPacksSlice } from './cardPacks/cardPacks.slice';
import { CardsSlice, createCardsSlice } from './cards/cards.slice';
import { createGameAssetsInitSlice, GameAssetsInitSlice } from './game-assets-init/game-assets-init.slice';
import { createMatchesSlice, MatchesSlice } from './matches/matches.slice';
import { createTeamsSlice, TeamsSlice } from './teams/teams.slice';
import { createRoomsSlice, RoomsSlice } from '@/lib/store/gameAssets/rooms/rooms.slice';
import { createFacilitySlice, FacilitySlice } from '@/lib/store/gameAssets/facility/facility.slice';
import { createSponsorsSlice, SponsorsSlice } from '@/lib/store/gameAssets/sponsors/sponsors.slice';

export type GameAssetsState = CardsSlice &
  CardPacksSlice &
  TeamsSlice &
  MatchesSlice &
  RoomsSlice &
  FacilitySlice &
  SponsorsSlice &
  GameAssetsInitSlice;

export const useGameAssetsStore = create<GameAssetsState>()(
  withDevtools(
    (set, get, ...a) => ({
      ...createCardsSlice(set, get, ...a),
      ...createCardPacksSlice(set, get, ...a),
      ...createTeamsSlice(set, get, ...a),
      ...createMatchesSlice(set, get, ...a),
      ...createRoomsSlice(set, get, ...a),
      ...createGameAssetsInitSlice(set, get, ...a),
      ...createFacilitySlice(set, get, ...a),
      ...createSponsorsSlice(set, get, ...a),
    }),
    { name: 'GameAssetsState' }
  )
);
