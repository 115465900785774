import { DataBasePksItem, DbMetaAttributes } from '../../utils';
import { MinMax } from '../athlete/utils';
import { LootDefinition } from '../content';

export enum PackTypeEnum {
  CARDPACK = 'CARDPACK',
}

export enum Rarity {
  COMMON = 'common',
  UNCOMMON = 'uncommon',
  RARE = 'rare',
  EPIC = 'epic',
  LEGENDARY = 'legendary',
}

export const rarityValues = Object.values(Rarity);
export function isRarity(rarity: any): rarity is Rarity {
  return rarityValues.includes(rarity);
}

// TODO: depricated
export type PackEdition = {
  editionId: string;
  name: string;
  type: PackTypeEnum;
  slots: LootDefinition[];
};

export type PackEditionDbItem = DataBasePksItem & DbMetaAttributes & PackEdition & {};
