export enum FacilityManagerType {
  BUILDER = 'builder', // reduces cost of rooms
  FAST_BUILDER = 'fastBuilder', // reduces build duration
  ACCOUNTANT = 'accountant', // increases merch and streaming incoming
  HR_SPECIALIST = 'hrSpecialist', // increases xp gain of STAFF
}

export const facilityManagerTypeValues = Object.values(FacilityManagerType);
export function isFacilityManagerType(obj: any): obj is FacilityManagerType {
  return facilityManagerTypeValues.includes(obj);
}
