import { Components } from '@mui/material';
import { palette } from '../palette';

const MuiPaperOverrides: Components = {
  MuiPaper: {
    styleOverrides: {
      root: {
        'backgroundColor': palette.bgPrimary.primary,
        'color': palette.contentPrimary.primary,
        'border': `1px solid ${palette.contentSecondary.tertiary}`,
        'marginTop': '24px',
        '.MuiList-root': {
          padding: '16px',
        },
      },
    },
  },
};

export default MuiPaperOverrides;
