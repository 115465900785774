import { CsMap, csMaps, CsRole, csRoles } from './proficiencies';
import { SkillName, skillNames } from './skills';
export enum SkillTalentTablesNames {
  ONE_HIGH = 'oneHigh',
  THREE_HIGH = 'threeHigh',
  HALF_AND_HALF = 'halfAndHalf',
  BALANCED = 'balanced',
}
export const skillTalentTablesNames = Object.values(SkillTalentTablesNames);
export function isSkillTalentTablesNames(sttn: any): sttn is SkillTalentTablesNames {
  return Object.values(SkillTalentTablesNames).includes(sttn);
}

export enum ProficiencyTalentTablesNames {
  ONE_HIGH = 'oneHigh',
  HALF_AND_HALF = 'halfAndHalf',
  BALANCED = 'balanced',
}
export const proficiencyTalentTablesNames = Object.values(ProficiencyTalentTablesNames);
export function isProficiencyTalentTableName(pttn: any): pttn is ProficiencyTalentTablesNames {
  return Object.values(ProficiencyTalentTablesNames).includes(pttn);
}

export enum TalentType {
  MAPS = 'maps',
  SKILLS = 'skills',
  ROLES = 'roles',
}
export const talentType = Object.values(TalentType);
export function isTalentType(tt: any): tt is TalentType {
  return Object.values(TalentType).includes(tt);
}

export interface Talents {
  maps: Record<CsMap, number>;
  roles: Record<CsRole, number>;
  skills: Record<SkillName, number>;
}
export function isTalents(talents: any): talents is Talents {
  return (
    talentType.every((tt) => talents.tt !== undefined) &&
    csMaps.every((map) => !isNaN(talents.maps[map])) &&
    csRoles.every((role) => !isNaN(talents.roles[role])) &&
    skillNames.every((name) => !isNaN(talents.skills[name]))
  );
}

export type TalentsRelativeDistance = Record<CsMap, number> | Record<CsRole, number> | Record<SkillName, number>;
export function isTalentsRelativeDistance(trd: any): trd is TalentsRelativeDistance {
  return (
    csMaps.every((map) => !isNaN(trd[map])) ||
    csRoles.every((role) => !isNaN(trd[role])) ||
    skillNames.every((name) => !isNaN(trd[name]))
  );
}
