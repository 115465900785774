export interface FacilityEffect {
  uuid: string;
  description: string;
  statName: FacilityStatModifierName;
  isRelative: boolean;
  value: number;
}

export enum FacilityStatModifierName {
  TRAINING_STAT_MULTIPLIER = 'trainingStatMultiplier',
  FAME_MULTIPLIER = 'fameMultiplier',
  ROOM_COST = 'roomCost',
  ROOM_UPKEEP = 'roomUpkeep',
  ROOM_BUILD_DURATION = 'roomBuildDuration',
  TEAM_MARKETABILITY = 'teamMarketability',
  ATHLETE_MARKETABILITY = 'athleteMarketability',
  VIEWABILITY = 'viewability',
  BUSINESS_INCOME_MULTIPLIER = 'businessIncomeMultiplier',

  // not in scope for launch
  //   TRAINING_CAMP_COST = 'trainingCampCost',
  //   TRAINING_CAMP_DURATION = 'trainingCampDuration',
  //   HARDWARE_DURABILITY_LOSS_MODIFIER = 'hardwareDurabilityLossModifier',
}
