import { DataBasePksItem, DbMetaAttributes } from '../../utils';
import { LootDefinition } from '../content';
import { PackEdition, PackTypeEnum } from './pack-edition';

export enum ShelfStateEnum {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type Shelf = {
  shelfId: string;
  name: string;
  maxSize: number;
  minSize: number;
  watermark: number;
  state: ShelfStateEnum;
  expireAt?: number;
  type: PackTypeEnum;
  slots: LootDefinition[];
};

export type ShelfDbItem = DataBasePksItem & DbMetaAttributes & Shelf & {};
