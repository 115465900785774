import { getRequest, postRequest } from '@/lib/utils/request';
import { StaffCard, StaffCardShort, StaffSlots, StaffSlotType } from '@cstactics/types';

// STAFFS

export async function queryUserStaffs(): Promise<StaffCardShort[]> {
  return getRequest('/api/user/facility/staffs');
}

export async function queryUserStaffSlots(): Promise<StaffSlots> {
  return getRequest('/api/user/facility/staffs/slots');
}

export async function queryUserStaffHire(staffId: string, slotType: StaffSlotType): Promise<StaffCardShort> {
  return postRequest('/api/user/facility/staffs/hire', { staffId, slotType });
}

export async function queryUserStaffFire(staffId: string, slotType: StaffSlotType): Promise<StaffCardShort> {
  return postRequest('/api/user/facility/staffs/fire', { staffId, slotType });
}

export async function queryUserStaffDetails(staffId: string): Promise<StaffCard> {
  return getRequest(`/api/user/facility/staffs/detail/${staffId}`);
}

// ROOMS

export async function queryUserRooms() {
  return getRequest('/api/user/facility/rooms');
}

export async function queryUserRoomsUpgrade(roomId: string, level: number) {
  return postRequest('/api/user/facility/rooms/upgrade', { roomId, level });
}

export async function queryUserRoomsDowngrade(roomId: string) {
  return postRequest('/api/user/facility/rooms/downgrade', { roomId });
}

export async function queryUserRoomsFastUpgrade() {
  return postRequest('/api/user/facility/rooms/fast-upgrade', {});
}

// SPONSORSHIP CONTRACTS

export async function queryUserSponsorshipContracts() {
  return getRequest('/api/user/facility/sponsorship-contracts');
}

export async function queryUserSponsorshipContractsSlots() {
  return getRequest('/api/user/facility/sponsorship-contracts/slots');
}

export async function queryUserSponsorshipContractsSign(contractId: string, teamId: string) {
  return postRequest('/api/user/facility/sponsorship-contracts/sign', { contractId, teamId });
}

export async function queryUserSponsorshipContractsFastEnd(contractId: string) {
  return postRequest('/api/user/facility/sponsorship-contracts/fast-end', { contractId });
}
