import { LeagueBaseInfo } from '../../leagues';
import { CsMap, CsRole, csMaps, isCsMap } from '../athlete';
import { AppliedBuff, TeamStatus } from '../../buff-system';
import { AthleteCard } from '../card/athlete/athleteCard';
import { PartialTeamMapTactics } from '../match/simulation/tactic/tactic';
import { TeamFame } from './fame';

export type TeamRoster = Record<CsRole, string>;
export type TeamRosterExtended = Record<CsRole, AthleteCard>;
export type TeamBench = string[];

/** Starting with key 1. Key 1 is the most prioritized map and will be banned as last. */
export type TeamMapPriorities = Record<number, CsMap>;
export function isTeamMapPriorities(prios: any): prios is TeamMapPriorities {
  const entries = Object.entries(prios);
  return entries.length === csMaps.length && entries.every(([rank, map]) => isCsMap(map as string));
}

export interface TeamLogo {
  layers: {
    shieldId: number;
    mascotId: number;
  };
  colors: {
    borderOutside: string;
    borderInside: string;
    fill: string;
    decoration: string;
  };
}

export enum RankInProgressState {
  IN_PROGRESS = 'inProgress',
  RANKED_IN = 'rankedIn',
}

export interface TeamLadderInfo {
  ladder: {
    name: string;
    uuid: string;
  };
  league?: LeagueBaseInfo;
  bracketId?: string;
  currentSeason?: {
    progress?: number;
    rank?: number;
  };
  lastSeason?: {
    rank: number;
    league: LeagueBaseInfo;
    bracketId: string;
  };
  rankIn: RankInProgressState;
}

export interface TeamBaseInfo {
  /** team uuid */
  uuid: string;
  /** team owner's user id */
  userId: string;
  /** team name */
  name: string;
  /** team tag */
  tag: string;
  /** team logo */
  logo: TeamLogo;
  /** team fame */
  fame: TeamFame;
  /** if team is ai team */
  isAiTeam?: boolean;
}

export interface Team extends TeamBaseInfo {
  /** amount of max benched athletes */
  benchSize: number;
  /** map of csRoles to athlete card uuid, default athletes for matches */
  roster: Partial<TeamRoster>;
  /** list of athlete card uuids */
  bench: TeamBench;
  /** map of sorted numbers to csMap */
  mapPriorities: TeamMapPriorities;
  /** tactics chosen for every csMap */
  mapTactics: PartialTeamMapTactics;
  /** ladderInfo */
  ladderInfos: Record<string, TeamLadderInfo>;

  // not in scope for launch
  /** team buffs */
  // buffs: { entries: AppliedBuff<TeamStatus>[]; nextInvalidAt: number };
  /**multipliers */
  // teamModifiers: TeamStatMultipliers;
}
